import Entity from '../../Entity'
import ProjectType from '../ProjectType'
import Stage from './Stage'

export default class Pipeline extends Entity {
  constructor(data) {
    super(data.id)
    this.name = data.name
    if (data.dtype) {
      let t = data.dtype
      if (!data.dtype.id) {
        t = { id: data.dtype }
      }
      this.type = ProjectType.create(t)
    }
    this.stages = []
    if (data.cstages && data.cstages.data) {
      this.stages = data.cstages.data.map(stage => Stage.create(stage))
    }
  }

  /**
   * @param { id, name, dtype, cstages } data
   */
  static create(data) {
    if (!data) {
      throw new Error('Pipeline::create: "data" is undefined')
    }
    return new Pipeline(data)
  }

  update(data) {
    this.name = data.name
    if (data.dtype) {
      let t = data.dtype
      if (!data.dtype.id) {
        t = { id: data.dtype }
      }
      this.type = ProjectType.create(t)
    }
    if (data.cstages && data.cstages.data) {
      this.stages = data.cstages.data.map(stage => Stage.create(stage))
    }
  }
}
