import Entity from '../Entity'
import * as moment from 'moment'
moment.locale('fr')
export default class HistoryUsers extends Entity {
  constructor(data) {
    super(data.id)
    if (data) {
      this.historique_id = data.historique_id
      this.current = data.current
      this.timestamp = data.timestamp
      this.formatDate = data.timestamp
        ? moment(data.timestamp, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY')
        : data.timestamp
      this.formatHeure = data.timestamp
        ? moment(data.timestamp, 'YYYY-MM-DD HH:mm:ss').format('HH:mm')
        : data.timestamp
      this.entity = data.entity
      this.entityId = data.entityId
      this.data_historique_id = data.data_historique_id
      this.key = data.key
      this.name = data.name ? data.name : data.key
      this.previous = data.previous
      this.type = data.type
      this.title_entity = data.title_entity
      this.title_projet = data.title_projet ? data.title_projet : '(supprimer)'
      this.title_contact = data.title_contact
      this.projet_id = data.projet_id
      this.contact_id = data.contact_id
      this.type_activity = data.type_activity
    }
  }

  static create(data) {
    if (!data) {
      throw new Error('HistoryUsers::create: "data" is undefined')
    }
    return new HistoryUsers(data)
  }
}
