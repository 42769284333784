import Entity from '../../Entity'
import User from '../User'

export default class ActionEvent extends Entity {
  constructor(data) {
    super(data.id)
    if (data.created_by) {
      if (data.created_by.data) {
        this.created_by = User.create(data.created_by.data)
      } else {
        this.created_by = User.create(data.created_by)
      }
    }
    this.type = data.type;
    this.scheduled = data.scheduled
    this.scheduled_at = data.scheduled_at ? invertDate(data.scheduled_at) : data.scheduled_at
    this.project_id = data.project_id
    this.subject = data.subject
    this.message = data.message
    this.to = data?.to?.length ? data.to.split(';').map(i => { return { value: i } }) : []
    this.showText = false
    this.attachments = []
    this.attachments_action = data.attachments
  }
  update(data) {
    this.scheduled = data.scheduled
    this.scheduled_at = data.scheduled_at ? invertDate(data.scheduled_at) : data.scheduled_at
    this.subject = data.subject
    this.message = data.message
    this.to = data?.to?.length ? data.to.split(';').map(i => { return { value: i } }) : []
    this.attachments = []
    this.attachments_action = data.attachments
  }
  /**
   * @param {*} data
   */
  static create(data) {
    if (!data) {
      throw new Error('ActionEvent::create: "data" is undefined')
    }
    return new ActionEvent(data)
  }
}

function invertDate(inputDate) {
  const [datePart, timePart] = inputDate.split(' ')
  const [year, month, day] = datePart.split('-')
  const [hours, minutes] = timePart.split(':')
  let meridian = 'am'
  let hour = parseInt(hours, 10)

  if (hour >= 12) {
    meridian = 'pm'
    if (hour > 12) {
      hour -= 12
    }
  }

  if (hour === 0) {
    hour = 12
  }

  return `${year}-${month}-${day} ${hour}:${minutes} ${meridian}`
}
