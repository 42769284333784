import Entity from '../Entity'

export default class StageTH extends Entity {
  constructor(data) {
    super(data.id)
    this.name = data.name
    this.order = data.order
    this.identifier = data.identifier
  }

  /**
   * @param { id, name, order } data
   */
  static create(data) {
    if (!data) {
      throw new Error('StageTH::create: "data" is undefined')
    }
    return new StageTH(data)
  }

  updateStageTH(data) {
    this.id = data.id
    this.name = data.name
    this.order = data.order
    this.identifier = data.identifier
  }
}
