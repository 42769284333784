import Axios from '@/axios'
import domain from '@/environment'

const state = {
  files: [],
  pdfError: null,
  pdfLoading: false
}

const getters = {
  getAllFiles: state => state.files,
  getPdfError: state => state.pdfError,
  getPdfLoading: state => state.pdfLoading
}

const mutations = {
  SET_PDF_ERROR(state, payload = null) {
    state.pdfError = payload
  },
  SET_PDF_LOADING(state, payload) {
    state.pdfLoading = payload
  },
  SET_CREATE_PDF(state, payload) {
    state.files.push(payload)
  },
  SET_DELETE_PDF(state, payload) {
    state.files = state.files.filter(f => f.id !== payload)
  },
  SET_ALL_PDF(state, payload) {
    state.files = payload
  }
}

const actions = {
  async storePDF({ commit }, payload) {
    commit('SET_PDF_LOADING', true)
    commit('SET_PDF_ERROR')
    try {
      const response = await Axios.post(`${domain}/api/create-pdf`, payload, {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        }
      })
      commit('SET_CREATE_PDF', response.data.data)
      commit('SET_PDF_LOADING')
      return response.data.data
    } catch (error) {
      commit('SET_PDF_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PDF_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PDF_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
  },
  async delete_PDF({ commit }, payload) {
    commit('SET_PDF_LOADING', true)
    commit('SET_PDF_ERROR')
    try {
      const response = await Axios.delete(
        `${domain}/api/delete-pdf/${payload}`,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )

      commit('SET_DELETE_PDF', payload)
      commit('SET_PDF_LOADING')
      return response.data.data
    } catch (error) {
      commit('SET_PDF_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PDF_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PDF_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
  },
  async all_PDF({ commit }, payload) {
    commit('SET_PDF_LOADING', true)
    commit('SET_PDF_ERROR')
    try {
      const response = await Axios.get(
        `${domain}/api/display-pdfs/${payload}`,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('SET_ALL_PDF', response.data.data)
      commit('SET_PDF_LOADING')
      return response.data.data
    } catch (error) {
      commit('SET_PDF_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PDF_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PDF_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
  },
  resetErrorPDF({ commit }) {
    commit('SET_PDF_ERROR')
  }
}
export default {
  state,
  getters,
  mutations,
  actions
}
