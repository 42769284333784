import Entity from '../Entity'

export default class DayRow extends Entity {
  constructor(data) {
    super(data.id)
    this.active = data.active
    this.index = data.index
    this._lead = null
  }

  set lead(data) {
    this._lead = data
  }

  get lead() {
    return this._lead
  }

  static create(data) {
    if (!data) {
      throw new Error('DayRow::create: "data" is undefined')
    }
    return new DayRow(data)
  }
}
