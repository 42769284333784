import Entity from '../../Entity'
import Ressources from './Ressources'
export default class TypeResource extends Entity {
  constructor(data) {
    super(data.id)
    this.created_at = data.created_at
    this.designation = data.designation
    this.detail = data.detail
    this.fields = data.fields
    this.type_projet_id = data.type_projet_id
    this.ressources = []
    if (data.ressources && data.ressources.length) {
      this.ressources = data.ressources.map(s => Ressources.create(s))
    }
  }

  /**
   * @param {*} data
   */
  static create(data) {
    if (!data) {
      throw new Error('TypeResource::create: "data" is undefined')
    }
    return new TypeResource(data)
  }
  updateTypeResourceObjet(data) {
    this.created_at = data.created_at
    this.designation = data.designation
    this.detail = data.detail
    this.fields = data.fields
    this.type_projet_id = data.type_projet_id
    this.ressources = []
    if (data.ressources && data.ressources.length) {
      this.ressources = data.ressources.map(s => Ressources.create(s))
    }
  }
}
