import Entity from '../Entity'

export default class Source extends Entity {
  constructor(data) {
    super(data.id)
    this.name = data.name
    this.id_pixel = data.id_pixel
    this.active = data.active == 1 ? true : false
    this.internal = data.internal == 1 ? true : false
    this.id_pipedrive = data.id_pipedrive
    this.name_internal = data.name_internal
    this.comm_sed_id = data.comm_sed_id
    this.commercial_sedentaire = data.commercial_sedentaire
      ? data.commercial_sedentaire.data
      : null
    this.id_pixel_acf = data.id_pixel_acf
    this.id_pixel_fiber = data.id_pixel_fiber
    this.id_pixel_h2o = data.id_pixel_h2o
    this.id_pixel_vertuoz = data.id_pixel_vertuoz
    this.id_pixel_rse = data.id_pixel_rse
    this.id_pixel_gse = data.id_pixel_gse
  }
  /**
   * @param {*} data
   */
  static create(data) {
    if (!data) {
      throw new Error('Source::create: "data" is undefined')
    }
    return new Source(data)
  }
  update(data) {
    this.name = data.name
    this.id_pixel = data.id_pixel
    this.active = data.active == 1 ? true : false
    this.internal = data.internal == 1 ? true : false
    this.id_pipedrive = data.id_pipedrive
    this.name_internal = data.name_internal
    this.comm_sed_id = data.comm_sed_id
    this.commercial_sedentaire = data.commercial_sedentaire
      ? data.commercial_sedentaire.data
      : null
    this.id_pixel_acf = data.id_pixel_acf
    this.id_pixel_fiber = data.id_pixel_fiber
    this.id_pixel_h2o = data.id_pixel_h2o
    this.id_pixel_vertuoz = data.id_pixel_vertuoz
    this.id_pixel_rse = data.id_pixel_rse
    this.id_pixel_gse = data.id_pixel_gse
  }
}
