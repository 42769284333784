const state = { items: [] }
const getters = {
  getStockeSelectedItems: state => state.items
}
const mutations = {
  STOCK_SELECTED_ITEMS(state, payload) {
    state.items = payload
  }
}
const actions = {
  async stockeSelectedItem({ commit }, payload) {
    commit('STOCK_SELECTED_ITEMS', payload)
  }
}
export default {
  state,
  getters,
  mutations,
  actions
}
