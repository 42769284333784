import Axios from '@/axios'
import HistoryExportExcel from '../../models/crm/HistoryExportExcel'
import domain from '@/environment'
let request = null
const state = {
  historyExportError: null,
  historyExportLoading: false,
  listHistoryExportExcel: [],
  countPagination: 1
}
const getters = {
  getHistoryExportError: state => state.historyExportError,
  getHistoryExportLoading: state => state.historyExportLoading,
  getlistHistoryExportExcel: state => state.listHistoryExportExcel,
  countPagination: state => state.countPagination
}
const mutations = {
  SET_HISTORY_EXPORT_EXCEL_LOADING(state, payload = false) {
    state.historyExportLoading = payload
  },
  SET_HISTORY_EXPORT_EXCEL_ERROR(state, payload = null) {
    state.historyExportError = payload
  },
  SET_LIST_HISTORY_EXPORT_EXCEL(state, payload) {
    if (payload && payload.data && payload.data.length) {
      state.listHistoryExportExcel = payload.data.map(item =>
        HistoryExportExcel.create(item)
      )
      state.countPagination = payload.meta.pagination.total
    }
  }
}
const actions = {
  async fetchListHistoryExportExcel({ commit }, payload) {
    commit('SET_HISTORY_EXPORT_EXCEL_LOADING', true)
    commit('SET_HISTORY_EXPORT_EXCEL_ERROR')
    const params = {
      page: payload.page,
      per_page: payload.per_page,
      sort_by_desc: 'timestamp'
    }
    if (payload.full_name) {
      params.full_name = payload.full_name
    }
    try {
      if (request) {
        request.cancel()
      }
      request = Axios.CancelToken.source()
      const response = await Axios.get(domain + '/api/historiquesExcel', {
        cancelToken: request.token,
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        },
        params: params
      })
      commit('SET_LIST_HISTORY_EXPORT_EXCEL', response.data)
      commit('SET_HISTORY_EXPORT_EXCEL_LOADING')
    } catch (error) {
      commit('SET_HISTORY_EXPORT_EXCEL_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit(
          'SET_HISTORY_EXPORT_EXCEL_ERROR',
          error.response.data.error.messages
        )
      } else {
        commit('SET_HISTORY_EXPORT_EXCEL_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  }
}
export default {
  state,
  getters,
  mutations,
  actions
}
