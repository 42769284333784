import Axios from '@/axios'
import Planning from '../../models/planning/Planning'
import domain from '@/environment'
import Router from '@/router'

let inti_CPlanning_Request = null
let fetch_CPlanning_Request = null
const state = {
  commercials: [],
  cplanning: null,
  errorCplanning: null,
  processingCplanning: false
}
const getters = {
  getAvailableCommercial: state => state.commercials,
  getCPlanning: state => state.cplanning,
  getCPlanningError: state => state.errorCplanning,
  getCPlanningProcessing: state => state.processingCplanning
}
const mutations = {
  SET_CPLANNING_PROCESSING(state, payload) {
    state.processingCplanning = payload
    state.errorCplanning = null
  },
  SET_CPLANNING_ERROR(state, payload) {
    state.errorCplanning = payload
    state.processingCplanning = false
  },
  REMOVE_CPLANNING_ERROR(state) {
    state.errorCplanning = null
  },
  REMOVE_CPLANNING_PLANNING(state) {
    state.cplanning = null
  },
  INIT_CPLANNING(state, payload) {
    state.cplanning = Planning.create(payload)
  },
  SET_PLANNING_COMMERCIALS(state, payload) {
    const commercial = state.cplanning.commercials.find(
      t => t.id === payload.comm_ite_id
    )
    if (commercial) {
      commercial.setWeekSummary(payload.totals_week)
      commercial.setDays(payload.days)
      // commercial.zipcode = payload.zipcode
      if (payload.name) {
        commercial.name = payload.name
      }
      commercial.loading = false
    }
  },
  SET_CPLANNING_HEAD_VALUES(state) {
    state.cplanning.computeGlobalValuesCPlanning()
  },
  SET_CPLANNING_CEE_PHOTO_MEETING(state, payload) {
    payload.lead[payload.field] = payload.value
  },
  SET_CPLANNING_OPTIMISE(state, payload) {
    payload.day.optimised = payload.response.optimised
  },
  SET_CPLANNING_ROW_STATUS(state, payload) {
    payload.row.active = payload.response[payload.row.id] ? true : false
    payload.day.computeRows()
  },
  SET_CPLANNING_DO_NOTHING() {}
}
const actions = {
  async initCPlanning({ commit }, payload) {
    commit('REMOVE_CPLANNING_ERROR')
    commit('SET_CPLANNING_PROCESSING', true)
    commit('REMOVE_CPLANNING_PLANNING')
    try {
      if (inti_CPlanning_Request) {
        inti_CPlanning_Request.cancel()
      }
      inti_CPlanning_Request = Axios.CancelToken.source()
      const response = await Axios.get(
        `${domain}/api/depots/${payload.depot_id}/weeks/${payload.week_id}/coms-ite`,
        {
          cancelToken: inti_CPlanning_Request.token,
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      if (response && response.data) {
        commit('INIT_CPLANNING', response.data)
        commit('SET_CPLANNING_PROCESSING', false)
      }
    } catch (error) {
      commit('INIT_CPLANNING', {})
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_CPLANNING_ERROR', error.response.data.error.messages)
      } else if (error.message) {
        commit('SET_CPLANNING_ERROR', [error.message])
      } else {
        if (!Axios.isCancel(error)) {
          commit('SET_CPLANNING_ERROR', 'unknown error')
        }
      }
    }
  },
  async fetchCPlanning({ commit }) {
    // check for commercials
    commit('SET_CPLANNING_PROCESSING', true)
    if (
      this.state.planning.deposits &&
      this.state.planning.deposits.length == 0
    ) {
      commit('SET_CPLANNING_ERROR', ["Ce dépôt n'existe pas ."])
      return
    }
    if (
      !this.state.cPlanning.cplanning.commercials ||
      !this.state.cPlanning.cplanning.commercials.length
    ) {
      commit('SET_CPLANNING_ERROR', [
        'Ce dépôt ne contient pas des commerciaux'
      ])
      return
    }
    try {
      // extract first commercial
      const commercial = this.state.cPlanning.cplanning.commercials[0]
      let nextId = commercial.id
      while (nextId !== null) {
        if (Router.history.current.name != 'planning-commerciaux-id_week') {
          return
        }
        const data = {
          depot_id: this.state.cPlanning.cplanning.depot_id,
          week_id: this.state.cPlanning.cplanning.week_id,
          comm_ite_id: nextId
        }
        if (fetch_CPlanning_Request) {
          fetch_CPlanning_Request.cancel()
        }
        fetch_CPlanning_Request = Axios.CancelToken.source()
        const response = await Axios.post(
          domain + '/api/planningCommIte',
          data,
          {
            cancelToken: fetch_CPlanning_Request.token,
            headers: {
              Authorization: `Bearer ${this.state.currentUser.token}`
            }
          }
        )
        if (response && response.data) {
          commit('SET_PLANNING_COMMERCIALS', response.data)
          if (response.data && response.data.$next_comm_id) {
            nextId = response.data.$next_comm_id
          } else {
            nextId = null
          }
        }
      }
      commit('SET_CPLANNING_HEAD_VALUES')
      commit('SET_CPLANNING_PROCESSING', false)
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_CPLANNING_ERROR', error.response.data.error.messages)
      } else if (error.message) {
        commit('SET_CPLANNING_ERROR', [error.message])
      } else {
        if (!Axios.isCancel(error)) {
          commit('SET_CPLANNING_ERROR', 'unknown error')
        }
      }
    }
  },
  async changeCEEPhotoMeetingCommercial({ commit }, payload) {
    commit('REMOVE_CPLANNING_ERROR')
    try {
      if (payload && payload.field && payload.lead) {
        const data = {
          [payload.field]: payload.value
        }
        await Axios.put(
          `${domain}/api/crm/projets/${payload.lead.project_id}`,
          data,
          {
            headers: {
              Authorization: `Bearer ${this.state.currentUser.token}`
            }
          }
        )
        commit('SET_CPLANNING_CEE_PHOTO_MEETING', payload)
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_CPLANNING_ERROR', error.response.data.error.messages)
      } else if (error.message) {
        commit('SET_CPLANNING_ERROR', [error.message])
      } else {
        commit('SET_CPLANNING_ERROR', ['Une erreur est survenue'])
      }
    }
  },
  async getSimulatedTrajetCPlanning({ commit }, payload) {
    commit('REMOVE_CPLANNING_ERROR')
    try {
      if (payload && payload.origin && payload.destination) {
        var headers = new Headers()

        headers.append('Content-Type', 'application/x-www-form-urlencoded')
        headers.append(
          'Authorization',
          `Bearer ${this.state.currentUser.token}`
        )

        const body = new URLSearchParams()
        body.append('start_address', payload.origin)
        body.append('end_address', payload.destination)

        var requestOptions = {
          method: 'POST',
          headers: headers,
          body: body,
          redirect: 'manual'
        }

        commit('SET_CPLANNING_DO_NOTHING')
        const response = await fetch(
          `${domain}/api/calc-trajet-test`,
          requestOptions
        )
        const result = await response.json()

        if (result.trajet_duration) {
          return result.trajet_duration
        } else {
          return 0
        }
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_CPLANNING_ERROR', error.response.data.error.messages)
      } else if (error.message) {
        commit('SET_CPLANNING_ERROR', [error.message])
      } else {
        commit('SET_CPLANNING_ERROR', ['Une erreur est survenue'])
      }
      return 0
    }
  },
  async updateFieldOptimiseCPlanning({ commit }, payload) {
    commit('REMOVE_CPLANNING_ERROR')
    try {
      if (payload && payload.day) {
        const response = await Axios.get(
          `${domain}/api/planning-day-comm/${payload.day.id}/field-optimised`,
          {
            headers: {
              Authorization: `Bearer ${this.state.currentUser.token}`
            }
          }
        )
        commit('SET_CPLANNING_OPTIMISE', {
          ...payload,
          response: response.data
        })
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_CPLANNING_ERROR', error.response.data.error.messages)
      } else if (error.message) {
        commit('SET_CPLANNING_ERROR', [error.message])
      } else {
        commit('SET_CPLANNING_ERROR', ['Une erreur est survenue'])
      }
    }
  },
  async updateRowStatusCPlanning({ commit }, payload) {
    commit('REMOVE_CPLANNING_ERROR')
    try {
      if (payload && payload.day && payload.row) {
        const response = await Axios.get(
          `${domain}/api/planning-day-comm/${payload.day.id}/row-index-${payload.row.index}-on-off`,
          {
            headers: {
              Authorization: `Bearer ${this.state.currentUser.token}`
            }
          }
        )
        commit('SET_CPLANNING_ROW_STATUS', {
          ...payload,
          response: response.data
        })
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_CPLANNING_ERROR', error.response.data.error.messages)
      } else if (error.message) {
        commit('SET_CPLANNING_ERROR', [error.message])
      } else {
        commit('SET_CPLANNING_ERROR', ['Une erreur est survenue'])
      }
    }
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
