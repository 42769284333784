import Entity from '../Entity'

export default class Group extends Entity {
  constructor(data) {
    super(data.id)
    this.created_at = data.created_at
    this.updated_at = data.updated_at
    this.description = data.description
    this.name = data.name
    this.type_projet_id = data.type_projet_id
    this.admins = []
    if (data.admins) {
      this.admins = data.admins
    }
    this.users = []
    if (data.users) {
      this.users = data.users
    }
  }

  /**
   * @param {*} data
   */
  static create(data) {
    if (!data) {
      throw new Error('Group::create: "data" is undefined')
    }
    return new Group(data)
  }
  updateGroupObjet(data) {
    this.created_at = data.created_at
    this.updated_at = data.updated_at
    this.description = data.description
    this.name = data.name
    this.type_projet_id = data.type_projet_id
    this.admins = data.admins
    this.users = data.users
  }
}
