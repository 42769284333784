import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

Vue.use(VueRouter)

// Visibilities
const isSuper = () => {
  if (store.state.currentUser && store.state.currentUser.isSuper) {
    return true
  }
  return false
}
const userAcces = () => {
  if (store.state.currentUser && store.state.currentUser.code_client != null) {
    return true
  } else {
    return false
  }
}
const isFromRegie = () => {
  if (
    store.state.currentUser &&
    (store.state.currentUser.type == 'user.commercial' ||
      store.state.currentUser.type == 'user.final' ||
      (store.state.currentUser.type == 'user.admin' &&
        store.state.currentUser.details &&
        store.state.currentUser.details.team &&
        store.state.currentUser.details.team.data &&
        store.state.currentUser.details.team.data.type == 'regie'))
  ) {
    return true
  } else if (store.state.currentUser.team_id == 425) {
    // filiale : ATELIER E-LEARNING
    return true
  }
  return false
}

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
    children: [
      {
        path: 'dashboard',
        name: 'dashboard',
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '@/components/CrmIla26Component/Dashboard.vue'
          ),
        beforeEnter: (to, from, next) => {
          if (!userAcces()) {
            next()
          } else {
            next('/')
          }
        }
      },
      {
        path: 'planning-poseurs',
        name: 'planning-poseurs',
        component: () =>
          import(
            /* webpackChunkName: "planning" */ '../views/Planning/Planning.vue'
          ),
        children: [
          {
            path: 'depot/:id_depot',
            name: 'planning-poseurs-id_depot',
            component: () =>
              import(
                /* webpackChunkName: "planning-poseurs-id_depot" */ '../views/Planning/Planning.vue'
              ),
            children: [
              {
                path: 'semaine/:id_week',
                name: 'planning-poseurs-id_week',
                component: () =>
                  import(
                    /* webpackChunkName: "planning-poseurs-id_week" */ '../views/Planning/Planning.vue'
                  )
              }
            ]
          }
        ],
        beforeEnter: (to, from, next) => {
          if (!isFromRegie()) {
            next()
          } else {
            next('/')
          }
        }
      },
      {
        path: 'planning-commerciaux',
        name: 'planning-commerciaux',
        component: () =>
          import(
            /* webpackChunkName: "CPlanning" */ '../views/CPlanning/CPlanning.vue'
          ),
        children: [
          {
            path: 'depot/:id_depot',
            name: 'planning-commerciaux-id_depot',
            component: () =>
              import(
                /* webpackChunkName: "planning-commerciaux-id_depot" */ '../views/CPlanning/CPlanning.vue'
              ),
            children: [
              {
                path: 'semaine/:id_week',
                name: 'planning-commerciaux-id_week',
                component: () =>
                  import(
                    /* webpackChunkName: "planning-commerciaux-id_week" */ '../views/CPlanning/CPlanning.vue'
                  )
              }
            ]
          }
        ],
        beforeEnter: (to, from, next) => {
          if (!isFromRegie()) {
            next()
          } else {
            next('/')
          }
        }
      },
      {
        path: 'reporting',
        name: 'Reporting',
        redirect: 'reporting/statistics',
        component: () =>
          import(/* webpackChunkName: "reporting" */ '../views/Reporting'),
        children: [
          {
            path: 'statistics',
            name: 'Statistics',
            component: () =>
              import(
                /* webpackChunkName: "statistics" */ '../views/Reporting/Statistics.vue'
              )
          },
          {
            path: 'terrain',
            name: 'Terrain',
            component: () =>
              import(
                /* webpackChunkName: "terrain" */ '../views/Reporting/Terrain.vue'
              )
          },
          {
            path: 'objectifs',
            name: 'Objectifs',
            component: () =>
              import(
                /* webpackChunkName: "objectifs" */ '../views/Reporting/Objectifs.vue'
              )
          }
        ],
        beforeEnter: (to, from, next) => {
          if (!isFromRegie()) {
            next()
          } else {
            next('/')
          }
        }
      },
      {
        path: 'utils/devis-ite',
        name: 'devis-ite',
        component: () =>
          import(
            /* webpackChunkName: "Devis-ite" */ '../views/Utils/DevisIte.vue'
          ),
        beforeEnter: (to, from, next) => {
          if (!userAcces()) {
            next()
          } else {
            next('/')
          }
        }
      },
      {
        path: 'utils/devis-iti',
        name: 'devis-iti',
        component: () =>
          import(
            /* webpackChunkName: "Devis-iti" */ '../views/Utils/DevisIti.vue'
          ),
        beforeEnter: (to, from, next) => {
          if (!userAcces()) {
            next()
          } else {
            next('/')
          }
        }
      },
      {
        path: 'utils/devis-audit',
        name: 'devis-audit',
        component: () =>
          import(
            /* webpackChunkName: "devis-audi" */ '../views/Utils/DevisAudit.vue'
          ),
        beforeEnter: (to, from, next) => {
          if (!userAcces()) {
            next()
          } else {
            next('/')
          }
        }
      },
      {
        path: 'projects',
        name: 'projects',
        component: () =>
          import(
            /* webpackChunkName: "projects" */ '../views/CRMILA26/Projects/indexProjectTheme.vue'
          ),
        children: [
          {
            path: ':id',
            name: 'project',
            component: () =>
              import(
                /* webpackChunkName: "project" */ '../views/CRMILA26/Projects/indexProjectTheme.vue'
              )
          },
          {
            path: ':id/activity',
            name: 'project_activity',
            component: () =>
              import(
                /* webpackChunkName: "project_activity" */ '../views/CRMILA26/Projects/index.vue'
              )
          }
        ],
        beforeEnter: (to, from, next) => {
          if (!userAcces()) {
            next()
          } else {
            next('/')
          }
        }
      },
      {
        path: 'client',
        name: 'client',
        component: () =>
          import(
            /* webpackChunkName: "client" */ '../views/CRMILA26/Client/index.vue'
          )
      },
      {
        path: 'planning',
        name: 'planning',
        component: () =>
          import(
            /* webpackChunkName: "planning" */ '../views/CRMILA26/Plannings/index.vue'
          )
      },
      // SAV
      {
        path: 'sav',
        component: () =>
          import(
            /* webpackChunkName: "sav" */ '../views/CRMILA26/SAV/index.vue'
          ),
        children: [
          // SAV
          {
            path: '',
            name: 'sav',
            component: () =>
              import(
                /* webpackChunkName: "sav" */ '../views/CRMILA26/SAV/SAV.vue'
              )
          },
          // SERVICE CLIENT
          {
            path: 'service-clients',
            name: 'service-clients',
            component: () =>
              import(
                /* webpackChunkName: "service-clients" */ '../views/CRMILA26/SAV/ServiceClients.vue'
              )
          }
        ]
      },
      //PASSWORD
      {
        path: 'password',
        name: 'password',
        component: () =>
          import(
            /* webpackChunkName: "Change_password-password" */ '../views/CRMILA26/Config/Password.vue'
          )
      },
      // CATALOG
      {
        path: 'catalog',
        name: 'catalog',
        component: () =>
          import(
            /* webpackChunkName: "catalog" */ '../views/CRMILA26/Projects/immobilier/pipline/Catalog/Catalog.vue'
          )
      },
      // CONTACT
      {
        path: 'contacts',
        name: 'contacts',
        component: () =>
          import(
            /* webpackChunkName: "contacts" */ '../views/CRMILA26/Projects/immobilier/pipline/Contact/Contact.vue'
          )
      },
      // SETTING
      {
        path: 'setting',
        redirect: 'setting/users',
        component: () =>
          import(
            /* webpackChunkName: "setting" */ '../views/CRMILA26/Config/Setting.vue'
          ),
        children: [
          {
            path: 'users',
            component: () =>
              import(
                /* webpackChunkName: "users-index" */ '../views/CRMILA26/Config/Users/index.vue'
              ),
            children: [
              // USERS
              {
                path: '',
                name: 'users',
                component: () =>
                  import(
                    /* webpackChunkName: "users" */ '../views/CRMILA26/Config/Users/Users.vue'
                  )
              },
              // PERMISSION
              {
                path: 'permissions',
                name: 'permission',
                component: () =>
                  import(
                    /* webpackChunkName: "permission" */ '../views/CRMILA26/Config/Users/Permission.vue'
                  )
              },
              // CORBEILLE
              {
                path: 'corbeilles',
                name: 'corbeille',
                component: () =>
                  import(
                    /* webpackChunkName: "corbeille" */ '../views/CRMILA26/Config/Users/Corbeille.vue'
                  )
              }
            ]
          },
          // VISIBILITY REGIE AND PLANNING
          {
            path: 'visibility',
            redirect: 'visibility/visibilityRegie',
            component: () =>
              import(
                /* webpackChunkName: "VisibilityRegie-index" */ '../views/CRMILA26/Config/Visibility/index.vue'
              ),
            children: [
              {
                path: 'visibilityRegie',
                name: 'visibilityRegie',
                component: () =>
                  import(
                    /* webpackChunkName: "visibilityRegie" */ '../views/CRMILA26/Config/Visibility/VisibilityRegie.vue'
                  )
              },
              {
                path: 'visibilityPlanning',
                name: 'visibilityPlanning',
                component: () =>
                  import(
                    /* webpackChunkName: "VisibilityPlanning" */ '../views/CRMILA26/Config/Visibility/VisibilityPlanning.vue'
                  )
              }
            ],
            beforeEnter: (to, from, next) => {
              if (!userAcces()) {
                next()
              } else {
                next('/')
              }
            }
          },
          // PPIPELINES
          {
            path: 'pipeline',
            name: 'etapes',
            component: () =>
              import(
                /* webpackChunkName: "setting-etapes" */ '../views/CRMILA26/Config/Pipeline/Etapes/Etapes.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (!userAcces()) {
                next()
              } else {
                next('/')
              }
            }
          },
          {
            path: 'pipeline/:id',
            name: 'etapesUpdate',
            component: () =>
              import(
                /* webpackChunkName: "setting-etapesUpdate" */ '../views/CRMILA26/Config/Pipeline/Etapes/EtapesUpdate.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (!userAcces()) {
                next()
              } else {
                next('/')
              }
            }
          },
          // ETAPES
          // {
          //   path: 'pipeline/:id/etapes',
          //   name: 'etapes',
          //   component: () =>
          //     import(
          //       /* webpackChunkName: "setting-etapes" */ '../views/CRMILA26/Config/Pipeline/Etapes/Etapes.vue'
          //     ),
          //   beforeEnter: (to, from, next) => {
          //     if (!userAcces()) {
          //       next()
          //     } else {
          //       next('/')
          //     }
          //   }
          // },
          // {
          //   path: 'pipeline/:id/etapes/:id_etape',
          //   name: 'etapesUpdate',
          //   component: () =>
          //     import(
          //       /* webpackChunkName: "setting-etapesUpdate" */ '../views/CRMILA26/Config/Pipeline/Etapes/EtapesUpdate.vue'
          //     ),
          //   beforeEnter: (to, from, next) => {
          //     if (!userAcces()) {
          //       next()
          //     } else {
          //       next('/')
          //     }
          //   }
          // },
          // TYPES PROJET
          {
            path: 'projects-types',
            name: 'projectsTypes',
            component: () =>
              import(
                /* webpackChunkName: "setting-projects-types" */ '../views/CRMILA26/Config/TypeProjet/ProjectsType.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (!userAcces()) {
                next()
              } else {
                next('/')
              }
            }
          },
          // TYPES PROJET UPDATE
          {
            path: 'projects-types/:id',
            name: 'projectsTypesUpdate',
            component: () =>
              import(
                /* webpackChunkName: "setting-projects-typesUpdate" */ '../views/CRMILA26/Config/TypeProjet/ProjectsTypeUpdate.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (!userAcces()) {
                next()
              } else {
                next('/')
              }
            }
          },
          // PRECARITE
          {
            path: 'precarite',
            name: 'Precarite',
            component: () =>
              import(
                /* webpackChunkName: "setting-precarite" */ '../views/CRMILA26/Config/Precarite.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (!userAcces()) {
                next()
              } else {
                next('/')
              }
            }
          },
          // VISIBILITY PROJET
          {
            path: 'visibility-project',
            name: 'Visibility-project',
            component: () =>
              import(
                /* webpackChunkName: "setting-visibility-project" */ '../views/CRMILA26/Config/VisibilityProject.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (!userAcces()) {
                next()
              } else {
                next('/')
              }
            }
          },
          // AFFECTATION LEADS
          {
            path: 'affectation-leads',
            name: 'affectation-leads',
            component: () =>
              import(
                /* webpackChunkName: "setting-affectation-leads" */ '../views/Setting/AffectationLeads.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (!userAcces()) {
                next()
              } else {
                next('/')
              }
            }
          },
          // HISTORIQUE USERS
          {
            path: 'historique-users',
            name: 'historique-users',
            component: () =>
              import(
                /* webpackChunkName: "setting-historique-users" */ '../views/CRMILA26/Config/HistoriqueUsers.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (!userAcces()) {
                next()
              } else {
                next('/')
              }
            }
          },
          // HISTORIQUE EXPORT EXCEL
          {
            path: 'history-export-excel',
            name: 'history-export-excel',
            component: () =>
              import(
                /* webpackChunkName: "setting-history-export-excel" */ '../views/CRMILA26/Config/Historique/HistoryExportExcel.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (!userAcces()) {
                next()
              } else {
                next('/')
              }
            }
          },
          // WEBHOOK
          {
            path: 'webhook',
            name: 'Webhook',
            component: () =>
              import(
                /* webpackChunkName: "setting-webhook" */ '../views/CRMILA26/Config/Webhook.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (!userAcces()) {
                next()
              } else {
                next('/')
              }
            }
          },
          // API TOKEN
          {
            path: 'api',
            name: 'Api',
            component: () =>
              import(
                /* webpackChunkName: "setting-api" */ '../views/CRMILA26/Config/Api.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (!userAcces()) {
                next()
              } else {
                next('/')
              }
            }
          },
          // SOURCES
          {
            path: 'sources',
            name: 'Sources',
            component: () =>
              import(
                /* webpackChunkName: "setting-sources" */ '../views/CRMILA26/Config/Sources.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (!userAcces()) {
                next()
              } else {
                next('/')
              }
            }
          },
          // EQUIPES
          {
            path: 'teams',
            name: 'Teams',
            component: () =>
              import(
                /* webpackChunkName: "setting-teams" */ '../views/CRMILA26/Config/Teams.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (!userAcces()) {
                next()
              } else {
                next('/')
              }
            }
          },
          // GROUPE
          {
            path: 'groupe',
            name: 'groupe',
            component: () =>
              import(
                /* webpackChunkName: "setting-groupe" */ '../views/CRMILA26/Config/Group.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (!userAcces()) {
                next()
              } else {
                next('/')
              }
            }
          },
          // DEPOT
          {
            path: 'deposits',
            name: 'Deposits',
            component: () =>
              import(
                /* webpackChunkName: "setting-deposits" */ '../views/CRMILA26/Config/Deposits.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (!userAcces()) {
                next()
              } else {
                next('/')
              }
            }
          },
          // CUSTOMFIELDS
          {
            path: 'champs-personnalises',
            name: 'champs-personnalises',
            redirect: 'champs-personnalises/contact',
            component: () =>
              import(
                /* webpackChunkName: "setting-champs-personnalises" */ '../views/CRMILA26/Config/Fields/index.vue'
              ),
            children: [
              {
                path: 'contact',
                name: 'contact',
                component: () =>
                  import(
                    /* webpackChunkName: "contact" */ '../views/CRMILA26/Config/Fields/FieldsContact.vue'
                  )
              },
              {
                path: 'projet',
                name: 'projet',
                component: () =>
                  import(
                    /* webpackChunkName: "projet" */ '../views/CRMILA26/Config/Fields/FieldsProjet.vue'
                  )
              },
              {
                path: 'type_ressources',
                name: 'type_ressources',
                component: () =>
                  import(
                    /* webpackChunkName: "type_ressources" */ '../views/CRMILA26/Config/Fields/FieldsTypeResource.vue'
                  )
              },
              {
                path: 'type_events',
                name: 'type_events',
                component: () =>
                  import(
                    /* webpackChunkName: "type_events" */ '../views/CRMILA26/Config/Fields/FieldsTypeEvents.vue'
                  )
              }
            ],
            beforeEnter: (to, from, next) => {
              if (!userAcces()) {
                next()
              } else {
                next('/')
              }
            }
          },
          // CONNEXION
          {
            path: 'connection',
            name: 'Connection',
            component: () =>
              import(
                /* webpackChunkName: "setting-connection" */ '../views/CRMILA26/Config/Connection.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (!userAcces()) {
                next()
              } else {
                next('/')
              }
            }
          },
          // CATEGORIES
          {
            path: 'categorie',
            redirect: 'categorie/contacts',
            component: () =>
              import(
                /* webpackChunkName: "setting-categorie" */ '../views/CRMILA26/Config/Categories/index.vue'
              ),
            children: [
              {
                path: 'contacts',
                name: 'categorie-contacts',
                component: () =>
                  import(
                    /* webpackChunkName: "categorie-contacts" */ '../views/CRMILA26/Config/Categories/Categorie.vue'
                  )
              },
              {
                path: 'projets',
                name: 'categorie-projets',
                component: () =>
                  import(
                    /* webpackChunkName: "categorie-projets" */ '../views/CRMILA26/Config/Categories/Categorie.vue'
                  )
              }
            ],
            beforeEnter: (to, from, next) => {
              if (!userAcces()) {
                next()
              } else {
                next('/')
              }
            }
          },
          // CHILDREN CATEGORY CONTACT
          {
            path: 'categorie/contacts/:id',
            name: 'categorie-update-contact',
            component: () =>
              import(
                /* webpackChunkName: "setting-categorie-update-contact" */ '../views/CRMILA26/Config/Categories/SousCategorie.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (!userAcces()) {
                next()
              } else {
                next('/')
              }
            }
          },
          {
            path: 'categorie/contacts/:id/subCategorie/:idSubCategorie',
            name: 'subcategorie-update-contact',
            component: () =>
              import(
                /* webpackChunkName: "settings-subcategorie-update-contact" */ '../views/CRMILA26/Config/Categories/FieldsCategorie.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (!userAcces()) {
                next()
              } else {
                next('/')
              }
            }
          },
          // CHILDREN CATEGORY PROJET
          {
            path: 'categorie/projets/:id',
            name: 'categorie-update-projet',
            component: () =>
              import(
                /* webpackChunkName: "setting-categorie-update-projet" */ '../views/CRMILA26/Config/Categories/SousCategorie.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (!userAcces()) {
                next()
              } else {
                next('/')
              }
            }
          },
          {
            path: 'categorie/projets/:id/subCategorie/:idSubCategorie',
            name: 'subcategorie-update-projet',
            component: () =>
              import(
                /* webpackChunkName: "settings-subcategorie-update-projet" */ '../views/CRMILA26/Config/Categories/FieldsCategorie.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (!userAcces()) {
                next()
              } else {
                next('/')
              }
            }
          },
          // EVENT
          {
            path: 'type-evenements',
            component: () =>
              import(
                /* webpackChunkName: "setting-index" */ '../views/CRMILA26/Config/Evenements/index.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (!userAcces()) {
                next()
              } else {
                next('/')
              }
            }
          },
          // UPDATE TYPE EVENT
          {
            path: 'type-evenements/fields/:id',
            name: 'type-evenements-fields',
            component: () =>
              import(
                /* webpackChunkName: "setting-type-event-field" */ '../views/CRMILA26/Config/Evenements/EvenementsTypeFields.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (!userAcces()) {
                next()
              } else {
                next('/')
              }
            }
          },
          // RESSOURCES
          {
            path: 'resource',
            redirect: 'resource/resources',
            component: () =>
              import(
                /* webpackChunkName: "setting-index" */ '../views/CRMILA26/Config/Resources/index.vue'
              ),
            children: [
              {
                path: 'resources',
                name: 'resources',
                component: () =>
                  import(
                    /* webpackChunkName: "setting-resources" */ '../views/CRMILA26/Config/Resources/Resources.vue'
                  )
              },
              {
                path: 'type-resources',
                name: 'type-resources',
                component: () =>
                  import(
                    /* webpackChunkName: "setting-type-resources" */ '../views/CRMILA26/Config/Resources/TypeResources.vue'
                  )
              }
            ],
            beforeEnter: (to, from, next) => {
              if (!userAcces()) {
                next()
              } else {
                next('/')
              }
            }
          },
          // UPDATE TYPE RESOURCE
          {
            path: 'resource/type-resources/:id',
            name: 'type-resource-field',
            component: () =>
              import(
                /* webpackChunkName: "setting-type-resource-field" */ '../views/CRMILA26/Config/Resources/TypeResourceFields.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (!userAcces()) {
                next()
              } else {
                next('/')
              }
            }
          },
          // TEMPLATE
          {
            path: 'templates',
            redirect: 'templates/email',
            component: () =>
              import(
                /* webpackChunkName: "templates-index" */ '../views/CRMILA26/Config/Template/index.vue'
              ),
            children: [
              {
                path: 'email',
                name: 'templates',
                component: () =>
                  import(
                    /* webpackChunkName: "templates" */ '../views/CRMILA26/Config/Template/TemplateEmail.vue'
                  )
              },
              {
                path: 'sms',
                name: 'templatesSms',
                component: () =>
                  import(
                    /* webpackChunkName: "templatesSms" */ '../views/CRMILA26/Config/Template/TemplateSms.vue'
                  )
              }
            ],
            beforeEnter: (to, from, next) => {
              if (!userAcces()) {
                next()
              } else {
                next('/')
              }
            }
          },
          // HISTORIQUE PLANNINGS
          {
            path: 'history-planning',
            redirect: 'history-planning/poseur',
            component: () =>
              import(
                /* webpackChunkName: "historiquesPlanning-index" */ '../views/CRMILA26/Config//Historique/HistoryPlanning/index.vue'
              ),
            children: [
              {
                path: 'poseur',
                name: 'history-planning',
                component: () =>
                  import(
                    /* webpackChunkName: "HistoriquePlanningPoseur" */ '../views/CRMILA26/Config//Historique/HistoryPlanning/HistoryPlanningPoseur.vue'
                  )
              },
              {
                path: 'commerciaux',
                name: 'commerciaux',
                component: () =>
                  import(
                    /* webpackChunkName: "HistoriquePlanningCom" */ '../views/CRMILA26/Config//Historique/HistoryPlanning/HistoryPlanningCom.vue'
                  )
              }
            ],
            beforeEnter: (to, from, next) => {
              if (!userAcces()) {
                next()
              } else {
                next('/')
              }
            }
          }
        ],
        beforeEnter: (to, from, next) => {
          // store.state.currentUser.type == 'user.admin'
          if (isSuper() || isFromRegie()) {
            next()
          } else {
            next('/')
          }
        }
      },
      // NOTIFICATIONS
      {
        path: 'notifications',
        name: 'notifications',
        component: () =>
          import(
            /* webpackChunkName: "Notifications" */ '../views/CRMILA26/Config/Notifications.vue'
          ),
        beforeEnter: (to, from, next) => {
          if (!userAcces()) {
            next()
          } else {
            next('/')
          }
        }
      }
    ],
    beforeEnter: (to, from, next) => {
      if (store.state.currentUser) {
        next()
      } else {
        next('/login')
      }
    }
  },
  {
    path: '/login',
    name: 'login',
    beforeEnter: (to, from, next) => {
      if (!store.state.currentUser) {
        next()
      } else {
        next('/')
      }
    },
    component: () =>
      import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '/sign',
    name: 'sign',
    component: () => import(/* webpackChunkName: "sign" */ '../views/Sign.vue')
  },
  {
    path: '/error',
    name: 'error',
    component: () =>
      import(/* webpackChunkName: "error" */ '../views/Error.vue')
  },
  {
    path: '*',
    redirect: '/error'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
const DEFAULT_TITLE = 'CRM Abondance'
router.afterEach(() => {
  Vue.nextTick(() => {
    document.title = DEFAULT_TITLE
  })
})
export default router
