import Vue from 'vue'
import Vuex from 'vuex'
import Axios from '@/axios'
import Router from '@/router'
import { createStore } from 'vuex-extensions'

import planning from './modules/planning'
import cPlanning from './modules/cPlanning'
import reporting from './modules/reporting'
import devisIte from './modules/utils/devisIte'
import devisIti from './modules/utils/devisIti'
import devisAudit from './modules/utils/devisAudit'
import projects from './modules/projects'
import pipelines from './modules/pipelines'
import projectTypes from './modules/projectTypes'
import customFields from './modules/customFields'
import api from './modules/api'
import precarite from './modules/precarite'
import contacts from './modules/contacts'
import visibilityRegie from './modules/VisibilityRegie'
import permission from './modules/permission'
import search from './modules/search'
import visibilityProject from './modules/visibilityProject'
import filtersProjectTH from './modules/filtersProjectTH'
import pixel from './modules/pixel'
import affectationLeads from './modules/affectationLeads'
import Source from '../models/crm/Source'
import Webhook from '../models/crm/Webhook'
import categorie from './modules/categorie'
import historiqueUsers from './modules/historiqueUsers'
import historyExportExcel from './modules/historyExportExcel'
import historyPlanningPoseur from './modules/HistoryPlanningPoseur'
import historyPlanningCommerciaux from './modules/HistoryPlanningCommerciaux'
import template from './modules/template'
import pusher from '../pusher'
import notifications from './modules/notifications'
import releve from './modules/releve'
import etapes from './modules/etapes'
import resources from './modules/resources'
import groupe from './modules/groupe'
import tableDynamic from './modules/tableDynamic'
import typeEvent from './modules/evenements/typeEvent'
import events from './modules/evenements/events'
import contactsImmo from './modules/contactsImmo'
import moment from 'moment'
import domain from '@/environment'
import Calendrier from './modules/Calendrier'
import actionEvent from './modules/evenements/actions'
let request = null
let requestTeams = null
Vue.use(Vuex)
export default createStore(Vuex.Store, {
  //export default new Vuex.Store({
  state: {
    /**
     * Authentication
     */
    currentUser:
      localStorage.getItem('reporting-regie-user') != null
        ? JSON.parse(localStorage.getItem('reporting-regie-user'))
        : null,
    loginError: null,
    processing: false,
    /**
     * reporting regie
     */
    lastNoteIndex: null,
    selectedRegie: null,
    supervisedRegies: [],
    /**
     * Setting
     */
    // users
    users: [],
    userLoading: false,
    depotsLoadingTechniciens: false,
    depotsLoadingCommercialIte: false,
    usersCount: 0,
    owners: [],
    regies: [],
    superiors: [],
    pipeline: [],
    stages: [],
    usertypes: [],
    listCommercailsUser: [],
    listCommercailsUserUpdate: [],
    technicianspipedrive: [],
    commerciauxpipedrive: [],
    usersPipedriveNoSelectionne: [],
    property: [],
    weeks: [],
    // teams
    teamsLoading: false,
    teams: [],
    admins: [],
    adminsGlobal: [],
    commercials: [],
    technicians: [],
    commercialIte: [],
    teamsTypes: [],
    membresGe: [],
    subStages: [],
    adminUpdate: [],
    regieUpdate: [],
    //depots
    deposits: [],
    departements: [],
    teamsfiliale: [],
    depotsNoTeam: [],
    usersConnections: [],
    lastUpdatedTime: null,
    usersConnectionsError: null,
    //Source
    sourceLoading: false,
    sourceError: null,
    sources: [],
    //Webhook
    webhookLoading: false,
    webhookError: null,
    webhook: [],
    // corbeille
    corbeilleUsers: [],
    usersCorbeilleCount: 0,
    //project type
    curentProjectType: null,
    eventStatus: [
      { text: 'En attente', value: 'En attente' },
      { text: 'En cours', value: 'En cours' },
      { text: 'Terminé', value: 'Terminé' }
    ],
    loaderThemeForProject: false,
    allUsers: []
  },
  getters: {
    currentUser: state => state.currentUser,
    processing: state => state.processing,
    loginError: state => state.loginError,
    lastNoteIndex: state => state.lastNoteIndex,
    selectedRegie: state => state.selectedRegie,
    supervisedRegies: state => state.supervisedRegies,
    users: state => state.users,
    getUserLoading: state => state.userLoading,
    usersCount: state => state.usersCount,
    usersPipedriveNoSelectionne: state => state.usersPipedriveNoSelectionne,
    owners: state => state.owners,
    property: state => state.property,
    regies: state => state.regies,
    superiors: state => state.superiors,
    pipeline: state => state.pipeline,
    stages: state => state.stages,
    usertypes: state => state.usertypes,
    listCommercailsUser: state => state.listCommercailsUser,
    listCommercailsUserUpdate: state => state.listCommercailsUserUpdate,
    technicianspipedrive: state => state.technicianspipedrive,
    commerciauxpipedrive: state => state.commerciauxpipedrive,
    getDepotsLoadingTechniciens: state => state.depotsLoadingTechniciens,
    getDepotsLoadingCommercialITE: state => state.depotsLoadingCommercialIte,
    weeks: state => state.weeks,
    // teams
    teams: state => state.teams,
    admins: state => state.admins,
    adminsGlobal: state => state.adminsGlobal,
    commercials: state => state.commercials,
    technicians: state => state.technicians,
    commercialIte: state => state.commercialIte,
    teamsTypes: state => state.teamsTypes,
    membresGe: state => state.membresGe,
    regieUpdate: state => state.regieUpdate,
    adminUpdate: state => state.adminUpdate,
    subStages: state => state.subStages,
    getTeamsLoading: state => state.teamsLoading,
    //depots
    deposits: state => state.deposits,
    departements: state => state.departements,
    teamsfiliale: state => state.teamsfiliale,
    depotsNoTeam: state => state.depotsNoTeam,
    getUsersConnections: state => state.usersConnections,
    getLastUpdatedTime: state => state.lastUpdatedTime,
    getUsersConnectionsError: state => state.usersConnectionsError,
    //Source
    getSourceError: state => state.sourceError,
    getSourceLoading: state => state.sourceLoading,
    getSources: state => state.sources,
    //Webhook
    getWebhookError: state => state.webhookError,
    getWebhookLoading: state => state.webhookLoading,
    getWebhooks: state => state.webhook,
    // corbeille
    getUsersCorbeille: state => state.corbeilleUsers,
    usersCorbeilleCount: state => state.usersCorbeilleCount,
    //global var project
    getCurentProjectType: state => state.curentProjectType,
    getCurentProjectTypeImmo: state =>
      state.curentProjectType &&
      state.curentProjectType.theme &&
      state.curentProjectType.theme == 'immobilier',
    getEventStatus: state => state.eventStatus,
    getLoaderThemeForProject: state => state.loaderThemeForProject,
    getIconFormate: () => (data, index, formate = false) => {
      let iconData = data.split(' ')[index]
      return formate ? iconData.replace(/-/g, ' ') : iconData
    },
    allUsers: state => state.allUsers
  },
  mutations: {
    setUser(state, payload) {
      state.currentUser = payload
      state.processing = false
      state.loginError = null
    },
    setLogout(state) {
      state.currentUser = null
      //state.processing = false
      //state.loginError = null
    },
    setProcessing(state, payload) {
      state.processing = payload
      state.loginError = null
    },
    setError(state, payload) {
      state.loginError = payload
      state.currentUser = null
      state.processing = false
    },
    clearError(state) {
      state.loginError = null
    },
    setLastNoteIndex(state, payload) {
      state.lastNoteIndex = payload
    },
    setSelectedSupervisedRegie(state, payload) {
      state.selectedRegie = payload
    },
    setSupervisedRegies(state, payload) {
      state.supervisedRegies = payload
    },
    setUsers(state, payload) {
      state.users = payload.data
      state.usersCount = payload.meta.pagination.total_pages
      // state.usersCount = payload.meta.pagination.total
    },
    setAllUsers(state, payload) {
      state.allUsers = payload.data
    },
    // systeme de corbeille
    SET_CORBEILLE_USERS(state, payload) {
      state.corbeilleUsers = payload.data
      state.usersCorbeilleCount = payload.meta.pagination.total_pages
      // state.usersCorbeilleCount = payload.meta.pagination.total
    },
    REMOVE_DEFINITIVEMENT_USERS(state, payload) {
      state.corbeilleUsers = state.corbeilleUsers.filter(user => {
        return user.id != payload.id
      })
    },
    SET_RESTAURER_USERS(state, payload) {
      state.corbeilleUsers = state.corbeilleUsers.filter(user => {
        return user.id != payload.id
      })
    },
    userLoading(state, payload) {
      state.userLoading = payload
    },
    setUsersPipedrive(state, payload) {
      state.usersPipedriveNoSelectionne = payload
    },
    setOwners(state, payload) {
      state.owners = payload
    },
    setRegies(state, payload) {
      state.regies = payload
    },
    setSuperiors(state, payload) {
      state.superiors = payload
    },
    setListWekks(state, payload) {
      state.weeks = payload
    },
    addUser(state, payload) {
      state.users.push(payload)
    },
    removeUser(state, id) {
      let key = false
      for (let index = 0; index < state.users.length; index++) {
        const user = state.users[index]
        if (user.id == id) {
          key = index
          break
        }
      }
      if (key !== false) {
        state.users.splice(key, 1)
      }
    },
    setactivedesactiveUser(state, payload) {
      state.users = state.users.map(item =>
        item.id == payload.id ? payload : item
      )
    },

    updateUser(state, payload) {
      state.users = state.users.map(item =>
        item.id == payload.id ? payload : item
      )
    },
    setPipeline(state, payload) {
      state.pipeline = payload
    },
    setStages(state, payload) {
      state.stages = payload
    },
    doNothing() { },
    setuserstype(state, payload) {
      state.usertypes = payload
    },
    setlistCommercailsUser(state, payload) {
      state.listCommercailsUser = payload.map(item => {
        item.name = item.first_name + ' ' + item.last_name
        return { ...item }
      })
    },
    setlistCommercailsUserUpdate(state, payload) {
      state.listCommercailsUserUpdate = payload
    },
    setTechnicianspipedrive(state, payload) {
      state.technicianspipedrive = payload
    },
    setCommerciauxPipedrive(state, payload) {
      state.commerciauxpipedrive = payload
    },
    SET_TEAMS_LOADING(state, payload = false) {
      state.teamsLoading = payload
    },
    addTeam(state, payload) {
      state.teams.push(payload)
    },
    removeTeam(state, id) {
      let key = false
      for (let index = 0; index < state.teams.length; index++) {
        const equipe = state.teams[index]
        if (equipe.id == id) {
          key = index
          break
        }
      }
      if (key !== false) {
        state.teams.splice(key, 1)
      }
    },
    updateTeams(state, payload) {
      state.teams = state.teams.map(item =>
        item.id == payload.id ? payload : item
      )
    },
    setTeams(state, payload) {
      state.teams = payload
    },
    setteamsTypes(state, payload) {
      state.teamsTypes = payload
    },
    setProprieteRegie(state, payload) {
      state.property = payload
    },
    setRegieUpdate(state, payload) {
      state.regieUpdate = payload
    },
    setAdminUpdate(state, payload) {
      state.adminUpdate = payload
    },
    setSubStage(state, payload) {
      state.subStages = payload
    },
    setadmins(state, payload) {
      state.admins = payload
    },
    setadminsGlobal(state, payload) {
      state.adminsGlobal = payload
    },
    setcommercials(state, payload) {
      state.commercials = payload
    },
    settechnicians(state, payload) {
      state.technicians = payload
    },
    setMembresGe(state, payload) {
      state.membresGe = payload
    },

    // depots
    addDepots(state, payload) {
      state.deposits.push(payload)
    },
    removeDepot(state, id) {
      let key = false
      for (let index = 0; index < state.deposits.length; index++) {
        const depot = state.deposits[index]
        if (depot.id == id) {
          key = index
          break
        }
      }
      if (key !== false) {
        state.deposits.splice(key, 1)
      }
    },
    updateDepots(state, payload) {
      state.deposits = state.deposits.map(item =>
        item.id == payload.id ? payload : item
      )
    },
    setdepartements(state, payload) {
      state.departements = payload
    },
    setteamsfiliale(state, payload) {
      state.teamsfiliale = payload
    },
    depots_loading_Techniciens(state, payload) {
      state.depotsLoadingTechniciens = payload
    },
    depots_loading_Commercial_ITE(state, payload) {
      state.depotsLoadingCommercialIte = payload
    },
    setDepots(state, payload) {
      state.deposits = payload
    },
    setdepotsNoTeam(state, payload) {
      state.depotsNoTeam = payload
    },
    settechniciansdepots(state, payload) {
      state.technicians = payload
    },
    setcommercialitedepots(state, payload) {
      state.commercialIte = payload
    },
    setDepartementsUpdate(state, payload) {
      state.departements = payload
    },
    SET_USERS_CONNECTIONS(state, payload) {
      state.usersConnections = payload
    },
    UPDATE_USERS_DISCONNECT(state, payload) {
      state.usersConnections = state.usersConnections.map(c => {
        if (c.id == payload) {
          c.connected = false
        }
        return c
      })
    },
    SET_LAST_UPDATED_TIME(state, payload) {
      state.lastUpdatedTime = payload
    },
    SET_USERS_DISCONNECT_ERROR(state, payload = false) {
      state.usersConnectionsError = payload
    },
    // Source
    SET_SOURCE_LOADING(state, payload = false) {
      state.sourceLoading = payload
    },
    SET_SOURCE_ERROR(state, payload = null) {
      state.sourceError = payload
    },
    ADD_SOURCE(state, payload) {
      state.sources.push(Source.create(payload.response))
    },
    UPDATE_SOURCE(state, payload) {
      for (let index = 0; index < state.sources.length; index++) {
        if (state.sources[index].id == payload.source.id) {
          state.sources[index].update(payload.response)
          break
        }
      }
    },
    DELETE_SOURCE(state, payload) {
      state.sources = state.sources.filter(source => {
        return source.id != payload.id
      })
    },
    SET_SOURCES(state, payload) {
      state.sources = payload.map(item => Source.create(item))
    },
    // WEBHOOK
    SET_WEBHOOK_LOADING(state, payload = false) {
      state.webhookLoading = payload
    },
    SET_WEBHOOK_ERROR(state, payload = null) {
      state.webhookError = payload
    },
    ADD_WEBHOOK(state, payload) {
      state.webhook.push(Webhook.create(payload.response))
    },
    DELETE_WEBHOOK(state, payload) {
      state.webhook = state.webhook.filter(w => {
        return w.id != payload.id
      })
    },
    SET_WEBHOOK(state, payload) {
      state.webhook = payload.map(item => Webhook.create(item))
    },
    //CURRENT TYPE
    SET_CURRENT_PROJECT_TYPE(state, payload) {
      state.curentProjectType = payload
      localStorage.setItem('typeProject', payload.id)
    },
    SET_LOADER_THEME_FOR_PROJECT(state, payload) {
      state.loaderThemeForProject = payload
    }
  },
  actions: {
    async login({ commit }, payload) {
      commit('clearError')
      commit('setProcessing', true)

      const params = new URLSearchParams()
      params.append('username', payload.email)
      params.append('password', payload.password)
      // params.append('is_desktop', 1)

      try {
        const getTokenResponse = await Axios.post(
          domain + '/api/getToken',
          params,
          {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
          }
        )

        const whoAmIResponse = await Axios.get(domain + '/api/whoami', {
          headers: {
            Authorization: `Bearer ${getTokenResponse.data.access_token}`
          }
        })

        const whoAmIDetailsResponse = await Axios.get(
          domain + '/api/whoami-details',
          {
            headers: {
              Authorization: `Bearer ${getTokenResponse.data.access_token}`
            }
          }
        )

        const now = new Date()
        const expirationDate = new Date(
          now.getTime() + getTokenResponse.data.expiresIn * 1000
        )

        localStorage.setItem('expirationDate', expirationDate)
        const userDetails = whoAmIDetailsResponse.data.data

        const item = {
          token: getTokenResponse.data.access_token,
          regieName: userDetails.regie ? userDetails.regie.data.label : null,
          isSuper: userDetails.is_super,
          details: userDetails,
          ...whoAmIResponse.data
        }

        localStorage.setItem('reporting-regie-user', JSON.stringify(item))

        commit('setUser', item)
        pusher.getnotificationPusher()
        setTimeout(() => {
          if (this.state.currentUser) {
            if (
              this.state.currentUser &&
              this.state.currentUser.code_client != null &&
              this.state.currentUser.details &&
              this.state.currentUser.details.team &&
              this.state.currentUser.details.team.data &&
              this.state.currentUser.details.team.data.type === 'regie'
            ) {
              Router.replace('/setting').catch(() => { })
            } else {
              Router.replace('/').catch(() => { })
            }
          }
        })
      } catch (error) {
        if (error.response && error.response.data) {
          commit('setError', error.response.data.error)
        } else if (error.message) {
          commit('setError', error.message)
        } else {
          commit('setError', 'unknown error')
        }
      }
    },
    async logout({ commit }) {
      try {
        await Axios.get(domain + '/api/user-clean-filter', {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        })
      } finally {
        localStorage.removeItem('reporting-regie-user')
        this.reset()
        pusher.deconnecte()
        if (this.state.currentUser !== null) {
          commit('setLogout')
        }
        Router.replace('/login').catch(() => { })
      }
    },
    setNoteIndex({ commit }, payload) {
      commit('setLastNoteIndex', payload)
    },
    hideLastNote({ commit }) {
      commit
    },
    setSupervisedRegie({ commit }, payload) {
      commit('setSelectedSupervisedRegie', payload)
    },
    async getSupervisedRegies({ commit }) {
      try {
        const response = await Axios.get(domain + '/api/regies-supervised', {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        })
        commit('setSupervisedRegies', response.data)
        return {
          success: true
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.error &&
          error.response.data.error.messages
        ) {
          return {
            success: false,
            error: error.response.data.error.messages
          }
        }
        return {
          success: false,
          error: 'Une erreur est survenue'
        }
      }
    },
    async getUsers({ commit }, payload) {
      commit('userLoading', true)
      try {
        const params = {
          page: payload.page,
          per_page: payload.per_page,
          active: payload.active,
          sort_by: payload.sort_by
        }

        if (payload.exclude) {
          params.exclude = payload.exclude.join()
        }

        if (payload.full_name) {
          params.full_name = payload.full_name
        }
        if (payload.sort_by_desc) {
          params.sort_by_desc = payload.sort_by_desc
        }
        if (request) {
          request.cancel('Optional message')
        }
        request = Axios.CancelToken.source()
        const response = await Axios.get(domain + '/api/users', {
          cancelToken: request.token,
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          },
          params: params
        })
        commit('setUsers', response.data)
        commit('userLoading', false)
        return {
          success: true
        }
      } catch (error) {
        commit('userLoading', false)
        if (
          error.response &&
          error.response.data &&
          error.response.data.error &&
          error.response.data.error.messages
        ) {
          return {
            success: false,
            error: error.response.data.error.messages
          }
        }
        return {
          success: false,
          error: 'Une erreur est survenue'
        }
      }
    },
    async getAllUsers({ commit }, payload) {
      commit('userLoading', true)
      try {
        const response = await Axios.get(domain + '/api/users', {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          },
          params: payload
        })
        commit('setAllUsers', response.data)
        commit('userLoading', false)
        return {
          success: true
        }
      } catch (error) {
        commit('userLoading', false)
        if (
          error.response &&
          error.response.data &&
          error.response.data.error &&
          error.response.data.error.messages
        ) {
          return {
            success: false,
            error: error.response.data.error.messages
          }
        }
        return {
          success: false,
          error: 'Une erreur est survenue'
        }
      }
    },
    async getRegies({ commit }) {
      try {
        const response = await Axios.get(domain + '/api/available-sources', {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        })
        commit('setRegies', response.data.data)
        return {
          success: true
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.error &&
          error.response.data.error.messages
        ) {
          return {
            success: false,
            error: error.response.data.error.messages
          }
        }
        return {
          success: false,
          error: 'Une erreur est survenue'
        }
      }
    },
    async getUsersPipedrive({ commit }) {
      try {
        const response = await Axios.get(domain + '/api/users-pipe', {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        })
        commit('setUsersPipedrive', response.data)
        return {
          success: true
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.error &&
          error.response.data.error.messages
        ) {
          return {
            success: false,
            error: error.response.data.error.messages
          }
        }
        return {
          success: false,
          error: 'Une erreur est survenue'
        }
      }
    },
    async getOwners({ commit }, payload) {
      const params = {}
      if (payload && payload.exclude) {
        params.exclude = payload.exclude.join()
      }
      try {
        const response = await Axios.get(domain + '/api/owners-regie', {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          },
          params: params
        })
        commit('setOwners', response.data.data)
        return {
          success: true
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.error &&
          error.response.data.error.messages
        ) {
          return {
            success: false,
            error: error.response.data.error.messages
          }
        }
        return {
          success: false,
          error: 'Une erreur est survenue'
        }
      }
    },
    async addUser({ commit }, payload) {
      const body = {
        first_name: payload.first_name,
        last_name: payload.last_name,
        api_pipe_token: payload.api_pipe_token ? payload.api_pipe_token : null,
        email: payload.email,
        password: payload.password,
        password_confirmation: payload.password_confirmation,
        zipcode: payload.zipcode,
        type: payload.type
      }
      if (
        this.state.currentUser &&
        this.state.currentUser.code_client != null &&
        this.state.currentUser.details &&
        this.state.currentUser.details.team &&
        this.state.currentUser.details.team.data &&
        this.state.currentUser.details.team.data.type === 'regie' &&
        this.state.currentUser.type == 'user.commercial'
      ) {
        body.code_client = payload.code_client
        body.societe = payload.societe
        body.nombre_appareil = payload.nombre_appareil
        body.periode_test = payload.periode_test
      }
      if (payload.phone && payload.phone.length) {
        body.phone = payload.phone
      }
      if (
        payload.type == 'user.admin' ||
        payload.type == 'user.sub-super-admin'
      ) {
        body.has_export_excel = 1
      } else {
        body.has_export_excel = payload.has_export_excel ? 1 : 0
      }
      if (payload.has_import_excel) {
        body.has_import_excel = 1
      } else {
        body.has_import_excel = 0
      }
      if (
        payload.type == 'user.admin' ||
        payload.type == 'user.agent' ||
        payload.type == 'user.resp.plan' ||
        payload.type == 'user.administration'
      ) {
        if (payload.pipe_user_id !== null) {
          body.pipe_user_id = payload.pipe_user_id
        }
      }

      if (payload.type == 'user.technicien') {
        body.technician_id_pipe = payload.technician_id_pipe
        body.co_technician_id = payload.co_technician_id
      }
      if (payload.type == 'user.commercial.ite') {
        body.commercial_ite_id_pipe = payload.commercial_ite_id_pipe
      }
      if (payload.type == 'user.final') {
        body.superior_id = payload.superior_id
        if (payload.owner_id) {
          body.owner_id = payload.owner_id
        }
      }
      if (payload.type == 'user.commercial') {
        if (payload.owner_id) {
          body.owner_id = payload.owner_id
        }
        if (payload.coeff_101) {
          body.coeff_101 = payload.coeff_101
        }
        if (payload.coeff_102) {
          body.coeff_102 = payload.coeff_102
        }
        if (payload.coeff_103) {
          body.coeff_103 = payload.coeff_103
        }
      }

      try {
        const response = await Axios.post(domain + '/api/users', body, {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        })
        commit('addUser', response.data.data)
        return {
          success: true
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.error &&
          error.response.data.error.messages
        ) {
          return {
            success: false,
            error: error.response.data.error.messages
          }
        }
        return {
          success: false,
          error: 'Une erreur est survenue'
        }
      }
    },

    async getAdminsTeams({ commit }) {
      try {
        const response = await Axios.get(
          domain + '/api/possible-admins-regie',
          {
            headers: {
              Authorization: `Bearer ${this.state.currentUser.token}`
            }
          }
        )
        const admins = response.data.map(item => {
          return {
            ...item,
            name: item.first_name + ' ' + item.last_name
          }
        })
        commit('setadmins', admins)
        return {
          success: true
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.error &&
          error.response.data.error.messages
        ) {
          return {
            success: false,
            error: error.response.data.error.messages
          }
        }
        return {
          success: false,
          error: 'Une erreur est survenue'
        }
      }
    },
    async getAdminsTeamsGlobal({ commit }, payload) {
      const params = {}
      if (payload && payload.exclude) {
        params.exclude = payload.exclude.join()
      }
      try {
        const response = await Axios.get(domain + '/api/admins', {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          },
          params: params
        })
        const admins = response.data.data.map(item => {
          return {
            ...item,
            name: item.first_name + ' ' + item.last_name
          }
        })
        commit('setadminsGlobal', admins)
        return {
          success: true
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.error &&
          error.response.data.error.messages
        ) {
          return {
            success: false,
            error: error.response.data.error.messages
          }
        }
        return {
          success: false,
          error: 'Une erreur est survenue'
        }
      }
    },
    async getcommercials({ commit }) {
      try {
        const response = await Axios.get(domain + '/api/commercials', {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        })
        commit('setcommercials', response.data.data)
        return {
          success: true
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.error &&
          error.response.data.error.messages
        ) {
          return {
            success: false,
            error: error.response.data.error.messages
          }
        }
        return {
          success: false,
          error: 'Une erreur est survenue'
        }
      }
    },
    async getMembresPossibleUpdate({ commit }, payload) {
      let totalPages = 1
      let users = []
      const params = {
        page: 1,
        per_page: payload.per_page || 20
      }

      if (payload && payload.exclude) {
        params.exclude = payload.exclude.join()
      }
      let meta = true
      while (params.page <= totalPages && meta) {
        const response = await Axios.post(
          domain + '/api/membres-possible-update',
          { team_id: payload.id },
          {
            headers: {
              Authorization: `Bearer ${this.state.currentUser.token}`
            },
            params: params
          }
        )
        if (response.data) {
          users = users.concat(response.data.data)
          if (response.data.meta) {
            totalPages = response.data.meta.pagination.total_pages
            params.page++
          } else {
            meta = false
          }
        }
      }
      commit('doNothing')
      return users
    },

    async gettechnicians({ commit }) {
      try {
        const response = await Axios.get(
          domain + '/api/membres-possible-filiale',
          {
            headers: {
              Authorization: `Bearer ${this.state.currentUser.token}`
            }
          }
        )
        commit('settechnicians', response.data.data)
        return {
          success: true
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.error &&
          error.response.data.error.messages
        ) {
          return {
            success: false,
            error: error.response.data.error.messages
          }
        }
        return {
          success: false,
          error: 'Une erreur est survenue'
        }
      }
    },
    async getUsersType({ commit }) {
      try {
        const response = await Axios.get(domain + '/api/users-types', {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        })
        if (response.data) {
          commit('setuserstype', response.data)
        } else {
          commit('setuserstype', [])
        }
      } catch (error) {
        commit('setuserstype', [])
      }
    },

    async getlistCommercailsUser({ commit }) {
      try {
        const response = await Axios.get(domain + '/api/all-commercials', {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        })
        commit('setlistCommercailsUser', response.data)
        return {
          success: true
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.error &&
          error.response.data.error.messages
        ) {
          return {
            success: false,
            error: error.response.data.error.messages
          }
        }
        return {
          success: false,
          error: 'Une erreur est survenue'
        }
      }
    },
    async getTechniciansPipeUpdate({ commit }) {
      try {
        await Axios.get(domain + '/api/update-techs-pipe', {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        })
        commit('doNothing')
        return {
          success: true
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.error &&
          error.response.data.error.messages
        ) {
          return {
            success: false,
            error: error.response.data.error.messages
          }
        }
        return {
          success: false,
          error: 'Une erreur est survenue'
        }
      }
    },
    async getCommerciauxPipeUpdate({ commit }) {
      try {
        await Axios.get(domain + '/api/update-coms-ite-pipe', {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        })
        commit('doNothing')
        return {
          success: true
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.error &&
          error.response.data.error.messages
        ) {
          return {
            success: false,
            error: error.response.data.error.messages
          }
        }
        return {
          success: false,
          error: 'Une erreur est survenue'
        }
      }
    },
    async getlistCommercailsUserUpdate({ commit }, id) {
      try {
        const response = await Axios.get(
          domain + '/api/all-commercials?update=1&user_id=' + id,
          {
            headers: {
              Authorization: `Bearer ${this.state.currentUser.token}`
            }
          }
        )
        commit('setlistCommercailsUserUpdate', response.data)
        return {
          success: true
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.error &&
          error.response.data.error.messages
        ) {
          return {
            success: false,
            error: error.response.data.error.messages
          }
        }
        return {
          success: false,
          error: 'Une erreur est survenue'
        }
      }
    },

    async postlistTechniciensFilialeDepots({ commit }, payload) {
      if (!payload) {
        commit('settechniciansdepots', [])
        return
      }
      commit('depots_loading_Techniciens', true)
      const params = {}
      if (payload.exclude) {
        params.exclude = payload.exclude.join()
      }
      try {
        const response = await Axios.post(
          domain + '/api/technicians-filial',
          payload,
          {
            headers: {
              Authorization: `Bearer ${this.state.currentUser.token}`
            },
            params: params
          }
        )
        commit('settechniciansdepots', response.data.data)
        commit('depots_loading_Techniciens', false)
        return {
          success: true
        }
      } catch (error) {
        commit('depots_loading_Techniciens', false)
        if (
          error.response &&
          error.response.data &&
          error.response.data.error &&
          error.response.data.error.messages
        ) {
          return {
            success: false,
            error: error.response.data.error.messages
          }
        }
        return {
          success: false,
          error: 'Une erreur est survenue'
        }
      }
    },
    async postlistCommercialsFilialeDepots({ commit }, payload) {
      if (!payload) {
        commit('setcommercialitedepots', [])
        return
      }
      const params = {}
      if (payload.exclude) {
        params.exclude = payload.exclude.join()
      }
      commit('depots_loading_Commercial_ITE', true)
      try {
        const response = await Axios.post(
          domain + '/api/commercials-ite-filial',
          payload,
          {
            headers: {
              Authorization: `Bearer ${this.state.currentUser.token}`
            },
            params: params
          }
        )
        commit('setcommercialitedepots', response.data.data)
        commit('depots_loading_Commercial_ITE', false)
        return {
          success: true
        }
      } catch (error) {
        commit('depots_loading_Commercial_ITE', false)
        if (
          error.response &&
          error.response.data &&
          error.response.data.error &&
          error.response.data.error.messages
        ) {
          return {
            success: false,
            error: error.response.data.error.messages
          }
        }
        return {
          success: false,
          error: 'Une erreur est survenue'
        }
      }
    },
    async postDepartmentsDepotsUpdate({ commit }, id) {
      try {
        const response = await Axios.post(
          domain + '/api/departments-depots-update',
          { depot_id: id },
          {
            headers: {
              Authorization: `Bearer ${this.state.currentUser.token}`
            }
          }
        )
        commit('doNothing')
        return response.data
      } catch (error) {
        return []
      }
    },

    async getdepartementsDepots({ commit }) {
      try {
        const response = await Axios.get(domain + '/api/departments-depots', {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        })
        commit('setdepartements', response.data)
        return {
          success: true
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.error &&
          error.response.data.error.messages
        ) {
          return {
            success: false,
            error: error.response.data.error.messages
          }
        }
        return {
          success: false,
          error: 'Une erreur est survenue'
        }
      }
    },

    async getteamsfiliale({ commit }, payload) {
      const params = {}
      if (payload && payload.exclude) {
        params.exclude = payload.exclude.join()
      }
      try {
        const response = await Axios.get(domain + '/api/teams-filiale', {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          },
          params: params
        })
        commit('setteamsfiliale', response.data.data)
        return {
          success: true
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.error &&
          error.response.data.error.messages
        ) {
          return {
            success: false,
            error: error.response.data.error.messages
          }
        }
        return {
          success: false,
          error: 'Une erreur est survenue'
        }
      }
    },

    async getDepotsNoTeam({ commit }) {
      try {
        const response = await Axios.get(domain + '/api/depots-no-team', {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        })
        commit('setdepotsNoTeam', response.data.data)
        return {
          success: true
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.error &&
          error.response.data.error.messages
        ) {
          return {
            success: false,
            error: error.response.data.error.messages
          }
        }
        return {
          success: false,
          error: 'Une erreur est survenue'
        }
      }
    },

    async getteamsTypes({ commit }) {
      try {
        const response = await Axios.get(domain + '/api/teams-types', {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        })

        const teamsTypes = []
        for (const [key, item] of Object.entries(response.data)) {
          teamsTypes.push({
            id: key,
            name: item.text
          })
        }

        commit('setteamsTypes', teamsTypes)
        return {
          success: true
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.error &&
          error.response.data.error.messages
        ) {
          return {
            success: false,
            error: error.response.data.error.messages
          }
        }
        return {
          success: false,
          error: 'Une erreur est survenue'
        }
      }
    },
    async createTeam({ commit }, payload) {
      try {
        const response = await Axios.post(domain + '/api/teams', payload, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        })
        commit('addTeam', response.data.data)
        return {
          success: true
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.error &&
          error.response.data.error.messages
        ) {
          return {
            success: false,
            error: error.response.data.error.messages
          }
        }
        return {
          success: false,
          error: 'Une erreur est survenue'
        }
      }
    },
    async getTeams({ commit }, payload) {
      commit('SET_TEAMS_LOADING', true)
      const params = {}
      if (payload && payload.exclude) {
        params.exclude = payload.exclude.join()
      }
      if (payload.name) {
        params.name = payload.name
      }
      try {
        if (requestTeams) {
          requestTeams.cancel()
        }
        requestTeams = Axios.CancelToken.source()
        const response = await Axios.get(domain + '/api/teams', {
          cancelToken: requestTeams.token,
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          },
          params: params
        })
        commit('setTeams', response.data.data)
        commit('SET_TEAMS_LOADING')
        return {
          success: true
        }
      } catch (error) {
        commit('SET_TEAMS_LOADING')
        if (
          error.response &&
          error.response.data &&
          error.response.data.error &&
          error.response.data.error.messages
        ) {
          return {
            success: false,
            error: error.response.data.error.messages
          }
        }
        return {
          success: false,
          error: ['Une erreur est survenue']
        }
      }
    },
    async getMembresGe({ commit }) {
      try {
        const response = await Axios.get(domain + '/api/membres-possible-ge', {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        })
        commit('setMembresGe', response.data.data)
        return {
          success: true
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.error &&
          error.response.data.error.messages
        ) {
          return {
            success: false,
            error: error.response.data.error.messages
          }
        }
        return {
          success: false,
          error: ['Une erreur est survenue']
        }
      }
    },
    async fetchAdminRegie({ commit }, payload) {
      try {
        const response = await Axios.get(
          domain + '/api/possible-admins-regie-update/' + payload,
          {
            headers: {
              Authorization: `Bearer ${this.state.currentUser.token}`
            }
          }
        )
        const admins = response.data.map(item => {
          return {
            ...item,
            name: item.first_name + ' ' + item.last_name
          }
        })
        commit('setAdminUpdate', admins)
        return {
          success: true
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.error &&
          error.response.data.error.messages
        ) {
          return {
            success: false,
            error: error.response.data.error.messages
          }
        }
        return {
          success: false,
          error: 'Une erreur est survenue'
        }
      }
    },
    async fetchRegieUpdate({ commit }, payload) {
      commit('SET_TEAMS_LOADING', true)
      try {
        const response = await Axios.get(
          domain + '/api/available-sources/' + payload + '/update',
          {
            headers: {
              Authorization: `Bearer ${this.state.currentUser.token}`
            }
          }
        )
        commit('setRegieUpdate', response.data.data)
        commit('SET_TEAMS_LOADING')
        return {
          success: true
        }
      } catch (error) {
        commit('SET_TEAMS_LOADING')
        if (
          error.response &&
          error.response.data &&
          error.response.data.error &&
          error.response.data.error.messages
        ) {
          return {
            success: false,
            error: error.response.data.error.messages
          }
        }
        return {
          success: false,
          error: ['Une erreur est survenue']
        }
      }
    },
    async getTechnicianspipedrive({ commit }) {
      try {
        const response = await Axios.get(domain + '/api/techs-pipe', {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        })
        commit('setTechnicianspipedrive', response.data)
        return {
          success: true
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.error &&
          error.response.data.error.messages
        ) {
          return {
            success: false,
            error: error.response.data.error.messages
          }
        }
        return {
          success: false,
          error: ['Une erreur est survenue']
        }
      }
    },
    async getCommerciauxPipedrive({ commit }) {
      try {
        const response = await Axios.get(domain + '/api/coms-ite-pipe', {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        })
        commit('setCommerciauxPipedrive', response.data)
        return {
          success: true
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.error &&
          error.response.data.error.messages
        ) {
          return {
            success: false,
            error: error.response.data.error.messages
          }
        }
        return {
          success: false,
          error: ['Une erreur est survenue']
        }
      }
    },
    async deleteTeams({ commit }, id) {
      try {
        await Axios.delete(domain + '/api/teams/' + id, {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        })
        commit('removeTeam', id)
        return {
          success: true
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.error &&
          error.response.data.error.messages
        ) {
          return {
            success: false,
            error: error.response.data.error.messages
          }
        }
        return {
          success: false,
          error: ['Une erreur est survenue']
        }
      }
    },
    async deleteDepot({ commit }, id) {
      try {
        await Axios.delete(domain + '/api/depots/' + id, {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        })
        commit('removeDepot', id)
        return {
          success: true
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.error &&
          error.response.data.error.messages
        ) {
          return {
            success: false,
            error: error.response.data.error.messages
          }
        }
        return {
          success: false,
          error: ['Une erreur est survenue']
        }
      }
    },
    async deleteUser({ commit }, id) {
      try {
        await Axios.delete(domain + '/api/users/' + id, {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        })
        commit('removeUser', id)
        return {
          success: true
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.error &&
          error.response.data.error.messages
        ) {
          return {
            success: false,
            error: error.response.data.error.messages
          }
        }
        return {
          success: false,
          error: ['Une erreur est survenue']
        }
      }
    },
    async getlistWeeks({ commit }, payload) {
      try {
        const response = await Axios.post(domain + '/api/weeks', payload, {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        })
        commit('setListWekks', response.data.data)
        return {
          success: true
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.error &&
          error.response.data.error.messages
        ) {
          return {
            success: false,
            error: error.response.data.error.messages
          }
        }
        return {
          success: false,
          error: ['Une erreur est survenue']
        }
      }
    },
    async activerDesactiverUser({ commit }, payload) {
      const body = {}
      if (payload.type == 'user.technicien') {
        if (payload.start_week_id != null && payload.end_week_id != null) {
          body.start_week_id = payload.start_week_id
          body.end_week_id = payload.end_week_id
        } else if (
          payload.start_week_id != null &&
          payload.end_week_id == null
        ) {
          body.start_week_id = payload.start_week_id
        } else {
          body.start_week_id = null
          body.end_week_id = null
        }
      }

      try {
        const response = await Axios.post(
          domain + '/api/users/' + payload.id + '/enable-disable',
          body,
          {
            headers: {
              Authorization: `Bearer ${this.state.currentUser.token}`
            }
          }
        )
        commit('setactivedesactiveUser', response.data.data)
        return {
          success: true
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.error &&
          error.response.data.error.messages
        ) {
          return {
            success: false,
            error: error.response.data.error.messages
          }
        }
        return {
          success: false,
          error: ['Une erreur est survenue']
        }
      }
    },
    async updateTeams({ commit }, payload) {
      const formData = new FormData()
      formData.append('type', payload.type)
      formData.append('name', payload.name)
      let empty = true
      payload.selected.forEach(element => {
        if (element) {
          empty = false
        }
      })
      if (payload.selected && !empty) {
        let index = 0
        payload.selected.forEach((element, key) => {
          if (element) {
            formData.append('members[' + index + ']', key)
          }
          index++
        })
      } else {
        formData.append('members', '')
      }
      formData.append('_method', 'PUT')
      if (payload.type == 'filiale' || payload.type == 'ge') {
        formData.append('manager_id', payload.selectManager.id)
        if (payload.imageDataUpdate) {
          formData.append('image', payload.imageDataUpdate)
        }
      }
      if (payload.type == 'regie') {
        formData.append('manager_id', payload.selectManagerRegie.id)
        formData.append('source_id', payload.source_id.id)
        formData.append('see_form_fast', payload.see_form_fast ? 1 : 0)
        formData.append('set_lead', payload.set_lead ? 1 : 0)
        // formData.append('owner_id', payload.pipe_user_id)
        formData.append('coeff_101', payload.coeff_101)
        formData.append('coeff_102', payload.coeff_102)
        formData.append('coeff_103', payload.coeff_103)

        // Team projectTypes visibility
        if (payload.projectTypes && payload.projectTypes.length) {
          const checkedProjectTypes = payload.projectTypes.filter(item => {
            if (item.dtype_id) {
              return true
            }
            return false
          })

          let index = 0
          for (let checkedProjectType of checkedProjectTypes) {
            formData.append(
              'dtypes[' + index + '][dtype_id]',
              checkedProjectType.dtype_id
            )
            if (
              checkedProjectType.cstage_id &&
              checkedProjectType.cstage_id.id
            ) {
              formData.append(
                'dtypes[' + index + '][cstage_id]',
                checkedProjectType.cstage_id.id
              )
            } else {
              formData.append('dtypes[' + index + '][cstage_id]', '')
            }
            if (
              checkedProjectType.csubstage_id &&
              checkedProjectType.csubstage_id.id
            ) {
              formData.append(
                'dtypes[' + index + '][csubstage_id]',
                checkedProjectType.csubstage_id.id
              )
            } else {
              formData.append('dtypes[' + index + '][csubstage_id]', '')
            }
            index++
          }
        }
      }

      try {
        const response = await Axios.post(
          domain + '/api/teams/' + payload.id,
          formData,
          {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
              Authorization: `Bearer ${this.state.currentUser.token}`
            }
          }
        )

        commit('updateTeams', response.data.data)
        return {
          success: true
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.error &&
          error.response.data.error.messages
        ) {
          return {
            success: false,
            error: error.response.data.error.messages
          }
        }
        return {
          success: false,
          error: ['Une erreur est survenue']
        }
      }
    },

    async updateDepots({ commit }, payload) {
      const body = {
        name: payload.name,
        zipCode: payload.zipCode,
        address: payload.address,
        depot_nbr: payload.depot_nbr,
        departments: payload.selectedDepartements.map(d => d.id),
        team_id: payload.team_id,
        technicians: payload.selectedTechnicians,
        commercials_ite: payload.selectedCommercialIte
      }

      try {
        const response = await Axios.put(
          domain + '/api/depots/' + payload.id,
          body,
          {
            headers: {
              Authorization: `Bearer ${this.state.currentUser.token}`
            }
          }
        )
        commit('updateDepots', response.data.data)
        return {
          success: true
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.error &&
          error.response.data.error.messages
        ) {
          return {
            success: false,
            error: error.response.data.error.messages
          }
        }
        return {
          success: false,
          error: ['Une erreur est survenue']
        }
      }
    },
    async addDepots({ commit }, payload) {
      const body = {
        name: payload.name,
        zipCode: payload.zipCode,
        address: payload.address,
        departments: payload.departments,
        team_id: payload.team_id,
        technicians: payload.technicians,
        commercials_ite: payload.commercialIte
      }
      if (payload.depot_nbr) {
        body.depot_nbr = payload.depot_nbr
      }
      try {
        const response = await Axios.post(domain + '/api/depots', body, {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        })
        commit('addDepots', response.data.data)
        return {
          success: true
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.error &&
          error.response.data.error.messages
        ) {
          return {
            success: false,
            error: error.response.data.error.messages
          }
        }
        return {
          success: false,
          error: ['Une erreur est survenue']
        }
      }
    },
    async getDepots({ commit }, payload) {
      const params = {}
      if (payload && payload.exclude) {
        params.exclude = payload.exclude.join()
      }
      try {
        const response = await Axios.get(domain + '/api/depots', {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          },
          params: params
        })
        commit('setDepots', response.data.data)
        return {
          success: true
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.error &&
          error.response.data.error.messages
        ) {
          return {
            success: false,
            error: error.response.data.error.messages
          }
        }
        return {
          success: false,
          error: ['Une erreur est survenue']
        }
      }
    },
    async addLead({ commit }, payload) {
      const body = {
        nom: payload.Nom,
        prenom: payload.Prenom,
        nom_prenom: payload.Nom_Prenom,
        email: payload.Email,
        phone: payload.Phone,
        address: payload.Address,
        zipCode: payload.ZipCode,
        ville: payload.Ville,
        chauffage: payload.selected_chauffage,
        logement: payload.selected_logement,
        num_fisc1: payload.Num_fisc1,
        ref_avis1: payload.Ref_avis1,
        num_fisc2: payload.Num_fisc2,
        ref_avis2: payload.Ref_avis2,
        access_comble: payload.selected_access_comble,
        plancher_comble: payload.selected_plancher_comble,
        surface_souffle: payload.surface_souffle,
        surface_deroule: payload.surface_deroule,
        surface_rampants: payload.surface_rampant,
        type_mur: payload.selected_type_mur,
        surface_mur: payload.surface_mur,
        type_plafond: payload.selected_type_plafond,
        surface_plafond: payload.surface_plafond,
        surface_pignon: payload.surface_pignon,
        surface_vide_sanitaire: payload.surface_vide_sanitaire,
        pose_date_time: payload.pose_date_time
      }
      commit('doNothing')

      try {
        const response = await Axios.post(domain + '/api/complete-lead', body, {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        })
        if (response.status == 200) {
          return {
            success: true
          }
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.error &&
          error.response.data.error.messages
        ) {
          return {
            success: false,
            error: error.response.data.error.messages
          }
        }
        return {
          success: false,
          error: ['Une erreur est survenue']
        }
      }
    },
    async fetchProprieteRegie({ commit }, payload) {
      const params = {}
      if (payload && payload.exclude) {
        params.exclude = payload.exclude.join()
      }
      try {
        const response = await Axios.get(domain + '/api/owners-regie', {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          },
          params: params
        })
        commit('setProprieteRegie', response.data.data)
        return {
          success: true
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.error &&
          error.response.data.error.messages
        ) {
          return {
            success: false,
            error: error.response.data.error.messages
          }
        }
        return {
          success: false,
          error: ['Une erreur est survenue']
        }
      }
    },

    async updateUser({ commit }, payload) {
      const body = {
        first_name: payload.first_name,
        last_name: payload.last_name,
        api_pipe_token: payload.api_pipe_token ? payload.api_pipe_token : null,
        email: payload.email,
        zipcode: payload.zipcode,
        phone: payload.phone
      }
      if (
        this.state.currentUser &&
        this.state.currentUser.type != 'user.admin' &&
        this.state.currentUser.type != 'user.commercial' &&
        this.state.currentUser.type != 'user.final'
      ) {
        body.type = payload.type
      }
      if (
        this.state.currentUser &&
        this.state.currentUser.code_client != null &&
        this.state.currentUser.details &&
        this.state.currentUser.details.team &&
        this.state.currentUser.details.team.data &&
        this.state.currentUser.details.team.data.type === 'regie' &&
        this.state.currentUser.type == 'user.commercial'
      ) {
        body.code_client = payload.code_client
        body.societe = payload.societe
        body.nombre_appareil = payload.nombre_appareil
        body.periode_test = payload.periode_test
      }
      if (
        payload.type == 'user.admin' ||
        payload.type == 'user.sub-super-admin'
      ) {
        body.has_export_excel = 1
      } else {
        body.has_export_excel = payload.has_export_excel ? 1 : 0
      }
      if (payload.has_import_excel) {
        body.has_import_excel = 1
      } else {
        body.has_import_excel = 0
      }
      if (payload.password) {
        body.password = payload.password
        body.password_confirmation = payload.password_confirmation
      }
      if (payload.type == 'user.final') {
        body.owner_id = payload.owner_id ? payload.owner_id : null
        body.superior_id = payload.superior_id
      }
      if (payload.type == 'user.commercial') {
        body.owner_id = payload.owner_id ? payload.owner_id : null
        if (payload.coeff_101) {
          body.coeff_101 = payload.coeff_101
        }
        if (payload.coeff_102) {
          body.coeff_102 = payload.coeff_102
        }
        if (payload.coeff_103) {
          body.coeff_103 = payload.coeff_103
        }
      }
      if (payload.type == 'user.supervisor') {
        body.supervised_regies = payload.supervised_regies
        body.superior_id = this.state.currentUser.id
      }
      if (payload.type == 'user.technicien') {
        body.technician_id_pipe = payload.technician_id_pipe
        body.co_technician_id = payload.co_technician_id
      }
      if (payload.type == 'user.commercial.ite') {
        body.commercial_ite_id_pipe = payload.commercial_ite_id_pipe
      }
      if (
        payload.type == 'user.admin' ||
        payload.type == 'user.agent' ||
        payload.type == 'user.resp.plan' ||
        payload.type == 'user.administration'
      ) {
        body.pipe_user_id = payload.pipe_user_id
      }
      try {
        const response = await Axios.put(
          domain + '/api/users/' + payload.id,
          body,
          {
            headers: {
              Authorization: `Bearer ${this.state.currentUser.token}`
            }
          }
        )
        commit('updateUser', response.data.data)
        return {
          success: true
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.error &&
          error.response.data.error.messages
        ) {
          return {
            success: false,
            error: error.response.data.error.messages
          }
        }
        return {
          success: false,
          error: ['Une erreur est survenue']
        }
      }
    },
    async userById({ dispatch }, id) {
      if (!this.state.users.length) {
        await dispatch('getUsers')
      }
      const users = this.state.users.filter(user => user.id == id)
      if (users.length) {
        return users[0]
      }
      return null
    },
    async getPipeline({ commit }) {
      try {
        const response = await Axios.get(domain + '/api/crm/cpipeline', {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        })
        commit('setPipeline', response.data.data)
      } catch (error) {
        commit('setPipeline', [])
      }
    },
    async getStages({ commit }, pipelineId) {
      commit('setStages', [])
      try {
        const response = await Axios.get(
          domain + '/api/dtypes/' + pipelineId + '/cstages?exclude=csubstages',
          {
            headers: {
              Authorization: `Bearer ${this.state.currentUser.token}`
            }
          }
        )
        commit('setStages', response.data.data)
      } catch (error) {
        commit('setStages', [])
      }
    },
    async getSubStages({ commit }, stageId) {
      commit('setSubStage', [])
      try {
        const response = await Axios.get(
          domain + '/api/crm/cstage/' + stageId + '/csubstage',
          {
            headers: {
              Authorization: `Bearer ${this.state.currentUser.token}`
            }
          }
        )
        commit('setSubStage', response.data.data)
      } catch (error) {
        commit('setSubStage', [])
      }
    },
    async getAvailableVisibilityUsersByDepot({ commit }, payload) {
      const params = {}
      if (payload.exclude) {
        params.exclude = payload.exclude.join()
      }
      const response = await Axios.get(
        domain + '/api/possible-depot-planning/' + payload.id + '/supervisors',
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          },
          params: params
        }
      )
      commit('doNothing')
      return response.data.data.map(user => {
        return { ...user, name: user.first_name + ' ' + user.last_name }
      })
    },
    async getUsersVisibilityByDepot({ commit }, id) {
      const response = await Axios.get(
        domain + '/api/depot/' + id + '/accessible-to-users',
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('doNothing')
      return response.data.map(user => {
        return { ...user, name: user.first_name + ' ' + user.last_name }
      })
    },
    async updateCanSee({ commit }, payload) {
      let route =
        '/api/make-depot-planning/' +
        payload.depot_id +
        '/invisible-to-users/' +
        payload.user_id
      if (payload.can_see === 1) {
        route =
          '/api/make-depot-planning/' +
          payload.depot_id +
          '/visible-to-users/' +
          payload.user_id
      }
      await Axios.get(domain + route, {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        }
      })
      commit('doNothing')
    },

    async updateCanEdit({ commit }, payload) {
      try {
        let route =
          '/api/make-depot-planning/' +
          payload.depot_id +
          '/uneditible-by-users/' +
          payload.user_id

        if (payload.can_edit === 1) {
          route =
            '/api/make-depot-planning/' +
            payload.depot_id +
            '/editible-by-users/' +
            payload.user_id
        }
        await Axios.get(domain + route, {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        })
        commit('doNothing')
        return {
          success: true
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.error &&
          error.response.data.error.messages
        ) {
          return {
            success: false,
            error: error.response.data.error.messages
          }
        }
        return {
          success: false,
          error: ['Une erreur est survenue']
        }
      }
    },
    async addUtilisateur({ commit }, payload) {
      try {
        await Axios.post(
          domain +
          '/api/make-depot-planning/' +
          payload.depot_id +
          '/accessible-to-users',
          { users: [payload.user_id] },
          {
            headers: {
              Authorization: `Bearer ${this.state.currentUser.token}`
            }
          }
        )
        commit('doNothing')
        return {
          success: true
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.error &&
          error.response.data.error.messages
        ) {
          return {
            success: false,
            error: error.response.data.error.messages
          }
        }
        return {
          success: false,
          error: ['Une erreur est survenue']
        }
      }
    },
    async updatePassword({ commit }, payload) {
      const body = {
        password: payload.password,
        password_confirmation: payload.password_confirmation
      }

      try {
        await Axios.put(domain + '/api/users/' + payload.id, body, {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        })
        commit('doNothing')
        return {
          success: true
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.error &&
          error.response.data.error.messages
        ) {
          return {
            success: false,
            error: error.response.data.error.messages
          }
        }
        return {
          success: false,
          error: ['Une erreur est survenue']
        }
      }
    },
    async fetchfillPipelinesTable({ commit }) {
      try {
        await Axios.get(domain + '/api/pipelines-pipe', {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        })
        commit('doNothing')
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.error &&
          error.response.data.error.messages
        ) {
          commit('SET_PLANNING_ERROR', error.response.data.error.messages)
        } else if (error.message) {
          commit('SET_PLANNING_ERROR', [error.message])
        } else {
          commit('SET_PLANNING_ERROR', 'unknown error')
        }
      }
    },
    async fetchfillStagesTable({ commit }) {
      try {
        await Axios.get(domain + '/api/stages-pipe', {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        })
        commit('doNothing')
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.error &&
          error.response.data.error.messages
        ) {
          commit('SET_PLANNING_ERROR', error.response.data.error.messages)
        } else if (error.message) {
          commit('SET_PLANNING_ERROR', [error.message])
        } else {
          commit('SET_PLANNING_ERROR', 'unknown error')
        }
      }
    },
    async fetchUsersConnections({ commit }) {
      commit('userLoading', true)
      commit('SET_USERS_DISCONNECT_ERROR')
      try {
        const response = await Axios.get(domain + '/api/users-connections', {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        })
        commit('SET_USERS_CONNECTIONS', response.data)
        commit('SET_LAST_UPDATED_TIME', moment().format('ddd D MMM, HH:mm:ss'))
        commit('userLoading', false)
      } catch (error) {
        commit('userLoading', false)
        if (
          error.response &&
          error.response.data &&
          error.response.data.error &&
          error.response.data.error.messages
        ) {
          commit(
            'SET_USERS_DISCONNECT_ERROR',
            error.response.data.error.messages
          )
        }
        commit('SET_USERS_DISCONNECT_ERROR', ['Une erreur est survenue'])
      }
    },
    async disconnectUser({ commit }, payload) {
      commit('SET_USERS_DISCONNECT_ERROR')
      commit('userLoading', true)
      try {
        await Axios.get(domain + `/api/disconnect/${payload}`, {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        })
        commit('UPDATE_USERS_DISCONNECT', payload)
        commit('userLoading', false)
        return {
          success: true
        }
      } catch (error) {
        commit('userLoading', false)
        if (
          error.response &&
          error.response.data &&
          error.response.data.error &&
          error.response.data.error.messages
        ) {
          commit(
            'SET_USERS_DISCONNECT_ERROR',
            error.response.data.error.messages
          )
        }
        commit('SET_USERS_DISCONNECT_ERROR', ['Une erreur est survenue'])
      }
    },
    // Source
    async addSource({ commit }, payload) {
      commit('SET_SOURCE_LOADING', true)
      commit('SET_SOURCE_ERROR')
      const body = {
        name: payload.source.name,
        id_pixel_h2o: payload.source.id_pixel_h2o
          ? payload.source.id_pixel_h2o.id
          : null,
        id_pixel_acf: payload.source.id_pixel_acf
          ? payload.source.id_pixel_acf.id
          : null,
        id_pixel_vertuoz: payload.source.id_pixel_vertuoz
          ? payload.source.id_pixel_vertuoz.id
          : null,
        id_pixel_fiber: payload.source.id_pixel_fiber
          ? payload.source.id_pixel_fiber.id
          : null,
        id_pixel_rse: payload.source.id_pixel_rse
          ? payload.source.id_pixel_rse.id
          : null,
        id_pixel_gse: payload.source.id_pixel_gse
          ? payload.source.id_pixel_gse.id
          : null,
        active: payload.source.active ? 1 : 0,
        internal: payload.source.internal ? 1 : 0,
        comm_sed_id: payload.source.comm_sed_id
          ? payload.source.comm_sed_id.id
          : null
      }
      try {
        const response = await Axios.post(domain + '/api/crm/sources', body, {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        })
        commit('ADD_SOURCE', {
          source: payload.source,
          response: response.data.data
        })
        commit('SET_SOURCE_LOADING')
      } catch (error) {
        commit('SET_SOURCE_LOADING')
        if (
          error.response &&
          error.response.data &&
          error.response.data.error &&
          error.response.data.error.messages
        ) {
          commit('SET_SOURCE_ERROR', error.response.data.error.messages)
        } else {
          commit('SET_SOURCE_ERROR', ['Une erreur est survenue'])
        }
        return false
      }
      return true
    },
    async updateSource({ commit }, payload) {
      commit('SET_SOURCE_LOADING', true)
      commit('SET_SOURCE_ERROR')
      const body = {
        name: payload.source.name,
        id_pixel_h2o: payload.source.id_pixel_h2o
          ? payload.source.id_pixel_h2o.id
          : null,
        id_pixel_acf: payload.source.id_pixel_acf
          ? payload.source.id_pixel_acf.id
          : null,
        id_pixel_vertuoz: payload.source.id_pixel_vertuoz
          ? payload.source.id_pixel_vertuoz.id
          : null,
        id_pixel_fiber: payload.source.id_pixel_fiber
          ? payload.source.id_pixel_fiber.id
          : null,
        id_pixel_rse: payload.source.id_pixel_rse
          ? payload.source.id_pixel_rse.id
          : null,
        id_pixel_gse: payload.source.id_pixel_gse
          ? payload.source.id_pixel_gse.id
          : null,
        active: payload.source.active ? 1 : 0,
        internal: payload.source.internal ? 1 : 0
      }
      if (payload.source.internal == 0) {
        body.comm_sed_id = payload.source.commercial_sedentaire
          ? payload.source.commercial_sedentaire.id
          : null
      } else {
        body.comm_sed_id = null
      }
      try {
        const response = await Axios.put(
          domain + '/api/crm/sources/' + payload.source.id,
          body,
          {
            headers: {
              Authorization: `Bearer ${this.state.currentUser.token}`
            }
          }
        )
        commit('UPDATE_SOURCE', {
          source: payload.source,
          response: response.data.data
        })
        commit('SET_SOURCE_LOADING')
      } catch (error) {
        commit('SET_SOURCE_LOADING')
        if (
          error.response &&
          error.response.data &&
          error.response.data.error &&
          error.response.data.error.messages
        ) {
          commit('SET_SOURCE_ERROR', error.response.data.error.messages)
        } else {
          commit('SET_SOURCE_ERROR', ['Une erreur est survenue'])
        }
        return false
      }
      return true
    },
    async deleteSource({ commit }, payload) {
      commit('SET_SOURCE_LOADING', true)
      commit('SET_SOURCE_ERROR')
      try {
        await Axios.delete(domain + '/api/crm/sources/' + payload.source.id, {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        })
        commit('DELETE_SOURCE', payload.source)
        commit('SET_SOURCE_LOADING')
      } catch (error) {
        commit('SET_SOURCE_LOADING')
        if (
          error.response &&
          error.response.data &&
          error.response.data.error &&
          error.response.data.error.messages
        ) {
          commit('SET_SOURCE_ERROR', error.response.data.error.messages)
        } else {
          commit('SET_SOURCE_ERROR', ['Une erreur est survenue'])
        }
        return false
      }
      return true
    },
    async getListSource({ commit }) {
      commit('SET_SOURCE_LOADING', true)
      commit('SET_SOURCE_ERROR')
      try {
        const response = await Axios.get(domain + '/api/crm/sources', {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        })
        commit('SET_SOURCES', response.data.data)
        commit('SET_SOURCE_LOADING')
      } catch (error) {
        commit('SET_SOURCE_LOADING')
        if (
          error.response &&
          error.response.data &&
          error.response.data.error &&
          error.response.data.error.messages
        ) {
          commit('SET_SOURCE_ERROR', error.response.data.error.messages)
        } else {
          commit('SET_SOURCE_ERROR', ['Une erreur est survenue'])
        }
        return false
      }
      return true
    },
    resetErrorSource({ commit }) {
      commit('SET_SOURCE_ERROR')
    },
    // Webhook
    async addWebhook({ commit }, payload) {
      commit('SET_WEBHOOK_LOADING', true)
      commit('SET_WEBHOOK_ERROR')
      const body = {
        action: payload.action,
        object: payload.object,
        url: payload.url
      }
      try {
        const response = await Axios.post(domain + '/api/crm/webhooks', body, {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        })
        commit('ADD_WEBHOOK', {
          webhook: payload,
          response: response.data.data
        })
        commit('SET_WEBHOOK_LOADING')
      } catch (error) {
        commit('SET_WEBHOOK_LOADING')
        if (
          error.response &&
          error.response.data &&
          error.response.data.error &&
          error.response.data.error.messages
        ) {
          commit('SET_WEBHOOK_ERROR', error.response.data.error.messages)
        } else {
          commit('SET_WEBHOOK_ERROR', ['Une erreur est survenue'])
        }
        return false
      }
      return true
    },
    async deleteWebhook({ commit }, payload) {
      commit('SET_WEBHOOK_LOADING', true)
      commit('SET_WEBHOOK_ERROR')
      try {
        await Axios.delete(domain + '/api/crm/webhooks/' + payload.id, {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        })
        commit('DELETE_WEBHOOK', payload)
        commit('SET_WEBHOOK_LOADING')
      } catch (error) {
        commit('SET_WEBHOOK_LOADING')
        if (
          error.response &&
          error.response.data &&
          error.response.data.error &&
          error.response.data.error.messages
        ) {
          commit('SET_WEBHOOK_ERROR', error.response.data.error.messages)
        } else {
          commit('SET_WEBHOOK_ERROR', ['Une erreur est survenue'])
        }
        return false
      }
      return true
    },
    async fetchListWebhook({ commit }) {
      commit('SET_WEBHOOK_LOADING', true)
      commit('SET_WEBHOOK_ERROR')
      try {
        const response = await Axios.get(domain + '/api/crm/webhooks', {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        })
        commit('SET_WEBHOOK', response.data.data)
        commit('SET_WEBHOOK_LOADING')
      } catch (error) {
        commit('SET_WEBHOOK_LOADING')
        if (
          error.response &&
          error.response.data &&
          error.response.data.error &&
          error.response.data.error.messages
        ) {
          commit('SET_WEBHOOK_ERROR', error.response.data.error.messages)
        } else {
          commit('SET_WEBHOOK_ERROR', ['Une erreur est survenue'])
        }
        return false
      }
      return true
    },
    resetErrorWebhook({ commit }) {
      commit('SET_WEBHOOK_ERROR')
    },
    async exportUser({ commit }, { payload }) {
      try {
        const params = {
          type: payload.type,
          team_type: payload.team_type,
          active: payload.active,
          columns: payload.columns
        }
        if (request) {
          request.cancel('Optional message')
        }
        request = Axios.CancelToken.source()

        const response = await Axios.post(
          domain + '/api/export-users',
          params,
          {
            cancelToken: request.token,
            headers: {
              Authorization: `Bearer ${this.state.currentUser.token}`
            }
          }
        )
        if (response.data && response.data.message) {
          return {
            success: false,
            error: response.data.message
          }
        }
        const a = document.createElement('a')
        a.href = response.data.data.url
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
        commit('doNothing')
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.error &&
          error.response.data.error.messages
        ) {
          return {
            success: false,
            error: error.response.data.error.messages
          }
        }
        return {
          success: false,
          error: ['Une erreur est survenue']
        }
      }
      return {
        success: true
      }
    },
    // SYSTEME DE CORBEILLE
    async fetchCorbeilleUsers({ commit }, payload) {
      commit('userLoading', true)
      try {
        const params = {
          page: payload.page,
          per_page: payload.per_page,
          active: payload.active,
          sort_by: payload.sort_by
        }

        if (payload.exclude) {
          params.exclude = payload.exclude.join()
        }

        if (payload.full_name) {
          params.full_name = payload.full_name
        }
        if (payload.sort_by_desc) {
          params.sort_by_desc = payload.sort_by_desc
        }
        if (request) {
          request.cancel('Optional message')
        }
        request = Axios.CancelToken.source()
        const response = await Axios.get(domain + '/api/recycle-all', {
          cancelToken: request.token,
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          },
          params: params
        })
        commit('SET_CORBEILLE_USERS', response.data)
        commit('userLoading', false)
        return {
          success: true
        }
      } catch (error) {
        commit('userLoading', false)
        if (
          error.response &&
          error.response.data &&
          error.response.data.error &&
          error.response.data.error.messages
        ) {
          return {
            success: false,
            error: error.response.data.error.messages
          }
        }
        return {
          success: false,
          error: 'Une erreur est survenue'
        }
      }
    },
    async deleteDefinitivementUser({ commit }, payload) {
      try {
        await Axios.delete(domain + '/api/force-delete/' + payload.id, {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        })
        commit('REMOVE_DEFINITIVEMENT_USERS', payload)
        return {
          success: true
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.error &&
          error.response.data.error.messages
        ) {
          return {
            success: false,
            error: error.response.data.error.messages
          }
        }
        return {
          success: false,
          error: ['Une erreur est survenue']
        }
      }
    },
    async restaurerUser({ commit }, payload) {
      try {
        await Axios.put(
          domain + '/api/restore/' + payload.id,
          {},
          {
            headers: {
              Authorization: `Bearer ${this.state.currentUser.token}`
            }
          }
        )
        commit('SET_RESTAURER_USERS', payload)
        return {
          success: true
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.error &&
          error.response.data.error.messages
        ) {
          return {
            success: false,
            error: error.response.data.error.messages
          }
        }
        return {
          success: false,
          error: ['Une erreur est survenue']
        }
      }
    },
    setCurentTypeProject({ commit }, payload) {
      commit('SET_CURRENT_PROJECT_TYPE', payload)
    },
    setLoaderThemeForProject({ commit }, payload) {
      commit('SET_LOADER_THEME_FOR_PROJECT', payload)
    }
  },
  modules: {
    planning,
    cPlanning,
    reporting,
    devisIte,
    devisIti,
    devisAudit,
    projects,
    pipelines,
    projectTypes,
    customFields,
    api,
    contacts,
    precarite,
    permission,
    search,
    visibilityProject,
    filtersProjectTH,
    visibilityRegie,
    pixel,
    affectationLeads,
    categorie,
    historiqueUsers,
    historyExportExcel,
    historyPlanningPoseur,
    historyPlanningCommerciaux,
    template,
    notifications,
    etapes,
    releve,
    resources,
    groupe,
    typeEvent,
    events,
    Calendrier,
    tableDynamic,
    contactsImmo,
    actionEvent
  }
})
