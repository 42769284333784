import Axios from '@/axios'
import domain from '@/environment'
import Event from '../../../models/crm/evenements/Event'
import EventCalendrier from '../../../models/crm/evenements/EventCalendrier'
import HistoryEventOfProject from '../../../models/crm/evenements/HistoryEventOfProject'

const state = {
  eventError: null,
  eventLoading: false,
  event: [],
  eventOfProject: [],
  eventCalendrier: [],
  allProject: [],
  countPaginationEvent: 1,
  historyEventOfProject: [],
  countPaginationHistoryEvent: 1
}
const getters = {
  //-----Getter Event Calender ------//
  geteventCalendrier: state => state.eventCalendrier,
  getEventError: state => state.eventError,
  getEventLoading: state => state.eventLoading,
  getEvent: state => state.event,
  getEventOfProject: state => state.eventOfProject,
  getallProject: state => state.allProject,
  countPaginationEvent: state => state.countPaginationEvent,
  getHistoryEventOfProject: state => state.historyEventOfProject,
  countPaginationHistoryEvent: state => state.countPaginationHistoryEvent
}
const mutations = {
  SET_ALL_PROJECT(state, payload) {
    state.allProject = payload
  },
  // Mutation Event Calendrier //
  SET_EVENT_CALENDRIER(state, payload) {
    state.eventCalendrier = payload.map(event => EventCalendrier.create(event))
  },

  // Mutation Add-Event Calendrier //
  ADD_EVENT_CALENDRIER(state, payload) {
    state.eventCalendrier.push(EventCalendrier.create(payload))
  },
  // mutation Update Event
  UPDATE_EVENT_CALENDRIER(state, { event, data }) {
    event.updateEventCalendrierr(data)
  },

  // Mutation Remove Event from Calendrier//
  REMOVE_EVENT_CALENDRIER(state, id) {
    state.eventCalendrier = state.eventCalendrier.filter(type => type.id != id)
  },

  // Mutation Remove Event from Calendrier//
  REMOVE_FAKE_EVENT_CALENDRIER(state) {
    state.eventCalendrier = state.eventCalendrier.filter(
      type => type.id != '####'
    )
  },
  //-----------------End--------------//

  SET_EVENT_LOADING(state, payload = false) {
    state.eventLoading = payload
  },
  SET_EVENT_ERROR(state, payload = null) {
    state.eventError = payload
  },
  SET_PROJECT_ERROR(state, payload = null) {
    state.eventError = payload
  },
  SET_EVENT(state, payload) {
    state.event = payload.map(event => Event.create(event))
  },
  ADD_EVENT(state, payload) {
    state.eventOfProject.unshift(Event.create(payload))
  },
  SET_EVENT_OF_PROJECT(state, payload) {
    state.eventOfProject =
      payload.events && payload.events.length
        ? payload.events.map(event => Event.create(event))
        : payload.events
    if (payload.pagination) {
      state.countPaginationEvent = payload.pagination.total
    } else {
      state.countPaginationEvent = 1
    }
  },
  UPDATE_EVENT(state, { event, data }) {
    event.updateEvent(data)
  },
  REMOVE_EVENT(state, id) {
    state.eventOfProject = state.eventOfProject.filter(type => type.id != id)
  },
  SET_HISTORY_EVENT(state, payload) {
    state.historyEventOfProject = payload.events.map(event =>
      HistoryEventOfProject.create(event)
    )
    if (payload.pagination) {
      state.countPaginationHistoryEvent = payload.pagination.total
    } else {
      state.countPaginationHistoryEvent = 1
    }
  },
  ADD_LOG_ACTIVITY(state, payload) {
    state.historyEventOfProject.unshift(HistoryEventOfProject.create(payload))
  },

  ADD_NEW_ACTION_IN_HISTORY_EVENT(state, payload) {
    state.historyEventOfProject.unshift(HistoryEventOfProject.create(payload))
  }
}
const actions = {
  //------------------- START Event (SETTING) ---------------------------//
  async fetchAllEvent({ commit }, payload) {
    commit('SET_EVENT_LOADING', true)
    commit('SET_EVENT_ERROR')
    try {
      const params = {}
      if (payload && payload.page) {
        params.page = payload.page
      }
      if (payload && payload.per_page) {
        params.per_page = payload.per_page
      }
      const response = await Axios.get(domain + '/api/events', {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        },
        params
      })

      commit('SET_EVENT', response.data.data)
      commit('SET_EVENT_LOADING')
    } catch (error) {
      commit('SET_EVENT_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_EVENT_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_EVENT_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async fetchALLTypeEventOfProject({ commit }, payload) {
    commit('SET_EVENT_LOADING', true)
    commit('SET_EVENT_ERROR')
    try {
      const params = {
        projet_id: payload.project_id
      }
      if (payload && payload.page) {
        params.page = payload.page
      }
      if (payload && payload.per_page) {
        params.per_page = payload.per_page
      }

      const response = await Axios.get(domain + '/api/events', {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        },
        params: params
      })
      commit('SET_EVENT_OF_PROJECT', response.data)
      commit('SET_EVENT_LOADING')
    } catch (error) {
      commit('SET_EVENT_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_EVENT_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_EVENT_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async addEvent({ commit }, payload) {
    commit('SET_EVENT_LOADING', true)
    commit('SET_EVENT_ERROR')
    const body = {
      designation: payload.name,
      detail: payload.detail,
      start_date: payload.start,
      end_date: payload.end,
      projet_id: payload.projet_id,
      color: payload.color
    }

    if (payload.start_time) {
      body.start_time = payload.start_time
    }
    if (payload.end_time) {
      body.end_time = payload.end_time
    }
    if (payload.type) {
      body.type_event_id =
        payload.type && payload.type.id ? payload.type.id : payload.type
    }
    if (payload.fields && payload.fields.length) {
      body.fields = payload.fields
    }
    if (payload.status) {
      body.status = payload.status
    }
    if (payload.assigned_user_id) {
      body.assigned_user_id = payload.assigned_user_id
    }
    try {
      const response = await Axios.post(domain + '/api/events', body, {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        }
      })
      commit('ADD_EVENT', response.data.data)
      commit('SET_EVENT_LOADING')
    } catch (error) {
      commit('SET_EVENT_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_EVENT_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_EVENT_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async updateEvent({ commit }, { body, event }) {
    commit('SET_EVENT_LOADING', true)
    commit('SET_EVENT_ERROR')
    const payload = {
      designation: body.name,
      detail: body.detail,
      start_date: body.start,
      end_date: body.end,
      projet_id: body.projet_id
    }
    if (body.type) {
      payload.type_event_id =
        body.type && body.type.id ? body.type.id : body.type
    }
    if (body.fieldsUpdate && body.fieldsUpdate.length) {
      payload.fields = body.fieldsUpdate
    }
    if (body.start_time) {
      payload.start_time = body.start_time
    }
    if (body.end_time) {
      payload.end_time = body.end_time
    }
    if (body.status) {
      payload.status = body.status
    }
    if (body.status) {
      payload.assigned_user_id = body.assigned_user_id
    }
    try {
      const response = await Axios.put(
        domain + '/api/events/' + event.id,
        payload,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('UPDATE_EVENT', {
        event,
        data: response.data.data
      })

      commit('SET_EVENT_LOADING')
    } catch (error) {
      commit('SET_EVENT_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_EVENT_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_EVENT_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async deletedEvent({ commit }, id) {
    commit('SET_EVENT_LOADING', true)
    commit('SET_EVENT_ERROR')
    try {
      await Axios.delete(domain + '/api/events/' + id, {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        }
      })
      commit('REMOVE_EVENT', id)
      commit('SET_EVENT_LOADING')
    } catch (error) {
      commit('SET_EVENT_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_EVENT_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_EVENT_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  resetErrorEvent({ commit }) {
    commit('SET_EVENT_ERROR')
  },
  //----------------------------- END Event (SETTING) ---------------------------//

  //------------------- Fetch Event CALENDRIER (Planing) ---------------------------//
  async fetchAllEventCalender({ commit }, payload) {
    commit('SET_EVENT_LOADING', true)
    commit('SET_EVENT_ERROR')
    try {
      const response = await Axios.get(domain + '/api/events', {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        },
        params: payload
      })
      //----------- Commit Enent Calender -----------//
      if (response.data.events) {
        commit('SET_EVENT_CALENDRIER', response.data.events)
      }
      //--------------End Commit ---------------------//

      commit('SET_EVENT_LOADING')
    } catch (error) {
      commit('SET_EVENT_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_EVENT_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_EVENT_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },

  //------------------- Add Event CALENDRIER (Planing) ---------------------------//
  async addEventCalendrier({ commit }, payload) {
    commit('SET_EVENT_LOADING', true)
    commit('SET_EVENT_ERROR')
    const body = {
      designation: payload.name,
      detail: payload.detail,
      start_date: payload.start,
      end_date: payload.end,
      projet_id: payload.Projet.id
    }
    if (payload.start_time) {
      body.start_time = payload.start_time
    }
    if (payload.end_time) {
      body.end_time = payload.end_time
    }
    if (payload.type) {
      body.type_event_id =
        payload.type && payload.type.id ? payload.type.id : payload.type
    }
    if (payload.fields && payload.fields.length) {
      body.fields = payload.fields
    }
    if (payload.status) {
      body.status = payload.status
    }
    if (payload.assigned_user_id) {
      body.assigned_user_id = payload.assigned_user_id
    }
    try {
      const response = await Axios.post(domain + '/api/events', body, {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        }
      })
      commit('ADD_EVENT_CALENDRIER', response.data.data)
      commit('SET_EVENT_LOADING')
    } catch (error) {
      commit('SET_EVENT_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_EVENT_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_EVENT_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },

  // ------------------------------ Fake Event ---------------------------------------//
  async createFakeEvent({ commit }, payload) {
    commit('ADD_EVENT_CALENDRIER', {
      id: '####',
      designation: 'Sample Event22',
      detail: 'This is a sample event description.',
      type_event_id: 2,
      start_date: payload.start_date,
      end_date: payload.start_date,
      start:
        payload.start_date +
        (payload.start_time ? ' ' + payload.start_time : ' 00:00:00'),
      end:
        payload.start_date +
        (payload.start_time ? ' ' + payload.start_time : ' 00:00:00'),
      start_time: payload.start_time,
      end_time: '17:00',
      status: 'En attente',
      assigned_user_id: null
    })

    return true
  },

  async DeleteFakeEvent({ commit }) {
    commit('REMOVE_FAKE_EVENT_CALENDRIER')

    return true
  },

  // -----------------------DELETE EVENT CALENDRIER (Planing)------------------------------//
  async deletedEventCalendrier({ commit }, id) {
    commit('SET_EVENT_LOADING', true)
    commit('SET_EVENT_ERROR')
    try {
      await Axios.delete(domain + '/api/events/' + id, {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        }
      })
      commit('REMOVE_EVENT_CALENDRIER', id)
      commit('SET_EVENT_LOADING')
    } catch (error) {
      commit('SET_EVENT_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_EVENT_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_EVENT_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  // -------------------------- UPDATE EVENT CALENDRIER (Planing)
  async updateEventCalendrier({ commit }, { body, event }) {
    commit('SET_EVENT_LOADING', true)
    commit('SET_EVENT_ERROR')
    const payload = {
      designation: body.name,
      detail: body.detail,
      start_date: body.start,
      end_date: body.end,
      projet_id: body.projet_id,
      status: body.status,
      assigned_user_id: body.assigned_user_id
    }
    if (body.fieldsUpdate && body.fieldsUpdate.length) {
      payload.fields = body.fieldsUpdate
    }
    try {
      const response = await Axios.put(
        domain + '/api/events/' + event.id,
        payload,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('UPDATE_EVENT_CALENDRIER', {
        event,
        data: response.data.data
      })
      commit('SET_EVENT_LOADING')
      return true
    } catch (error) {
      commit('SET_EVENT_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_EVENT_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_EVENT_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
  },

  // -------------------------------- Fetch all Project-------------------------------------//
  async fetchAllProject({ commit }) {
    commit('SET_EVENT_LOADING', true)
    commit('SET_PROJECT_ERROR')
    try {
      const response = await Axios.get(domain + '/api/projects-for-planning', {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        }
      })
      if (response.data) {
        commit('SET_ALL_PROJECT', response.data.data)
      }
      commit('SET_EVENT_LOADING')
    } catch (error) {
      commit('SET_EVENT_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECT_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECT_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },

  //----------------------------- START HISTORY Event (PROJECT) ---------------------------//
  async fetchHistoryActivtyEvent({ commit }, payload) {
    commit('SET_EVENT_LOADING', true)
    commit('SET_EVENT_ERROR')
    try {
      const params = {}
      if (payload && payload.type_projet_id) {
        params.type_projet_id = payload.type_projet_id
      }
      if (payload && payload.projet_id) {
        params.projet_id = payload.projet_id
      }
      if (payload && payload.type_events) {
        params.type_events = payload.type_events
      }
      if (payload && payload.page) {
        params.page = payload.page
      }
      if (payload && payload.per_page) {
        params.per_page = payload.per_page
      }
      const response = await Axios.get(domain + '/api/historique-events', {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        },
        params
      })

      commit('SET_HISTORY_EVENT', response.data)
      commit('SET_EVENT_LOADING')
    } catch (error) {
      commit('SET_EVENT_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_EVENT_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_EVENT_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async addLogActivity({ commit }, payload) {
    commit('SET_EVENT_LOADING', true)
    commit('SET_EVENT_ERROR')
    try {
      const response = await Axios.post(
        domain + '/api/historique-events',
        payload,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('ADD_LOG_ACTIVITY', response.data.data)
      commit('SET_EVENT_LOADING')
    } catch (error) {
      commit('SET_EVENT_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_EVENT_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_EVENT_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  //----------------------------- END HISTORY Event (PROJECT) ---------------------------//

  //--------------------------- ADD NEW ACTION IN HISTORY -------------------------------//
  addNewHistoryAction({ commit }, payload) {
    commit('ADD_NEW_ACTION_IN_HISTORY_EVENT', payload)
  }
}
export default {
  state,
  getters,
  mutations,
  actions
}
