// import Axios from 'axios'

// import domain from '@/environment'

const state = {
  currentDate: new Date()
}
const getters = {}

const mutations = {
  setCurrentDate(state, newDate) {
    state.currentDate = newDate
  }
}

const actions = {}

export default {
  state,
  getters,
  mutations,
  actions
}
