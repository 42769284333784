import Axios from 'axios'
import domain from '@/environment'
const state = {
  error: null,
  processing: false,
  bodyContact: [],
  headerContact: [],
  countPaginationContact: 1,
  contactPays: [],
  contactsPaysError: null,
  contactsPaysProcessing: false,
  contactJob: [],
  contactJobError: null,
  contactJobProcessing: false,
  customAllContact: []
}
const getters = {
  getCustomContactsError: state => state.error,
  getCustomContactsProcessing: state => state.processing,
  getBodyContact: state => state.bodyContact,
  getHeaderContact: state => state.headerContact,
  countPaginationContact: state => state.countPaginationContact,
  getContactPays: state => state.contactPays,
  getContactsPaysError: state => state.contactsPaysError,
  getContactsPaysProcessing: state => state.contactsPaysProcessing,
  getContactJob: state => state.contactJob,
  getContactJobError: state => state.contactJobError,
  getContactJobProcessing: state => state.contactJobProcessing,
  getCustomAllContact: state => state.customAllContact
}
const mutations = {
  SET_CONTACTS_CUSTOM_PROCESSING(state, payload = false) {
    state.processing = payload
  },
  SET_CONTACTS_CUSTOM_ERROR(state, payload = null) {
    if (payload && Array.isArray(payload)) {
      state.error = payload.map(message => {
        const translations = {
          'The full name has already been taken.': {
            fr: 'Il existe déjà un contact avec ce nom et prénom.',
            default: 'The full name has already been taken.'
          }
        }

        const i18n = localStorage.getItem('i18n') || 'fr'
        return translations[message]
          ? translations[message][i18n] || translations[message]['default']
          : message
      })
    } else {
      state.error = payload
    }
  },
  SET_CUSTOM_CONTACT_HEADER(state, payload) {
    state.headerContact = payload
  },
  SET_CUSTOM_CONTACT_BODY(state, payload) {
    state.bodyContact = payload.data
    //  PAGINATION
    if (payload.pagination) {
      state.countPaginationContact = payload.pagination.total
    } else {
      state.countPaginationContact = 1
    }
  },
  ADD_CUSTOM_CONTACT(state, payload) {
    state.bodyContact.unshift(payload)
  },
  UPDATE_CUSTOM_CONTACT(state, payload) {
    state.bodyContact = state.bodyContact.map(item =>
      item.id == payload.id ? payload : item
    )
  },
  REMOVE_CONTACT(state, id) {
    state.bodyContact = state.bodyContact.filter(contact => contact.id != id)
  },
  // LIST ALL CUSTOM CONTACT
  SET_ALL_CUSTOM_CONTACT(state, payload) {
    state.customAllContact = payload
  },
  // LISTE PAYS
  SET_CONTACT_PAYS_PROCESSING(state, payload = false) {
    state.contactsPaysProcessing = payload
  },
  SET_CONTACT_PAYS_ERROR(state, payload = null) {
    state.contactsPaysError = payload
  },
  SET_CONTACT_PAYS(state, payload) {
    state.contactPays = payload
  },
  // LISTE JOB
  SET_CONTACT_JOB_PROCESSING(state, payload = false) {
    state.contactJobProcessing = payload
  },
  SET_CONTACT_JOB_ERROR(state, payload = null) {
    state.contactJobError = payload
  },
  SET_CONTACT_JOB(state, payload) {
    state.contactJob = payload
  }
}
const actions = {
  async fetchHeaderCustomContacts({ commit }) {
    commit('SET_CONTACTS_CUSTOM_PROCESSING', true)
    commit('SET_CONTACTS_CUSTOM_ERROR')
    try {
      const response = await Axios.get(domain + '/api/contact-header', {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        }
      })

      commit('SET_CUSTOM_CONTACT_HEADER', response.data.data)
      commit('SET_CONTACTS_CUSTOM_PROCESSING')
    } catch (error) {
      commit('SET_CONTACTS_CUSTOM_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_CONTACTS_CUSTOM_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_CONTACTS_CUSTOM_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async fetchBodyCustomContacts({ commit }, payload) {
    commit('SET_CONTACTS_CUSTOM_PROCESSING', true)
    commit('SET_CONTACTS_CUSTOM_ERROR')
    try {
      const params = {}
      if (payload && payload.page) {
        params.page = payload.page
      }
      if (payload && payload.per_page) {
        params.per_page = payload.per_page
      }
      if (payload && payload.location) {
        params.location = payload.location
      }
      if (payload && payload.search) {
        params.search = payload.search
      }
      const response = await Axios.get(domain + '/api/contact-body', {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        },
        params
      })

      commit('SET_CUSTOM_CONTACT_BODY', response.data)
      commit('SET_CONTACTS_CUSTOM_PROCESSING')
    } catch (error) {
      commit('SET_CONTACTS_CUSTOM_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_CONTACTS_CUSTOM_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_CONTACTS_CUSTOM_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async addCustomContact({ commit }, payload) {
    commit('SET_CONTACTS_CUSTOM_PROCESSING', true)
    commit('SET_CONTACTS_CUSTOM_ERROR')
    try {
      const response = await Axios.post(
        domain + '/api/customContacts',
        payload,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('ADD_CUSTOM_CONTACT', response.data.data)
      commit('SET_CONTACTS_CUSTOM_PROCESSING')
      return {
        contact: response.data.data
      }
    } catch (error) {
      commit('SET_CONTACTS_CUSTOM_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_CONTACTS_CUSTOM_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_CONTACTS_CUSTOM_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
  },
  async updateCustomContact({ commit }, { body, contact }) {
    commit('SET_CONTACTS_CUSTOM_PROCESSING', true)
    commit('SET_CONTACTS_CUSTOM_ERROR')
    try {
      const response = await Axios.put(
        domain + '/api/customContacts/' + contact.id,
        body,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('UPDATE_CUSTOM_CONTACT', response.data.data)

      commit('SET_CONTACTS_CUSTOM_PROCESSING')
    } catch (error) {
      commit('SET_CONTACTS_CUSTOM_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_CONTACTS_CUSTOM_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_CONTACTS_CUSTOM_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async deletedCustomContact({ commit }, id) {
    commit('SET_CONTACTS_CUSTOM_PROCESSING', true)
    commit('SET_CONTACTS_CUSTOM_ERROR')
    try {
      await Axios.delete(domain + '/api/customContacts/' + id, {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        }
      })
      commit('REMOVE_CONTACT', id)
      commit('SET_CONTACTS_CUSTOM_PROCESSING')
    } catch (error) {
      commit('SET_CONTACTS_CUSTOM_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_CONTACTS_CUSTOM_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_CONTACTS_CUSTOM_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  resetErrorCustomContacts({ commit }) {
    commit('SET_CONTACTS_CUSTOM_ERROR')
  },
  // LISTE CONTACT PAYS
  async fetchListContactPays({ commit }) {
    commit('SET_CONTACT_PAYS_PROCESSING', true)
    commit('SET_CONTACT_PAYS_ERROR')
    try {
      const response = await Axios.get(domain + '/api/contact-pays', {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        }
      })

      commit('SET_CONTACT_PAYS', response.data)
      commit('SET_CONTACT_PAYS_PROCESSING')
    } catch (error) {
      commit('SET_CONTACT_PAYS_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_CONTACT_PAYS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_CONTACT_PAYS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  // LISTE CONTACT JOB
  async fetchListContactJob({ commit }) {
    commit('SET_CONTACT_JOB_PROCESSING', true)
    commit('SET_CONTACT_JOB_ERROR')
    try {
      const response = await Axios.get(domain + '/api/contact-jobs', {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        }
      })

      commit('SET_CONTACT_JOB', response.data)
      commit('SET_CONTACT_JOB_PROCESSING')
    } catch (error) {
      commit('SET_CONTACT_JOB_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_CONTACT_JOB_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_CONTACT_JOB_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  // LIST ALL CUSTOM CONTACT
  async fetchAllCustomContacts({ commit }) {
    commit('SET_CONTACTS_CUSTOM_PROCESSING', true)
    commit('SET_CONTACTS_CUSTOM_ERROR')
    try {
      const response = await Axios.get(domain + '/api/customContacts', {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        }
      })
      commit('SET_ALL_CUSTOM_CONTACT', response.data.data)
      commit('SET_CONTACTS_CUSTOM_PROCESSING')
    } catch (error) {
      commit('SET_CONTACTS_CUSTOM_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_CONTACTS_CUSTOM_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_CONTACTS_CUSTOM_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
