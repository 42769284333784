import Entity from '../Entity'
import User from './User'

export default class Comment extends Entity {
  constructor(data) {
    super(data.id)
    this.body = data.body
    this.created_at = data.created_at
    if (data.created_by) {
      if (data.created_by.data) {
        this.created_by = User.create(data.created_by.data)
      } else {
        this.created_by = User.create(data.created_by)
      }
    }
    this.epingler = data.epingler
    this.title = data.title
    this.object = 'Comment'
    this.public = data.public == 1 ? true : false
  }

  /**
   * @param {*} data
   */
  static create(data) {
    if (!data) {
      throw new Error('Comment::create: "data" is undefined')
    }
    return new Comment(data)
  }
  updateNote(data) {
    this.body = data.body
    this.created_at = data.created_at
    this.created_by = User.create(data.created_by.data)
    this.epingler = data.epingler
    this.title = data.title
    this.public = data.public == 1 ? true : false
  }
}
