import Entity from '../Entity'
import Lead from './Lead'
import DayRow from './DayRow'

export default class Day extends Entity {
  constructor(data) {
    super(data.totals_day.planning_day_id)
    this.date = data.date

    // Leads
    this.leads = data.leads.map(lead => Lead.create(lead))

    // Day summary
    this.total_trajet = data.totals_day.total_trajet
    this.total_pose = data.totals_day.total_pose
    this.total_total_h = data.totals_day.total_total_h
    this.total_s_planifie = data.totals_day.total_s_planifie
    this.total_d_planifie = data.totals_day.total_d_planifie
    this.total_r_planifie = data.totals_day.total_r_planifie
    this.total_m_planifie = data.totals_day.total_m_planifie
    this.total_p_planifie = data.totals_day.total_p_planifie
    this.total_pignon_planifie = data.totals_day.total_pignon_planifie
    // planning commercial
    this.total_pignon_planif = data.totals_day.total_pignon_planif
    this.total_vs_planifie = data.totals_day.total_vs_planifie
    this.total_ite_planifie = data.totals_day.total_ite_planifie
    this.total_iti_planifie = data.totals_day.total_iti_planifie
    this.ite_day = data.totals_day.ite_day
    this.iti_day = data.totals_day.iti_day
    this.total_total_m_planifie = data.totals_day.total_total_m_planifie
    this.total_s_pose = data.totals_day.total_s_pose
    this.total_d_pose = data.totals_day.total_d_pose
    this.total_r_pose = data.totals_day.total_r_pose
    this.total_m_pose = data.totals_day.total_m_pose
    this.total_p_pose = data.totals_day.total_p_pose
    this.total_pignon_pose = data.totals_day.total_pignon_pose
    this.total_vs_pose = data.totals_day.total_vs_pose
    this.total_ite_pose = data.totals_day.total_ite_pose
    this.total_iti_pose = data.totals_day.total_iti_pose
    this.total_total_m_pose = data.totals_day.total_total_m_pose
    this.ca_d = data.totals_day.ca_d
    this.pose_planifie_d = data.totals_day.pose_planifie_d
    this.optimised = data.totals_day.optimised

    // set day rows
    this.dayRows = []
    this.dayRows.push(
      DayRow.create({
        id: 'row_index_1_on',
        index: 1,
        active: data.totals_day.row_index_1_on === 1 ? true : false
      })
    )
    this.dayRows.push(
      DayRow.create({
        id: 'row_index_2_on',
        index: 2,
        active: data.totals_day.row_index_2_on === 1 ? true : false
      })
    )
    this.dayRows.push(
      DayRow.create({
        id: 'row_index_3_on',
        index: 3,
        active: data.totals_day.row_index_3_on === 1 ? true : false
      })
    )
    this.dayRows.push(
      DayRow.create({
        id: 'row_index_4_on',
        index: 4,
        active: data.totals_day.row_index_4_on === 1 ? true : false
      })
    )
    this.dayRows.push(
      DayRow.create({
        id: 'row_index_5_on',
        index: 5,
        active: data.totals_day.row_index_5_on === 1 ? true : false
      })
    )
    this.computeRows()
  }

  computeRows() {
    const leads = [...this.leads]
    let simulationConfigured = false
    this.dayRows.forEach(row => {
      row.lead = null
      row.simulatable = false
      if (row.active) {
        const shifted = leads.shift()
        if (shifted) {
          row.lead = shifted
        } else if (!simulationConfigured) {
          row.simulatable = true
          simulationConfigured = true
        }
      }
    })
  }

  static create(data) {
    if (!data) {
      throw new Error('Day::create: "data" is undefined')
    }
    return new Day(data)
  }
}
