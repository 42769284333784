import Axios from '@/axios'
import domain from '@/environment'

const state = {
  pixelError: null
}

const getters = {
  getPixelError: state => state.pixelError
}

const mutations = {
  SET_PIXEL_ERROR(state, payload = null) {
    state.pixelError = payload
  }
}

const actions = {
  async fetchPixelSources({ commit }, { type }) {
    commit('SET_PIXEL_ERROR')
    try {
      const response = await Axios.get(`${domain}/api/pixel-get-sources`, {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        },
        params: {
          type: type
        }
      })
      return response.data.data
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PIXEL_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PIXEL_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
  },
  async fetchPixelStatus({ commit }, { type }) {
    commit('SET_PIXEL_ERROR')
    try {
      const response = await Axios.get(`${domain}/api/pixel-get-status`, {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        },
        params: {
          type: type
        }
      })
      return response.data.data
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PIXEL_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PIXEL_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
  }
}
export default {
  state,
  getters,
  mutations,
  actions
}
