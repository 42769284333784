import Entity from '../../Entity'
export default class Ressources extends Entity {
  constructor(data) {
    super(data.id)
    this.active = data.active
    this.created_at = data.created_at
    this.designation = data.designation
    this.detail = data.detail
    this.sousFamille = data.sousFamille
    this.type = data.type
    this.fields = data.fields
    this.files = data.files
    // Dont delete this line plz
    this.checked = false
  }

  /**
   * @param {*} data
   */
  static create(data) {
    if (!data) {
      throw new Error('Ressources::create: "data" is undefined')
    }
    return new Ressources(data)
  }
  updateResourceObjet(data) {
    this.active = data.active
    this.created_at = data.created_at
    this.designation = data.designation
    this.detail = data.detail
    this.sousFamille = data.sousFamille
    this.type = data.type
    this.fields = data.fields
    this.files = data.files
  }
}
