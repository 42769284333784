import Entity from '../../Entity'
export default class TypeEvenements extends Entity {
  constructor(data) {
    super(data.id)
    this.created_at = data.created_at
    this.designation = data.designation
    this.detail = data.detail
    this.fields = data.fields
    this.events = data.events
    this.type_projet_id = data.type_projet_id
    // Dont delete this line plz
    this.checked = false
    this.color = data.color ? data.color : 'transparent'
    this.icon = data.icon ? data.icon : null
    this.action_id = data.action_id
  }

  /**
   * @param {*} data
   */
  static create(data) {
    if (!data) {
      throw new Error('TypeEvenements::create: "data" is undefined')
    }
    return new TypeEvenements(data)
  }
  updateTypeEvent(data) {
    this.created_at = data.created_at
    this.designation = data.designation
    this.detail = data.detail
    this.fields = data.fields
    this.events = data.events
    this.type_projet_id = data.type_projet_id
    this.color = data.color
    this.icon = data.icon
    this.action_id = data.action_id
  }
}
