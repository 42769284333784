import Entity from '../../Entity'
import SubStage from './SubStage'

export default class Stage extends Entity {
  constructor(data) {
    super(data.id)
    this.name = data.name
    this.order = data.order
    this.has_email = data.has_email
    this.has_sms = data.has_sms
    this.projectsCount = null
    this.projet_count = data.projet_count
    this.flech = false
    this.subStages = []
    if (data.csubstages && data.csubstages.data) {
      this.subStages = data.csubstages.data.map(s => SubStage.create(s))
    }
  }

  /**
   * @param { id, name, order, status, projet_count, csubstages } data
   */
  static create(data) {
    if (!data) {
      throw new Error('Stage::create: "data" is undefined')
    }
    return new Stage(data)
  }

  update(data) {
    this.name = data.name
    this.order = data.order
    this.has_email = data.has_email
    this.has_sms = data.has_sms
    this.projectsCount = data.projet_count
    this.projet_count = data.projet_count
    if (data.csubstages && data.csubstages.data) {
      this.subStages = data.csubstages.data.map(s => SubStage.create(s))
    }
  }
}
