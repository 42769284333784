import Axios from 'axios'
import store from '@/store'
Axios.interceptors.response.use(
  function(response) {
    return response
  },
  function(error) {
    if (
      error.response &&
      error.response.data &&
      error.response.data.message &&
      error.response.data.message == 'Unauthenticated.'
    ) {
      store.dispatch('logout')
      setTimeout(() => {
        store.commit(
          'setError',
          "Dû au paramétrage de sécurité concernant la durée d'inactivité du navigateur ,vous avez été déconnecté, veuillez vous reconnecter."
        )
      })
    } else {
      return Promise.reject(error)
    }
  }
)

export default Axios
