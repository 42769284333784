<template>
  <v-app>
    <div id="app" :class="{ 'immo-font': getCurentProjectTypeImmo }">
      <router-view />
      <portal-target
        name="project-modal"
        :transition="fadeTransition"
      ></portal-target>
      <portal-target
        name="portal-modal-immobilier"
        :transition="fadeTransition"
      >
      </portal-target>
      <portal-target
        name="portal-child-modal-immobilier"
        :transition="fadeTransition"
      >
      </portal-target>
      <portal-target
        name="new-project-modal"
        :transition="fadeTransition"
      ></portal-target>
      <portal-target name="Modal-Gestion-Appels" :transition="fadeTransition">
      </portal-target>
    </div>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex'
import pusher from './pusher'
export default {
  computed: {
    ...mapGetters(['getCurentProjectTypeImmo']),
    fadeTransition() {
      return {
        functional: true,
        render(h, context) {
          return h(
            'transition',
            { props: { name: 'slide-fade', mode: 'out-in' } },
            context.children
          )
        }
      }
    }
  },
  mounted() {
    pusher.getnotificationPusher()
  }
}
</script>

<style lang="scss" scoped>
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.3s ease-in-out;
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(500px);
  opacity: 0;
}

html body .immo-font {
  font: 400 15px/20px 'Montserrat', Arial !important;
  font-family: 'Montserrat', Arial !important;
}

.v-application .immo-font {
  font-family: 'Montserrat', Arial !important;
}
</style>
