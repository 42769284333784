import Axios from '@/axios'
import HistoryPlanningCommerciaux from '../../models/crm/HistoryPlanningCommerciaux'
import domain from '@/environment'
let request = null
const state = {
  historyPlanningCommerciauxError: null,
  historyPlanningCommerciauxLoading: false,
  listHistoryPlanningCommerciaux: [],
  countPaginationPlanningCommerciaux: 1
}
const getters = {
  getHistoryPlanningCommerciauxError: state =>
    state.historyPlanningCommerciauxError,
  getHistoryPlanningCommerciauxLoading: state =>
    state.historyPlanningCommerciauxLoading,
  getListHistoryPlanningCommerciaux: state =>
    state.listHistoryPlanningCommerciaux,
  countPaginationPlanningCommerciaux: state =>
    state.countPaginationPlanningCommerciaux
}
const mutations = {
  SET_HISTORY_PLANNING_COMMERCIAUX_LOADING(state, payload = false) {
    state.historyPlanningCommerciauxLoading = payload
  },
  SET_HISTORY_PLANNING_COMMERCIAUX_ERROR(state, payload = null) {
    state.historyPlanningCommerciauxError = payload
  },
  SET_HISTORY_LIST_PLANNING_COMMERCIAUX(state, payload) {
    state.listHistoryPlanningCommerciaux = payload.data.map(item =>
      HistoryPlanningCommerciaux.create(item)
    )
    if (payload.meta) {
      state.countPaginationPlanningCommerciaux = payload.meta.pagination.total
    } else {
      state.countPaginationPlanningCommerciaux = 0
    }
  },
  // RESET_HISTORY_PLANNING_COMMERCIAUX(state) {
  //   state.listHistoryPlanningCommerciaux = []
  //   state.countPaginationPlanningCommerciaux = 0
  // }
}
const actions = {
  /** o93ed istanaha lin ijib resultat async */
  async fetchListHistoryPlanningCommerciaux({ commit }, payload) {
    commit('SET_HISTORY_PLANNING_COMMERCIAUX_LOADING', true)
    commit('SET_HISTORY_PLANNING_COMMERCIAUX_ERROR')
    const params = {
      optimised: payload.optimised,
      page: payload.page,
      per_page: payload.per_page
    }
    if (
      payload.user_commercial &&
      payload.user_commercial.value &&
      payload.user_commercial.value !== 'vide'
    ) {
      params.user_commercial = payload.user_commercial.value
    }
    if (payload.row_index) {
      params.row_index = 'row_index_' + payload.row_index + '_on'
    }
    if (payload.day) {
      params.day = payload.day
    }
    try {
      if (request) {
        request.cancel()
      }
      request = Axios.CancelToken.source()
      const response = await Axios.get(
        domain + '/api/historique-planning-comm',
        {
          params: params,

          cancelToken: request.token,
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('SET_HISTORY_LIST_PLANNING_COMMERCIAUX', response.data)
      commit('SET_HISTORY_PLANNING_COMMERCIAUX_LOADING')
    } catch (error) {
      commit('SET_HISTORY_PLANNING_COMMERCIAUX_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit(
          'SET_HISTORY_PLANNING_COMMERCIAUX_ERROR',
          error.response.data.error.messages
        )
      } else {
        commit('SET_HISTORY_PLANNING_COMMERCIAUX_ERROR', [
          'Une erreur est survenue'
        ])
      }
      return false
    }
    return true
  },
  resetHistoryPlanningCommerciaux({ commit }) {
    commit('SET_HISTORY_PLANNING_COMMERCIAUX_ERROR')
  }
}
export default {
  state,
  getters,
  mutations,
  actions
}
