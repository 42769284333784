import Axios from 'axios'
import Ressources from '../../models/crm/resources/Ressources'
import TypeResource from '../../models/crm/resources/TypeResource'
import domain from '@/environment'
let requestResource = null
const state = {
  //    RESOURCE
  resource: [],
  resourceNonAffecteToProject: [],
  oneResource: null,
  resourceLoading: false,
  resourceError: false,
  countPaginationResource: 0,
  countPaginationAffecteResourceToProject: 1,
  //   TYPE RESOURCE
  typeResource: [],
  oneTypeResource: null,
  typeResourceLoading: false,
  typeResourceError: false,
  countPaginationTypeResource: 1,
  headersResource: []
}
const getters = {
  //    RESOURCE
  resourceCount(state) {
    return state.resource.length;
  },
  getResource: state => state.resource,
  getResourceNonAffecteToProject: state => state.resourceNonAffecteToProject,
  getOneResource: state => state.oneResource,
  getResourceLoading: state => state.resourceLoading,
  getResourceError: state => state.resourceError,
  countPaginationResource: state => state.countPaginationResource,
  countPaginationAffecteResourceToProject: state =>
    state.countPaginationAffecteResourceToProject,

  //   TYPE RESOURCE
  getTypeResource: state => state.typeResource,
  getOneTypeResource: state => state.oneTypeResource,
  getTypeResourceLoading: state => state.typeResourceLoading,
  getTypeResourceError: state => state.typeResourceError,
  countPaginationTypeResource: state => state.countPaginationTypeResource,
  // PROPOSALS
  getHeadersResource: state => state.headersResource
}
const mutations = {
  //    RESOURCE
  SET_RESOURCE_LOADING(state, payload = false) {
    state.resourceLoading = payload
  },
  SET_RESOURCE_ERROR(state, payload = null) {
    state.resourceError = payload
  },
  SET_ALL_RESOURCE(state, payload) {
    state.resource = payload ? payload.map(g => Ressources.create(g)) : payload
  },
  SET_RESOURCES_META(state, payload) {
    //  PAGINATION
    if (payload && payload.pagination) {
      state.countPaginationResource = payload.pagination.total
    } else {
      state.countPaginationResource = 0
    }
  },
  SET_ONE_RESOURCE(state, type) {
    state.oneResource = Ressources.create(type)
  },
  ADD_RESOURCE(state, payload) {
    state.resource.push(Ressources.create(payload))
  },
  UPDATE_RESOURCE(state, { resource, data }) {
    resource.updateResourceObjet(data)
  },
  UPDATE_RESOURCE_FILE(state, data) {
    state.resource = state.resource.map(item =>
      item.id == data.id ? Ressources.create(data) : item
    )
  },
  REMOVE_RESOURCE(state, id) {
    state.resource = state.resource.filter(res => res.id != id)
  },
  // AFFECTER RESOURCE TO PROJET
  FETCH_NO_AFFECTE_TO_PROJECT(state, payload) {
    state.resourceNonAffecteToProject = payload.data.map(g =>
      Ressources.create(g)
    )
    //  PAGINATION
    if (payload.meta && payload.meta.pagination) {
      state.countPaginationAffecteResourceToProject =
        payload.meta.pagination.total
    } else {
      state.countPaginationAffecteResourceToProject = 1
    }
  },
  //   TYPE RESOURCE
  SET_TYPE_RESOURCE_LOADING(state, payload = false) {
    state.typeResourceLoading = payload
  },
  SET_TYPE_RESOURCE_ERROR(state, payload = null) {
    state.typeResourceError = payload
  },
  SET_ALL_TYPE_RESOURCE(state, payload) {
    state.typeResource = payload.data.map(g => TypeResource.create(g))
    //  PAGINATION
    if (payload.pagination) {
      state.countPaginationTypeResource = payload.pagination.total
    } else {
      state.countPaginationTypeResource = 1
    }
  },
  SET_ONE_TYPE_RESOURCE(state, type) {
    state.oneTypeResource = TypeResource.create(type)
  },
  ADD_TYPE_RESOURCE(state, payload) {
    state.typeResource.push(TypeResource.create(payload))
  },
  UPDATE_TYPE_RESOURCE(state, { typeResource, data }) {
    typeResource.updateTypeResourceObjet(data)
  },
  REMOVE_TYPE_RESOURCE(state, id) {
    state.typeResource = state.typeResource.filter(type => type.id != id)
  },
  ADD_CUSTEM_FIELDS_TYPE_RESOURCE(state, response) {
    state.oneTypeResource = TypeResource.create(response)
  },
  UPDATE_TYPE_RESOURCE_CUSTEM_FIELD(state, response) {
    state.oneTypeResource = response
  },
  REFRESH_ORDER_CUSTEM_FIELDS_TYPE_RESOURCE(state, payload) {
    state.oneTypeResource.fields = payload
  },
  UPDATE_ORDER_CUSTEM_FIELDS_TYPE_RESOURCE(state, payload) {
    state.oneTypeResource = payload
  },
  DO_NOTHING() {},
  SET_ALL_HEADERS_RESOURCE(state, payload) {
    state.headersResource = payload
  },
  SET_UPDATE_VIEW_RESOURCE(state, payload) {
    state.headersResource = state.headersResource.map(item => {
      if (item.id == payload.field_id) {
        item.visibility = payload.visibility
      }
      return item
    })
  }
}
const actions = {
  // RESOUURCE
  async fetchAllResource({ commit }, payload) {
    commit('SET_RESOURCE_LOADING', true)
    commit('SET_RESOURCE_ERROR')
    if (payload.refreshTable) {
      commit('SET_ALL_RESOURCE', [])
    }
    try {
      const params = {
        active: payload?.active ? 1 : 0
      }
      if (payload && payload.projet_id) {
        params.projet_id = payload.projet_id
      }
      if (payload && payload.affectedToProject) {
        params.affectedToProject = payload.affectedToProject
      }
      if (payload && payload.page) {
        params.page = payload.page
      }
      if (payload && payload.per_page) {
        params.per_page = payload.per_page
      }
      if (requestResource) {
        requestResource.cancel()
      }
      requestResource = Axios.CancelToken.source()
      const response = await Axios.get(domain + '/api/ressources', {
        cancelToken: requestResource.token,
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        },
        params: params
      })

      if (response && response.data) {
        commit('SET_ALL_RESOURCE', response.data.data)
        commit('SET_RESOURCES_META', response.data.meta)
        commit('SET_RESOURCE_LOADING')
      } else {
        commit('SET_ALL_RESOURCE', [])
      }
    } catch (error) {
      commit('SET_RESOURCE_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_RESOURCE_ERROR', error.response.data.error.messages)
      }
      //  else {
      //   commit('SET_RESOURCE_ERROR', ['Une erreur est survenue'])
      // }
      return false
    }
    return true
  },
  async fetchOneResource({ commit }, resource_id) {
    commit('SET_RESOURCE_LOADING', true)
    commit('SET_RESOURCE_ERROR')
    try {
      const response = await Axios.get(
        domain + '/api/ressources/' + resource_id,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('SET_ONE_RESOURCE', response.data.data)
      commit('SET_RESOURCE_LOADING')
    } catch (error) {
      commit('SET_RESOURCE_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_RESOURCE_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_RESOURCE_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async addResource({ commit }, payload) {
    commit('SET_RESOURCE_LOADING', true)
    commit('SET_RESOURCE_ERROR')
    try {
      const response = await Axios.post(domain + '/api/ressources', payload, {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        }
      })
      commit('ADD_RESOURCE', response.data.data)
      commit('SET_RESOURCE_LOADING')
      return { success: response.data.data }
    } catch (error) {
      commit('SET_RESOURCE_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_RESOURCE_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_RESOURCE_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
  },
  async updateResource({ commit }, { body, resource }) {
    commit('SET_RESOURCE_LOADING', true)
    commit('SET_RESOURCE_ERROR')
    const payload = {
      designation: body.designation,
      detail: body.detail,
      active: body.active ? 1 : 0
    }
    if (body.type) {
      payload.type_resource_id =
        body.type && body.type.id ? body.type.id : body.type
    }
    if (body.fieldsUpdate && body.fieldsUpdate.length) {
      payload.fields = body.fieldsUpdate
    }
    try {
      const response = await Axios.put(
        domain + '/api/ressources/' + body.id,
        payload,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('UPDATE_RESOURCE', {
        resource,
        data: response.data.data
      })
      commit('SET_RESOURCE_LOADING')
    } catch (error) {
      commit('SET_RESOURCE_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_RESOURCE_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_RESOURCE_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async updateResourceProjet({ commit }, { body, resource }) {
    commit('SET_RESOURCE_LOADING', true)
    commit('SET_RESOURCE_ERROR')
    try {
      const response = await Axios.post(
        domain + '/api/update-ressource/' + resource.id,
        body,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('UPDATE_RESOURCE_FILE', response.data.data)
      commit('SET_RESOURCE_LOADING')
    } catch (error) {
      commit('SET_RESOURCE_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_RESOURCE_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_RESOURCE_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async deletedResource({ commit }, id) {
    commit('SET_RESOURCE_LOADING', true)
    commit('SET_RESOURCE_ERROR')
    try {
      await Axios.delete(domain + '/api/ressources/' + id, {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        }
      })
      commit('REMOVE_RESOURCE', id)
      commit('SET_RESOURCE_LOADING')
    } catch (error) {
      commit('SET_RESOURCE_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_RESOURCE_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_RESOURCE_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async deletedMultiResource({ commit }, ids) {
    commit('SET_RESOURCE_LOADING', true)
    commit('SET_RESOURCE_ERROR')
    try {
      await Axios.delete(domain + '/api/destroy-many-ressources', {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        },
        data: { ids: ids }
      })
      commit('REMOVE_RESOURCE', ids)
      commit('SET_RESOURCE_LOADING')
    } catch (error) {
      commit('SET_RESOURCE_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_RESOURCE_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_RESOURCE_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  resetErrorResource({ commit }) {
    commit('SET_RESOURCE_ERROR')
  },
  // AFFECTER RESSOURCE TO  PROJECT
  async affecterNonResourceToProject({ commit }, payload) {
    commit('SET_RESOURCE_LOADING', true)
    commit('SET_RESOURCE_ERROR')
    const params = {
      projet_id: payload.projet_id,
      affectedToProject: 0
    }

    if (payload && payload.page) {
      params.page = payload.page
    }
    if (payload && payload.per_page) {
      params.per_page = payload.per_page
    }
    try {
      const response = await Axios.get(domain + '/api/ressources', {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        },
        params
      })
      commit('FETCH_NO_AFFECTE_TO_PROJECT', response.data)
      commit('SET_RESOURCE_LOADING')
    } catch (error) {
      commit('SET_RESOURCE_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_RESOURCE_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_RESOURCE_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async addResourceAffectedToProjet({ commit }, payload) {
    commit('SET_RESOURCE_LOADING', true)
    commit('SET_RESOURCE_ERROR')
    const body = {
      ressources: payload.ressources
    }

    try {
      await Axios.post(
        domain + '/api/crm/projets/' + payload.projet_id + '/sync-ressources',
        body,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('DO_NOTHING')
      commit('SET_RESOURCE_LOADING')
    } catch (error) {
      commit('SET_RESOURCE_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_RESOURCE_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_RESOURCE_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async removeResourceAffecteToProjet({ commit }, payload) {
    commit('SET_RESOURCE_LOADING', true)
    commit('SET_RESOURCE_ERROR')
    const body = {
      ressources: payload.ressources
    }
    try {
      await Axios.post(
        domain + '/api/crm/projets/' + payload.projet_id + '/remove-ressources',
        body,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('DO_NOTHING')
      commit('SET_RESOURCE_LOADING')
    } catch (error) {
      commit('SET_RESOURCE_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_RESOURCE_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_RESOURCE_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  //   TYPE RESOURCE
  async fetchAllTypeResource({ commit }, payload) {
    commit('SET_TYPE_RESOURCE_LOADING', true)
    commit('SET_TYPE_RESOURCE_ERROR')
    const params = {}
    if (payload && payload.page) {
      params.page = payload.page
    }
    if (payload && payload.per_page) {
      params.per_page = payload.per_page
    }
    if (payload && payload.type_projet_id) {
      params.type_projet_id = payload.type_projet_id
    }
    try {
      const response = await Axios.get(domain + '/api/types_ressources', {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        },
        params: params
      })
      commit('SET_ALL_TYPE_RESOURCE', response.data)
      commit('SET_TYPE_RESOURCE_LOADING')
    } catch (error) {
      commit('SET_TYPE_RESOURCE_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_TYPE_RESOURCE_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_TYPE_RESOURCE_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async fetchOneTypeResource({ commit }, type_resource_id) {
    commit('SET_TYPE_RESOURCE_LOADING', true)
    commit('SET_TYPE_RESOURCE_ERROR')
    try {
      const response = await Axios.get(
        domain + '/api/types_ressources/' + type_resource_id,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('SET_ONE_TYPE_RESOURCE', response.data.data)
      commit('SET_TYPE_RESOURCE_LOADING')
    } catch (error) {
      commit('SET_TYPE_RESOURCE_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_TYPE_RESOURCE_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_TYPE_RESOURCE_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async addTypeResource({ commit }, payload) {
    commit('SET_TYPE_RESOURCE_LOADING', true)
    commit('SET_TYPE_RESOURCE_ERROR')
    try {
      const response = await Axios.post(
        domain + '/api/types_ressources',
        payload,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('ADD_TYPE_RESOURCE', response.data.data)
      commit('SET_TYPE_RESOURCE_LOADING')
    } catch (error) {
      commit('SET_TYPE_RESOURCE_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_TYPE_RESOURCE_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_TYPE_RESOURCE_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async updateTypeResource({ commit }, { body, typeResource }) {
    commit('SET_TYPE_RESOURCE_LOADING', true)
    commit('SET_TYPE_RESOURCE_ERROR')
    const payload = {
      detail: body.detail,
      type_projet_id: body.type_projet_id
    }
    // Supprimer les espaces au début et à la fin des chaînes
    let chaine1Trimmed = body.designation.trim()
    let chaine2Trimmed = typeResource.designation.trim()
    if (
      body.designation &&
      typeResource.designation &&
      chaine1Trimmed !== chaine2Trimmed
    ) {
      payload.designation = body.designation
    }
    try {
      const response = await Axios.put(
        domain + '/api/types_ressources/' + typeResource.id,
        payload,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('UPDATE_TYPE_RESOURCE', {
        typeResource,
        data: response.data.data
      })
      commit('SET_TYPE_RESOURCE_LOADING')
    } catch (error) {
      commit('SET_TYPE_RESOURCE_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_TYPE_RESOURCE_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_TYPE_RESOURCE_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async deletedTypeResource({ commit }, id) {
    commit('SET_TYPE_RESOURCE_LOADING', true)
    commit('SET_TYPE_RESOURCE_ERROR')
    try {
      await Axios.delete(domain + '/api/types_ressources/' + id, {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        }
      })
      commit('REMOVE_TYPE_RESOURCE', id)
      commit('SET_TYPE_RESOURCE_LOADING')
    } catch (error) {
      commit('SET_TYPE_RESOURCE_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_TYPE_RESOURCE_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_TYPE_RESOURCE_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  resetErrorTypeResource({ commit }) {
    commit('SET_TYPE_RESOURCE_ERROR')
  },
  // CUUSTOM FIELDS
  async addCutemFieldsToTypeResource({ commit }, { idTypeResource, data }) {
    commit('SET_TYPE_RESOURCE_LOADING', true)
    commit('SET_TYPE_RESOURCE_ERROR')
    try {
      const response = await Axios.put(
        domain + '/api/types_ressources/' + idTypeResource,
        data,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('ADD_CUSTEM_FIELDS_TYPE_RESOURCE', response.data.data)
      commit('SET_TYPE_RESOURCE_LOADING')
    } catch (error) {
      commit('SET_TYPE_RESOURCE_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_TYPE_RESOURCE_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_TYPE_RESOURCE_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async updateCustemFieldTypeResource({ commit }, { idTypeResource, data }) {
    commit('SET_TYPE_RESOURCE_LOADING', true)
    commit('SET_TYPE_RESOURCE_ERROR')
    try {
      const response = await Axios.put(
        domain + '/api/update-type-field/' + idTypeResource,
        data,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )

      commit('UPDATE_TYPE_RESOURCE_CUSTEM_FIELD', response.data.data)
      commit('SET_TYPE_RESOURCE_LOADING')
    } catch (error) {
      commit('SET_TYPE_RESOURCE_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_TYPE_RESOURCE_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_TYPE_RESOURCE_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  refreshOrderCustemFieldsTypeResource({ commit }, payload) {
    commit('REFRESH_ORDER_CUSTEM_FIELDS_TYPE_RESOURCE', payload)
  },
  async updateOrderCustemFieldsTypeResource({ commit }, payload) {
    commit('SET_TYPE_RESOURCE_LOADING', true)
    commit('SET_TYPE_RESOURCE_ERROR')
    try {
      const response = await Axios.put(
        domain +
          '/api/update-ordre/' +
          payload.idTypeResource +
          '/' +
          payload.field_id +
          '/' +
          payload.ordre,
        {},
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )

      commit('UPDATE_ORDER_CUSTEM_FIELDS_TYPE_RESOURCE', response.data.data)
      commit('SET_TYPE_RESOURCE_LOADING')
    } catch (error) {
      commit('SET_TYPE_RESOURCE_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_TYPE_RESOURCE_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_TYPE_RESOURCE_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  // PROPOSALS
  async fetchHeaderResource({ commit }, payload) {
    commit('SET_RESOURCE_LOADING')
    commit('SET_RESOURCE_ERROR')
    const params = {
      type_ressource_id: payload.type_ressource_id
    }

    try {
      const response = await Axios.get(domain + '/api/header-ressources', {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        },
        params: params
      })
      commit('SET_ALL_HEADERS_RESOURCE', response.data.data)
      commit('SET_RESOURCE_LOADING', true)
    } catch (error) {
      commit('SET_RESOURCE_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_RESOURCE_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_RESOURCE_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async UpdateViewTableResource({ commit }, payload) {
    commit('SET_PROJECTS_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      const response = await Axios.post(
        domain + '/api/update-ressource-field',
        payload.data,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('SET_UPDATE_VIEW_RESOURCE', response.data)
      commit('SET_PROJECTS_PROCESSING')
    } catch (error) {
      commit('SET_PROJECTS_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  updateOrderFieldsResource({ commit }, payload) {
    commit('SET_ALL_HEADERS_RESOURCE', payload)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
