import Axios from 'axios'
import domain from '@/environment'
import ActionEventModal from '../../../models/crm/evenements/ActionEvent'
const state = {
    //  ACTIONS
    actionEvent: [],
    actionEventLoading: false,
    actionEventError: null,
    countPaginationactionEvent: 0
}
const getters = {
    //  ACTIONS
    getactionEvent: state => state.actionEvent,
    getactionEventLoading: state => state.actionEventLoading,
    getactionEventError: state => state.actionEventError,
}
const mutations = {
    //   TYPE EVENT
    SET_ACTION_EVENT_LOADING(state, payload = false) {
        state.actionEventLoading = payload
    },
    SET_ACTION_EVENT_ERROR(state, payload = null) {
        state.actionEventError = payload
    },
    SET_ALL_ACTION_EVENT(state, payload) {
        state.actionEvent = payload.data.map(i => ActionEventModal.create(i))
    },
    ADD_NEW_ACTION(state, payload) { state.actionEvent.unshift(ActionEventModal.create(payload)) },
    EMPTY_COMMIT() { },
    UPDATE_ACTION(state, payload) {
        payload.payload.update(payload.response)
    }
}
const actions = {
    // FETCH ALL ACTIONS
    async fetchAllActions({ commit }) {
        try {

            const response = await Axios.get(domain + '/api/actions/', {
                headers: {
                    Authorization: `Bearer ${this.state.currentUser.token}`,
                }
            })
            commit('SET_ALL_ACTION_EVENT', response.data.data)
            return true
        } catch (error) {
            if (
                error.response &&
                error.response.data &&
                error.response.data.error &&
                error.response.data.error.messages
            ) {
                commit('SET_ACTION_EVENT_ERROR', error.response.data.error.messages)
            } else {
                commit('SET_ACTION_EVENT_ERROR', ['Une erreur est survenue'])
            }
            return false
        }
    },

    async addMessageAction({ commit }, payload) {
        try {
            const body = new FormData()
            body.append('body', payload.message)
            body.append('project_id', payload.project_id)
            payload.to.forEach((element, index) => {

                body.append('to[' + index + ']', element.value)
            });
            payload.attachments.forEach((element, index) => {
                body.append('attachments[' + index + ']', element)
            });

            const response = await Axios.post(domain + '/api/actions/message', body, {
                headers: {
                    Authorization: `Bearer ${this.state.currentUser.token}`,
                    'Content-Type': 'application/x-www-form-urlencoded',
                }
            })
            commit('EMPTY_COMMIT')
            return { succes: true, data: response.data }
        } catch (error) {
            if (
                error.response &&
                error.response.data &&
                error.response.data.error &&
                error.response.data.error.messages
            ) {
                return { succes: false, error: error.response.data.error.messages }
            } else {
                return { succes: false, error: ['Une erreur est survenue'] }
            }
        }
    },
    async addEmailAction({ commit }, payload) {
        try {
            const body = new FormData()
            body.append('body', payload.message)
            body.append('subject', payload.subject)
            body.append('project_id', payload.project_id)
            payload.to.forEach((element, index) => {
                body.append('to[' + index + ']', element.value)
            });
            payload.attachments.forEach((element, index) => {
                body.append('attachments[' + index + ']', element)
            });
            const response = await Axios.post(domain + '/api/actions/email', body, {
                headers: {
                    Authorization: `Bearer ${this.state.currentUser.token}`,
                    'Content-Type': 'application/x-www-form-urlencoded',
                }
            })
            commit('EMPTY_COMMIT')

            return { succes: true, data: response.data }
        } catch (error) {
            if (
                error.response &&
                error.response.data &&
                error.response.data.error &&
                error.response.data.error.messages
            ) {
                return { succes: false, error: error.response.data.error.messages }
            } else {
                return { succes: false, error: ['Une erreur est survenue'] }
            }
        }
    },
    //ADD NEW ACTION
    async addActions({ commit }, payload) {
        try {
            const body = new FormData()
            body.append('type', payload.type)
            body.append('message', payload.message)
            body.append('project_id', payload.project_id)
            body.append('scheduled_at', payload.scheduled_at)
            body.append('scheduled', payload.scheduled_at ? true : false)
            if (payload.type == 'email') {
                body.append('subject', payload.subject)
            }
            payload.to.forEach((element, index) => {

                body.append('to[' + index + ']', element.value)
            });
            payload.attachments.forEach((element, index) => {
                body.append('attachments[' + index + ']', element)
            });

            const response = await Axios.post(domain + '/api/actions', body, {
                headers: {
                    Authorization: `Bearer ${this.state.currentUser.token}`,
                    'Content-Type': 'application/x-www-form-urlencoded',
                }
            })
            commit('ADD_NEW_ACTION', response.data)
            return { succes: true, data: response.data }
        } catch (error) {
            if (
                error.response &&
                error.response.data &&
                error.response.data.error &&
                error.response.data.error.messages
            ) {
                return { succes: false, error: error.response.data.error.messages }
            } else {
                return { succes: false, error: ['Une erreur est survenue'] }
            }
        }
    },
    // GET DATA FOR  ACTION
    async fetchDataForActions({ commit }, payload) {
        try {
            const response = await Axios.get(domain + '/api/actions/contact-info/' + payload, {
                headers: {
                    Authorization: `Bearer ${this.state.currentUser.token}`,
                }
            })
            commit('EMPTY_COMMIT')

            return { succes: true, data: response.data }
        } catch (error) {
            if (
                error.response &&
                error.response.data &&
                error.response.data.error &&
                error.response.data.error.messages
            ) {
                return { succes: false, error: error.response.data.error.messages }
            } else {
                return { succes: false, error: ['Une erreur est survenue'] }
            }
        }
    },
    //ADD NEW ACTION
    async updateActions({ commit }, payload) {
        try {
            const body = new FormData()
            body.append('type', payload.data.type)
            body.append('message', payload.data.message)
            body.append('project_id', payload.data.project_id)
            body.append('scheduled_at', payload.data.scheduled_at)
            body.append('scheduled', payload.data.scheduled_at ? true : false)
            if (payload.data.type == 'email') {
                body.append('subject', payload.data.subject)
            }
            payload.data.to.forEach((element, index) => {

                body.append('to[' + index + ']', element.value)
            });
            payload.data.attachments.forEach((element, index) => {
                body.append('attachments-to_add[' + index + ']', element)
            });
            payload.data.attachments_to_delete.forEach((element, index) => {
                body.append('attachments_to_delete[' + index + ']', element)
            });

            const response = await Axios.post(domain + '/api/actions/' + payload.data.id, body, {
                headers: {
                    Authorization: `Bearer ${this.state.currentUser.token}`,
                    'Content-Type': 'application/x-www-form-urlencoded',
                }
            })
            commit('UPDATE_ACTION', { response: response.data, payload: payload.origin })
            return { succes: true }
        } catch (error) {
            if (
                error.response &&
                error.response.data &&
                error.response.data.error &&
                error.response.data.error.messages
            ) {
                return { succes: false, error: error.response.data.error.messages }
            } else {
                return { succes: false, error: ['Une erreur est survenue'] }
            }
        }
    },
}

export default {
    state,
    getters,
    mutations,
    actions
}
