import Axios from 'axios'
import Etape from '../../models/crm/etapes/Etape'
import EtapesStage from '../../models/crm/etapes/EtapesStage'
import EtapesSubStage from '../../models/crm/etapes/EtapesSubStage'
import domain from '@/environment'

const state = {
  etapes: [],
  etapesLoading: false,
  etapesError: false,
  etapeSubstageLoading: false,
  countPaginationEtapes: 1
}
const getters = {
  getEtapes: state => state.etapes,
  getEtapesLoading: state => state.etapesLoading,
  getEtapesError: state => state.etapesError,
  getEtapesSubstageLoading: state => state.etapeSubstageLoading,
  countPaginationEtapes: state => state.countPaginationEtapes
}
const mutations = {
  SET_ETAPES_LOADING(state, payload = false) {
    state.etapesLoading = payload
  },
  SET_ETAPES_SUBSTAGE_LOADING(state, payload = false) {
    state.etapeSubstageLoading = payload
  },
  SET_ETAPES_ERROR(state, payload = null) {
    state.etapesError = payload
  },

  // fetch etapes mutations
  SET_ETAPES(state, payload) {
    state.etapes = payload.data.map(etape => Etape.create(etape))
    //  PAGINATION
    if (payload.meta && payload.meta.pagination) {
      state.countPaginationEtapes = payload.meta.pagination.total
    } else {
      state.countPaginationEtapes = 1
    }
  },
  ADD_ETAPES(state, response) {
    state.etapes.push(Etape.create(response))
  },
  REMOVE_ETAPES(state, id) {
    state.etapes = state.etapes.filter(etapes => etapes.id != id)
  },
  UPDATE_ORDER_ETAPES(state, payload) {
    state.etapes = payload
  },
  DO_NOTHING() {},
  UPDATE_ETAPES(state, { etapes, data }) {
    etapes.update(data)
  },

  SET_ETAPES_COUNTER(state, { etapes }) {
    for (let pipeline of state.etapes) {
      const updatedPipline = etapes.find(item => item.id == pipeline.id)
      if (updatedPipline.cstages && updatedPipline.cstages.data) {
        for (let stage of pipeline.stages) {
          const updatedStage = updatedPipline.cstages.data.find(
            item => item.id == stage.id
          )
          if (updatedStage) {
            stage.update(updatedStage)
          }
        }
      }
    }
  },
  // stages mutations
  SET_STAGE_ETAPES(state, { etape, stage }) {
    if (stage) {
      etape.stages = stage.map(item => EtapesStage.create(item))
    } else {
      etape.stages = []
    }
  },
  ADD_ETAPE_STAGES(state, { etape, stage }) {
    etape.stages.push(EtapesStage.create(stage))
  },
  REMOVE_ETAPE_STAGES(state, { etape, stage }) {
    etape.stages = etape.stages
      .filter(s => s.id != stage.id)
      .map(s => {
        if (s.order > stage.order) {
          s.order--
        }
        return s
      })
  },
  UPDATE_ETAPE_STAGES(state, { stage, data }) {
    stage.updateStage(data)
  },

  //subStages mutations
  SET_ETAPES_SUB_STAGES(state, { stage, subStage }) {
    if (stage) {
      stage.subStages = subStage.map(item => EtapesSubStage.create(item))
    } else {
      stage.subStages = []
    }
  },
  ADD_ETAPES_SUB_STAGES(state, { stage, subStage }) {
    stage.subStages.push(EtapesSubStage.create(subStage))
  },
  REMOVE_ETAPE_SUB_STAGES(state, { csubStage, cstage }) {
    cstage.subStages = cstage.subStages
      .filter(ss => ss.id != csubStage.id)
      .map(ss => {
        if (ss.order > csubStage.order) {
          ss.order--
        }
        return ss
      })
  },
  UPDATE_ETAPE_SUB_STAGES(state, { subStage, data }) {
    subStage.update(data)
  }
}
const actions = {
  async fetchEtapes({ commit }, payload) {
    commit('SET_ETAPES_LOADING', payload && payload.isNotLoading ? false : true)
    commit('SET_ETAPES_ERROR')
    try {
      const params = {
        type_id: payload.type_id
      }
      if (payload && payload.page) {
        params.page = payload.page
      }
      if (payload && payload.per_page) {
        params.per_page = payload.per_page
      }
      const response = await Axios.get(domain + '/api/crm/steps', {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        },
        params: params
      })
      commit('SET_ETAPES', response.data)
      commit('SET_ETAPES_LOADING')
    } catch (error) {
      commit('SET_ETAPES_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_ETAPES_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_ETAPES_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  // Pipelines
  async addEtapes({ commit }, etapes) {
    commit('SET_ETAPES_LOADING', true)
    commit('SET_ETAPES_ERROR')

    try {
      const response = await Axios.post(domain + '/api/crm/steps', etapes, {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        }
      })
      commit('ADD_ETAPES', response.data.data)
      commit('SET_ETAPES_LOADING')
    } catch (error) {
      commit('SET_ETAPES_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_ETAPES_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_ETAPES_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async deleteEtapes({ commit }, id) {
    commit('SET_ETAPES_LOADING', true)
    commit('SET_ETAPES_ERROR')
    try {
      await Axios.delete(domain + '/api/crm/steps/' + id, {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        }
      })
      commit('REMOVE_ETAPES', id)
      commit('SET_ETAPES_LOADING')
    } catch (error) {
      commit('SET_ETAPES_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_ETAPES_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_ETAPES_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  updateOrderEtapes({ commit }, payload) {
    commit('UPDATE_ORDER_ETAPES', payload)
  },
  async moveEtapes({ commit }, { type_id, etapes, new_ordre }) {
    commit('SET_ETAPES_LOADING', true)
    commit('SET_ETAPES_ERROR')
    const body = {
      step_id: etapes.id,
      new_ordre: new_ordre
    }

    try {
      await Axios.patch(domain + '/api/crm/steps/move/' + type_id, body, {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        }
      })
      commit('DO_NOTHING')
      commit('SET_ETAPES_LOADING')
    } catch (error) {
      commit('SET_ETAPES_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_ETAPES_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_ETAPES_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async updateEtape({ commit }, { body, etape }) {
    commit('SET_ETAPES_LOADING', true)
    commit('SET_ETAPES_ERROR')
    try {
      const response = await Axios.put(
        domain + '/api/crm/steps/' + etape.id,
        body,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('UPDATE_ETAPES', {
        etapes: etape,
        data: response.data.data
      })
      commit('SET_ETAPES_LOADING')
    } catch (error) {
      commit('SET_ETAPES_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_ETAPES_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_ETAPES_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },

  // Stages
  async fetchEtapeStage({ commit }, payload) {
    commit('SET_ETAPES_LOADING', payload && payload.isNotLoading ? false : true)
    commit('SET_ETAPES_ERROR')
    try {
      const params = {
        step_id: payload.step_id
      }
      const response = await Axios.get(domain + '/api/crm/cstage', {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        },
        params: params
      })
      commit('SET_STAGE_ETAPES', {
        etape: payload.etape,
        stage: response.data.data
      })
      commit('SET_ETAPES_LOADING')
    } catch (error) {
      commit('SET_ETAPES_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_ETAPES_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_ETAPES_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async addEtapesStages({ commit }, { etape, stage }) {
    commit('SET_ETAPES_LOADING', true)
    commit('SET_ETAPES_ERROR')
    const body = {
      name: stage.name,
      order: stage.order,
      // status: stage.status,
      step_id: etape.id
    }
    try {
      const response = await Axios.post(domain + '/api/crm/cstage', body, {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        }
      })
      commit('ADD_ETAPE_STAGES', {
        etape: etape,
        stage: response.data.data
      })
      commit('SET_ETAPES_LOADING')
    } catch (error) {
      commit('SET_ETAPES_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_ETAPES_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_ETAPES_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async deleteEtapeStages({ commit }, { etape, stage }) {
    commit('SET_ETAPES_LOADING', true)
    commit('SET_ETAPES_ERROR')
    try {
      await Axios.delete(domain + '/api/crm/cstage/' + stage.id, {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        }
      })
      commit('REMOVE_ETAPE_STAGES', { etape, stage })
      commit('SET_ETAPES_LOADING')
    } catch (error) {
      commit('SET_ETAPES_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_ETAPES_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_ETAPES_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async updateEtapesStages({ commit }, { stage, data }) {
    commit('SET_ETAPES_LOADING', true)
    commit('SET_ETAPES_ERROR')
    try {
      const response = await Axios.put(
        domain + '/api/crm/cstage/' + stage.id,
        data,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('UPDATE_ETAPE_STAGES', { stage, data: response.data.data })
      commit('SET_ETAPES_LOADING')
    } catch (error) {
      commit('SET_ETAPES_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_ETAPES_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_ETAPES_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },

  // SubStages
  async fetchEtapeSubstage({ commit }, payload) {
    commit('SET_ETAPES_SUBSTAGE_LOADING', true)
    commit('SET_ETAPES_ERROR')
    try {
      const params = {
        cstage_id: payload.cstage_id
      }
      if (payload.project_id) {
        params.project_id = payload.project_id
      }
      const response = await Axios.get(domain + '/api/crm/csubstage', {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        },
        params: params
      })
      commit('SET_ETAPES_SUB_STAGES', {
        stage: payload.stage,
        subStage: response.data.data
      })
      commit('SET_ETAPES_SUBSTAGE_LOADING')
    } catch (error) {
      commit('SET_ETAPES_SUBSTAGE_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_ETAPES_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_ETAPES_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async addEtapeSubStages({ commit }, { stage, data }) {
    commit('SET_ETAPES_LOADING', true)
    commit('SET_ETAPES_ERROR')
    try {
      const response = await Axios.post(domain + '/api/crm/csubstage', data, {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        }
      })
      commit('ADD_ETAPES_SUB_STAGES', {
        stage,
        subStage: response.data.data
      })
      commit('SET_ETAPES_LOADING')
    } catch (error) {
      commit('SET_ETAPES_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_ETAPES_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_ETAPES_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async deleteEtapeSousStages({ commit }, { csubStage, cstage }) {
    commit('SET_ETAPES_LOADING', true)
    commit('SET_ETAPES_ERROR')
    try {
      await Axios.delete(domain + '/api/crm/csubstage/' + csubStage.id, {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        }
      })
      commit('REMOVE_ETAPE_SUB_STAGES', {
        csubStage,
        cstage
      })
      commit('SET_ETAPES_LOADING')
    } catch (error) {
      commit('SET_ETAPES_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_ETAPES_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_ETAPES_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async updateEtapeSubStages({ commit }, { subStage, data }) {
    commit('SET_ETAPES_LOADING', true)
    commit('SET_ETAPES_ERROR')
    try {
      const response = await Axios.put(
        domain + '/api/crm/csubstage/' + subStage.id,
        data,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('UPDATE_ETAPE_SUB_STAGES', {
        subStage,
        data: response.data.data
      })
      commit('SET_ETAPES_LOADING')
    } catch (error) {
      commit('SET_ETAPES_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_ETAPES_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_ETAPES_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  resetErrorEtapes({ commit }) {
    commit('SET_ETAPES_ERROR')
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
