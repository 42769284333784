import Axios from 'axios'
import domain from '@/environment'
const state = {
  error: null,
  processing: false,
  contacts: []
}
const getters = {
  getContactsError: state => state.error,
  getContactsProcessing: state => state.processing,
  getContactsList: state => state.contacts
}
const mutations = {
  SET_CONTACTS_PROCESSING(state, payload = false) {
    state.processing = payload
  },
  SET_CONTACTS_ERROR(state, payload = null) {
    state.error = payload
  },
  SET_CONTACTS_LIST(state, payload) {
    state.contacts = payload
  },
  CONTACTS_COMMIT_NOTHING() { },
  UPDATE_CONTACTS_CONTACT(state, payload) {
    payload.entity.update(payload.response)
    payload.entity.checked = true
    // setTimeout(() => {
    //   for (
    //     let index = 0;
    //     index < this.state.projects.projects.length;
    //     index++
    //   ) {
    //     const projet = this.state.projects.projects[index]
    //     if (projet.id === payload.entity.id) {
    //       this.state.projects.projects[index].update(payload.response)
    //     }
    //   }
    // })
  }
}
const actions = {
  // TODO : add filters by name
  async searchContacts({ commit }, payload) {
    commit('SET_CONTACTS_PROCESSING', true)
    commit('SET_CONTACTS_ERROR')
    try {
      const response = await Axios.get(domain + '/api/crm/contacts', {
        params: {
          full_name: payload,
          minimal: true
        },
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        }
      })
      commit('SET_CONTACTS_LIST', response.data)
      commit('SET_CONTACTS_PROCESSING')
    } catch (error) {
      commit('SET_CONTACTS_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_CONTACTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_CONTACTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async updateContact({ commit }, payload) {
    commit('SET_CONTACTS_PROCESSING', true)
    commit('SET_CONTACTS_ERROR')
    try {
      let data = { [payload.field]: payload.value }
      if (payload && payload.field && Array.isArray(payload.field)) {
        payload.field.forEach(objet => {
          for (const prop in objet) {
            data[prop] = objet[prop]
          }
        })
      }
      const response = await Axios.put(
        domain +
        // `/api/crm/contacts/${payload.entity.contact_id}?project_id=${payload.entity.id}`
        `/api/crm/update-contact/${payload.entity.contact_id}?project_id=${payload.entity.id}`,
        {
          ...data
        },
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('UPDATE_CONTACTS_CONTACT', {
        entity: payload.entity,
        response: response.data
      })

      commit('SET_CONTACTS_PROCESSING')
    } catch (error) {
      commit('SET_CONTACTS_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_CONTACTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_CONTACTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  resetSearchContacts({ commit }) {
    commit('SET_CONTACTS_LIST', [])
  },
  resetErrorContact({ commit }) {
    commit('SET_CONTACTS_ERROR')
  },
  async getContactPhone({ commit }, id) {
    try {
      const response = await Axios.get(
        `${domain}/api/crm/contacts/${id}/phones`,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('CONTACTS_COMMIT_NOTHING')
      if (response && response.data && response.data.data) {
        return response.data.data.map(p => p.number)
      }
      return []
    } catch (error) {
      return []
    }
  },

}

export default {
  state,
  getters,
  mutations,
  actions
}
