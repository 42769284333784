import Vue from 'vue'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
Vue.component('font-awesome-icon', FontAwesomeIcon)
import {
  faUserSecret,
  faAngleUp,
  faAngleDown,
  faPencilAlt,
  faPlus,
  faInfoCircle,
  faTimes,
  faTrashAlt,
  faSearch,
  faEye,
  faTimesCircle,
  faFileAlt,
  faTruck,
  faSyncAlt,
  faMinusSquare,
  faExternalLinkAlt,
  faCheck,
  faCaretDown,
  faCaretUp,
  faHandshake,
  faCopy,
  faDownload,
  faFileSignature,
  faMoneyCheck,
  faCogs,
  faMinus,
  faPhoneAlt,
  faUserFriends,
  faGripLines,
  faBars,
  faUser,
  faArrowCircleLeft,
  faCircle as fasCircle,
  faHourglassStart,
  faBriefcase,
  faBold,
  faItalic,
  faStrikethrough,
  faUnderline,
  faParagraph,
  faListUl,
  faListOl,
  faQuoteRight,
  faCalendarDay,
  faClock,
  faStopwatch,
  faStickyNote,
  faPaperclip,
  faFileCsv,
  faFileWord,
  faFilePowerpoint,
  faFilePdf,
  faFileImage,
  faFileExcel,
  faFileCode,
  faFileArchive,
  faCommentAlt,
  faThumbtack,
  faFileAudio,
  faFile,
  faSave,
  faExclamationCircle,
  faExclamationTriangle,
  faTrash,
  faFilter,
  faSpinner,
  faUserTie,
  faGlobeEurope,
  faLock,
  faPaste,
  faWindowClose,
  faBell,
  faEllipsisH
} from '@fortawesome/free-solid-svg-icons'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'
import {
  faCaretSquareLeft,
  faCaretSquareDown,
  faCircle as farCircle,
  faBell as farBell,
  faCommentAlt as farCommentAlt
} from '@fortawesome/free-regular-svg-icons'
library.add(faUserSecret)
library.add(faAngleUp)
library.add(faAngleDown)
library.add(faPencilAlt)
library.add(faPlus)
library.add(faInfoCircle)
library.add(faTimes)
library.add(faTrashAlt)
library.add(faSearch)
library.add(faEye)
library.add(faTimesCircle)
library.add(faFileAlt)
library.add(faTruck)
library.add(faInstagram)
library.add(faSyncAlt)
library.add(faMinusSquare)
library.add(faCaretSquareLeft)
library.add(faExternalLinkAlt)
library.add(faCheck)
library.add(faCaretDown)
library.add(faCaretUp)
library.add(faHandshake)
library.add(faCaretSquareDown)
library.add(faCopy)
library.add(faDownload)
library.add(faFileSignature)
library.add(faMoneyCheck)
library.add(faBars)
library.add(faCogs)
library.add(faMinus)
library.add(faPhoneAlt)
library.add(faUser)
library.add(faUserFriends)
library.add(faGripLines)
library.add(faArrowCircleLeft)
library.add(fasCircle)
library.add(farCircle)
library.add(faHourglassStart)
library.add(faBriefcase)
library.add(faBold)
library.add(faItalic)
library.add(faStrikethrough)
library.add(faUnderline)
library.add(faParagraph)
library.add(faListUl)
library.add(faListOl)
library.add(faQuoteRight)
library.add(faCalendarDay)
library.add(faClock)
library.add(faStopwatch)
library.add(faStickyNote)
library.add(faStickyNote)
library.add(faPaperclip)
library.add(faFileCsv)
library.add(faFileWord)
library.add(faFilePowerpoint)
library.add(faFilePdf)
library.add(faFileImage)
library.add(faFileExcel)
library.add(faFileCode)
library.add(faFileArchive)
library.add(faCommentAlt)
library.add(faThumbtack)
library.add(faFileAudio)
library.add(faFile)
library.add(faSave)
library.add(faExclamationCircle)
library.add(faExclamationTriangle)
library.add(faTrash)
library.add(faFilter)
library.add(faSpinner)
library.add(faUserTie)
library.add(faGlobeEurope)
library.add(faLock)
library.add(faPaste)
library.add(faWindowClose)
library.add(faBell)
library.add(farBell)
library.add(faEllipsisH)
library.add(farCommentAlt)
