import Axios from '@/axios'
import domain from '@/environment'
// import User from '../../models/crm/User'
import FiltersProjectTH from '../../models/crm/FiltersProjectTH'
import Project from '../../models/crm/Project'
const state = {
  filtersProjectTHError: null,
  filtersProjectTHLoading: false,
  listfiltersProjectTHLoading: false,
  fieldsTH: [],
  operatorsTH: [],
  filtersTH: [],
  countFilter: 0
}
const getters = {
  getFiltersProjectTHError: state => state.filtersProjectTHError,
  getFiltersProjectTHLoading: state => state.filtersProjectTHLoading,
  getListFiltersProjectTHLoading: state => state.listfiltersProjectTHLoading,
  getFieldsTHToFiltre: state => state.fieldsTH,
  getOperatorTHToFiltre: state => state.operatorsTH,
  getFiltersProjectTH: state => state.filtersTH,
  getFilterTHCount: state => state.countFilter
}
const mutations = {
  SET_FILTERS_PROJECT_TH_LOADING(state, payload = false) {
    state.filtersProjectTHLoading = payload
  },
  SET_LIST_FILTERS_PROJECT_TH_LOADING(state, payload = false) {
    state.listfiltersProjectTHLoading = payload
  },
  SET_FILTERS_PROJECT_TH_ERROR(state, payload = null) {
    state.filtersProjectTHError = payload
  },
  SET_FIELDS_TH(state, fields) {
    state.fieldsTH = fields.data
  },
  SET_OPERATORS_TH(state, payload) {
    const operators = []
    const keys = Object.keys(payload.response.data)
    keys.forEach(key => {
      operators.push({
        key: key,
        label: payload.response.data[key]
      })
    })
    state.operatorsTH[payload.field.field_key] = {
      operators,
      links: payload.response.links,
      possible_values: payload.response.possible_values,
      type: payload.response.type
    }
  },
  SET_LISTE_FILTERS_TH(state, payload) {
    state.filtersTH = payload.map(filter => FiltersProjectTH.create(filter))
  },
  ADD_FILTER_TH(state, payload) {
    state.filtersTH.unshift(FiltersProjectTH.create(payload))
  },
  UPDATE_FILTER_TH(state, payload) {
    state.filtersTH = state.filtersTH.map(item =>
      item.id == payload.response.id
        ? FiltersProjectTH.create(payload.response)
        : item
    )
  },
  REMOVE_FILTER_TH(state, payload) {
    state.filtersTH = state.filtersTH.filter(filter => {
      return filter.id != payload.id
    })
  },
  SELECTED_FILTERS_PROJECT_TH(state, payload) {
    this.state.projects.projects = payload.map(p =>
      Project.create(p, {
        projectFields: state.projectFields,
        contactFields: state.contactFields
      })
    )
  },
  SELECTED_FILTERS_PROJECT_TH_META(state, payload) {
    if (payload.pagination && payload.pagination.total) {
      state.countFilter = payload.pagination.total
    } else {
      state.countFilter = 0
    }
  }
}
const actions = {
  async fetchFieldsToFilterTH({ commit }, payload) {
    commit('SET_FILTERS_PROJECT_TH_LOADING', true)
    commit('SET_FILTERS_PROJECT_TH_ERROR')
    const params = {}
    if (payload.th_prj) {
      params.th_prj = payload.th_prj
    }
    try {
      const response = await Axios.post(
        domain + '/api/fields-filter',
        {},
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          },
          params: params
        }
      )
      commit('SET_FIELDS_TH', response.data)
      commit('SET_FILTERS_PROJECT_TH_LOADING')
    } catch (error) {
      commit('SET_FILTERS_PROJECT_TH_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit(
          'SET_FILTERS_PROJECT_TH_ERROR',
          error.response.data.error.messages
        )
      } else {
        commit('SET_FILTERS_PROJECT_TH_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async fetchOperatorsToFieldsTH({ commit }, payload) {
    commit('SET_FILTERS_PROJECT_TH_LOADING', true)
    commit('SET_FILTERS_PROJECT_TH_ERROR')
    try {
      const response = await Axios.post(
        domain + '/api/fields-operators',
        { field_id: payload.field_id },
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('SET_OPERATORS_TH', {
        response: response.data,
        field: payload
      })
      commit('SET_FILTERS_PROJECT_TH_LOADING')
    } catch (error) {
      commit('SET_FILTERS_PROJECT_TH_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit(
          'SET_FILTERS_PROJECT_TH_ERROR',
          error.response.data.error.messages
        )
      } else {
        commit('SET_FILTERS_PROJECT_TH_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async fetchListFiltresTH({ commit }, payload) {
    commit('SET_LIST_FILTERS_PROJECT_TH_LOADING', true)
    commit('SET_FILTERS_PROJECT_TH_ERROR')
    const params = {}
    if (payload.exclude) {
      params.exclude = payload.exclude
    }
    if (payload.for_visibility) {
      params.for_visibility = payload.for_visibility
    }
    if (payload.th_prj) {
      params.th_prj = payload.th_prj
    }
    try {
      const response = await Axios.get(domain + '/api/pfilters', {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        },
        params: params
      })
      commit('SET_LISTE_FILTERS_TH', response.data)
      commit('SET_LIST_FILTERS_PROJECT_TH_LOADING')
    } catch (error) {
      commit('SET_LIST_FILTERS_PROJECT_TH_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit(
          'SET_FILTERS_PROJECT_TH_ERROR',
          error.response.data.error.messages
        )
      } else {
        commit('SET_FILTERS_PROJECT_TH_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async ajouterFilterTH({ commit }, payload) {
    commit('SET_FILTERS_PROJECT_TH_LOADING', true)
    commit('SET_FILTERS_PROJECT_TH_ERROR')
    try {
      const response = await Axios.post(domain + '/api/pfilters', payload, {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        }
      })
      commit('ADD_FILTER_TH', response.data)
      commit('SET_FILTERS_PROJECT_TH_LOADING')
    } catch (error) {
      commit('SET_FILTERS_PROJECT_TH_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit(
          'SET_FILTERS_PROJECT_TH_ERROR',
          error.response.data.error.messages
        )
      } else {
        commit('SET_FILTERS_PROJECT_TH_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async deleteFilterTH({ commit }, payload) {
    commit('SET_FILTERS_PROJECT_TH_LOADING', true)
    commit('SET_FILTERS_PROJECT_TH_ERROR')
    try {
      await Axios.delete(domain + '/api/pfilters/' + payload.id, {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        }
      })
      commit('REMOVE_FILTER_TH', payload)
      commit('SET_FILTERS_PROJECT_TH_LOADING')
    } catch (error) {
      commit('SET_FILTERS_PROJECT_TH_LOADING')

      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit(
          'SET_FILTERS_PROJECT_TH_ERROR',
          error.response.data.error.messages
        )
      } else {
        commit('SET_FILTERS_PROJECT_TH_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async updateFiltreTH({ commit }, payload) {
    commit('SET_FILTERS_PROJECT_TH_LOADING', true)
    commit('SET_FILTERS_PROJECT_TH_ERROR')
    const body = {
      conditions: payload.conditions,
      name: payload.name,
      type: payload.type
    }
    try {
      const response = await Axios.put(
        domain + '/api/pfilters/' + payload.id,
        body,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('UPDATE_FILTER_TH', {
        filter: payload,
        response: response.data
      })
      commit('SET_FILTERS_PROJECT_TH_LOADING')
    } catch (error) {
      commit('SET_FILTERS_PROJECT_TH_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit(
          'SET_FILTERS_PROJECT_TH_ERROR',
          error.response.data.error.messages
        )
      } else {
        commit('SET_FILTERS_PROJECT_TH_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async selectedFilterProjectTh({ commit }, payload) {
    commit('SET_FILTERS_PROJECT_TH_LOADING', true)
    commit('SET_FILTERS_PROJECT_TH_ERROR')
    try {
      const params = {
        categories: 1,
        page: payload.page,
        per_page: payload.per_page
      }
      if (payload.filter_th_id) {
        params.filter_th_id = payload.filter_th_id
      }
      const response = await Axios.post(
        domain + '/api/crm/projets/search',
        {},
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          },
          params: params
        }
      )
      commit('SELECTED_FILTERS_PROJECT_TH', response.data.data)
      commit('SELECTED_FILTERS_PROJECT_TH_META', response.data.meta)
      commit('SET_FILTERS_PROJECT_TH_LOADING')
    } catch (error) {
      commit('SET_FILTERS_PROJECT_TH_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit(
          'SET_FILTERS_PROJECT_TH_ERROR',
          error.response.data.error.messages
        )
      } else {
        commit('SET_FILTERS_PROJECT_TH_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },

  resetErrorFilterTH({ commit }) {
    commit('SET_FILTERS_PROJECT_TH_ERROR')
  }
}
export default {
  state,
  getters,
  mutations,
  actions
}
