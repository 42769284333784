export default {
  'Exception inattendue. Essayer plus tard':
    'Unexpected exception. Try again later',
  // sidebar crm
  entreprise: 'COMPANY',
  titleEntrepise: 'General France Energy',
  // Modules
  dashbord: 'Dashboard',
  businessMap: 'Business Map',
  marketplace: 'Marketplace',
  bibliothèque: 'Library',
  gestionTache: 'Task management',
  lieudetravail: 'Workplace',
  calendrierPartage: 'calender',
  tâches: 'Tasks',
  inbox: 'Mailbox',
  baseConnaissances: 'Knowledge base',
  annuairePro: 'Pro Directory',
  actualites: 'News',
  crmIla26: 'CRM Ila 26',
  remontées: 'Information Feedback',
  immobilier: 'Real estate',
  // PERSONNELLE
  personnelle: 'PERSONAL',
  persoLite: 'Perso Lite',
  // PARAMÈTRES
  parametres: 'Settings',
  demandeSupport: 'Ask for support',
  monProfil: 'My profile',
  // dashbord
  close: 'Close',
  textSidebar: "Find your colleagues' contacts and more!",
  // Menu crm
  planning: 'Planning',
  sav: 'SAV',
  typeProjet: 'Type of project',
  servicesClients: 'Services clients',
  search: 'Search',
  searchMsg: 'Search {msg}',
  projet: 'Projects',
  aucun: 'No',
  // Search
  aucunResultat: 'results for',
  Ouvert: 'Open',
  Perdu: 'Lost',
  Conclu: 'Won',
  // settings entity
  gestionEntite: 'ENTITY MANAGEMENT',
  users: 'User Management',
  historyUsers: 'User history',
  equipe: 'Teams',
  group: 'Group',
  depot: 'deposits',
  visibility: 'Visibilities',
  connexions: 'Connections',
  gestionProjet: 'Project management',
  typesProjets: 'Types of projects',
  customFields: 'Custom fields',
  categories: 'Categories',
  pipelines: 'Pipelines',
  resources: 'Resources',
  evenement: 'events',
  sources: 'Sources',
  precarite: 'Precarity',
  visibilityProjet: 'Project visibility',
  template: 'Templates',
  webhooks: 'Webhooks',
  apiToken: 'API token',
  history: 'History',
  exportExcel: 'Excel export',
  // users
  utilisateurs: 'Users',
  configUser: {
    name: 'user',
    active: 'Active',
    desactive: 'Disabled',
    export: 'Exporting'
  },
  autorisations: 'permissions',
  configPermission: {
    name: 'permission'
  },
  corbeille: 'trash',
  add: 'Add {msg}',
  update: 'Edit',
  deleted: 'Delete',
  active: 'Enable',
  diabled: 'Disable',
  renommer: 'Rename',
  loading: 'Loading...',
  btnCancel: 'Cancel',
  btnValider: 'Confirm',
  btnAdd: 'Add',
  btnDetail: 'Details',
  btnEnregistre: 'Save',
  btnRestore: 'Restore',
  btnDeleteDefinitive: 'Delete permanently',
  msgdeleted: 'Are you sure you want to delete {msg}',
  msgDesactive: 'Are you sure you want to deactivate {msg}',
  ensemble: 'Sets',
  // Fields
  msgOblg: 'is required',
  msgObligMsg: '{msg} is required',
  noDataOption: 'No options found',
  noDataOptionMsg: 'No {option} found',
  msgSelectDate: 'Select the date',
  name: 'Name',
  object: 'Object',
  champ: 'Fields',
  operator: 'Operators',
  date: 'Date',
  value: 'Value',
  title: 'Title',
  message: 'Message',
  copy: 'Copy',
  view: 'View',
  // users
  first_name: 'First name',
  phone: 'Phone',
  zip_code: 'Postal code',
  password: 'Password',
  confirm_password: 'Confirm password',
  company: 'Company',
  Superior: 'Superior',
  team_manager: 'Team leader',
  department: 'Department',
  commercial_sedentaire: 'Sedentary sales',
  categoriesStage: 'Stage category',
  stade: 'Stage',
  adresse: 'Address',
  ville: 'City',
  sous_categories: 'Sub category',
  category: 'Category',
  sousType: 'Sub-type',
  statut: 'Status',
  selectionner: 'Please select',
  filtre: 'Filter',
  telecharger: 'Download',
  champObligatoire: 'This field is required',
  suivant: 'Next',
  precedant: 'Previous',
  choose: 'Choose',
  etape: 'Step',
  date_debut: 'Start date',
  date_fin: 'End date',
  designation: 'designation',
  //projet immobilier
  total: 'Total',
  all: 'All',
  note: 'Note',
  email: 'Email',
  call: 'Call',
  task: 'Task',
  meetting: 'Metting',
  assign: 'Assign',
  assign_owner: 'Assign Owner',
  opportunity: 'Opportunity',
  budget: 'Budget',
  owner: 'Deal Owner',
  progress: 'Opportunity Progression',
  next_stage: 'Validate',
  one_step: 'One step',
  activities: 'Activity',
  information: 'Information',
  proposals: 'Proposals',
  documents: 'Documents',
  calendar_task: 'Calendar & Tasks',
  pipeline: 'Pipeline',
  contact: 'Contacts',
  catalog: 'Catalog',
  loading_msg: 'Loading... Please wait',
  no_data_table: 'There is no data to display in this table.',
  modified_by: 'Modified by',
  default_theme: 'Standard',
  immo_theme: 'Immobilier',
  header_field: 'Header field',
  theme: 'Theme',
  event_status: 'event status',
  active_since: 'Active since',
  data_updated: 'Change saved successfully',
  task_aded: 'Task added successfully',
  add_new_task: 'Add A Task',
  TaskTodo: 'Task to do',
  TaskDone: 'Task done',
  new_task: 'New Task',
  event_type: 'Event type',
  description: 'Description',
  color: 'Color',
  overdue: 'Overdue',
  task_edit: 'Task Edit',
  one_category_projet: 'Use project category only',
  dashboard: 'dashboard',
  assigned_to: 'Assigned to',
  action: 'Action',
  send: 'Send',
  confirmActionMsg: 'Are you sure you want to confirm this action',
  action_done: 'action completed successfully',
  project_id: 'Project',
  contact_id: 'Contact',
  proposal: 'Proposal',
  job: 'job title',
  nationality: 'nationality',
  location: 'location',
  email_address: 'email Address',
  phone_number: 'phone Number',
  lead_owner: 'Lead Owner',
  full_name: 'full name',
  edit_view: 'Edit View',
  write: 'write',
  to: 'To',
  add_another: 'Add another',
  invalid: 'Invalid',
  subject: 'Subject',
  created_by: 'Created by',
  send_by: 'Sent by',
  more: '+{nb} more',
  send_succes: 'sent',
  link: 'link',
  noResult: 'No results',
  log_activity: 'Log Activity',
  type_activity: 'type activité',
  cover: 'cover',
  message_file: 'you must choose a cover image',
  resource_disable: 'Disabled Resource',
  resource_enable: 'Enable Resource',
  resource: 'resource',
  msgValidEmail: 'Please enter a valid e-mail address.',
  to_send: 'to send by',
  update_succes: 'edited successfully',
  export_key: 'export',
  projet_disable: 'Inactive projects',
  projet_enable: 'active projects',
  proposal_disable: 'Disabled proposals',
  proposal_enable: 'Enable proposals',
  deleted_projet: 'Delete Project/Projects',
  create: 'Create',
  voir: 'Show',
  civility: 'civility',
  contact_Id: 'Contact Id',
  others: 'others',
  add_new_tag: 'Add new tag',
  msgPlaceholderCombobox: 'Select an option or create one',
  ownerId: 'Owner',
  update_stade: 'Update Stage',
  stage_status_list: 'Go to stage status',
  add_tag: 'Add Tag',
  new_tag: 'New Tag',
  update_key: 'Update',
  any: 'Any',
  delete_filter: 'delete filter',
  view_tag: 'View',
  discard_update: 'Discard update',
  discard: 'Discard',
  back: 'Back',
  discard_msg_tag: 'Are you sure you want to Discard the Tag Update on this activity?'


}
