import Axios from '@/axios'
import Precarite from '../../models/crm/Precarite'
import domain from '@/environment'
const state = {
  precariteError: null,
  precariteLoading: false,
  precarite: []
}
const getters = {
  getPrecariteError: state => state.precariteError,
  getPrecariteLoading: state => state.precariteLoading,
  getPrecarite: state => state.precarite
}
const mutations = {
  SET_PRECARITE_LOADING(state, payload = false) {
    state.precariteLoading = payload
  },
  SET_PRECARITE_ERROR(state, payload = null) {
    state.precariteError = payload
  },
  UPDATE_PRECARITE(state, payload) {
    payload.entity.update(payload.response)
    state.precarite = [...state.precarite]
  },
  SET_PRECARITE(state, payload) {
    state.precarite = payload.map(item => Precarite.create(item))
  }
}
const actions = {
  async updatePrecarite({ commit }, payload) {
    commit('SET_PRECARITE_LOADING', true)
    commit('SET_PRECARITE_ERROR')
    try {
      const response = await Axios.put(
        domain + '/api/crm/precarites/' + payload.entity.id,
        payload.data,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('UPDATE_PRECARITE', {
        entity: payload.entity,
        response: response.data.data
      })
      commit('SET_PRECARITE_LOADING')
    } catch (error) {
      commit('SET_PRECARITE_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PRECARITE_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PRECARITE_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async fetchPrecarite({ commit }) {
    commit('SET_PRECARITE_LOADING', true)
    commit('SET_PRECARITE_ERROR')

    try {
      const response = await Axios.get(domain + '/api/crm/precarites', {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        }
      })
      commit('SET_PRECARITE', response.data.data)
      commit('SET_PRECARITE_LOADING')
    } catch (error) {
      commit('SET_PRECARITE_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PRECARITE_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PRECARITE_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  }
}
export default {
  state,
  getters,
  mutations,
  actions
}
