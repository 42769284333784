import Entity from '../../Entity'
export default class EventCalendrier extends Entity {
  constructor(data) {
    super(data.id)
    this.name = data.name
    this.color = data.type.color
    this.detail = data.detail
    this.end = data.end
    this.end_Planing = new Date(data.end).getTime()
    this.end_time = data.end_time
    this.start = data.start
    this.start_Planing = new Date(data.start).getTime()
    this.start_time = data.start_time
    this.type = data.type
    this.timed = true
    this.fields = data.fields
    this.Projet = data.Projet
    this.projet_id = data.projet_id
    this.status = data.status
    this.assigned_user_id = data.assigned_user_id
    this.assigned_user = data.assigned_user

  }

  /**
   * @param {*} data
   */
  static create(data) {
    if (!data) {
      throw new Error('Event Calendrier::create: "data" is undefined')
    }
    return new EventCalendrier(data)
  }
  updateEventCalendrierr(data) {
    this.name = data.name
    this.detail = data.detail
    this.end_date = data.end_date
    this.end_time = data.end_time
    this.start_date = data.start_date
    this.start_time = data.start_time
    this.end = data.end
    this.end_Planing = new Date(data.end).getTime()
    this.start = data.start
    this.start_Planing = new Date(data.start).getTime()
    this.type = data.type
    this.fields = data.fields
    this.color = data.type.color 
    this.projet_id = data.Projet
    this.projet_id = data.projet_id
    this.status = data.status
    this.assigned_user_id = data.assigned_user_id
    this.assigned_user = data.assigned_user


  }
}
