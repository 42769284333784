export default {
  'Exception inattendue. Essayer plus tard':
    'Exception inattendue. Essayer plus tard',
  // sidebar crm
  entreprise: 'ENTREPRISE',
  titleEntrepise: 'GENERALE FRANCE ENERGIE',
  // Modules
  dashbord: 'Tableau de bord',
  businessMap: 'Business Map',
  marketplace: 'Marketplace',
  bibliothèque: 'Bibliothèque',
  gestionTache: 'Gestion des taches',
  calendrierPartage: 'Calendrier partagé',
  tâches: 'Tâches',
  lieudetravail: 'lieu de travail',
  inbox: 'Inbox',
  baseConnaissances: 'Base de connaissances',
  annuairePro: 'Annuaire pro',
  actualites: 'Actualités',
  crmIla26: 'CRM Ila 26',
  remontées: 'Remontées',
  immobilier: 'Immobilier',
  TaskTodo: 'Tâche à faire',
  TaskDone: 'Tâche terminée',
  // PERSONNELLE
  personnelle: 'PERSONNELLE',
  persoLite: 'Perso Lite',
  // PARAMÈTRES
  parametres: 'Paramètres',
  demandeSupport: 'Demande de support',
  monProfil: 'Mon Profil',
  // dashbord
  close: 'Fermer',
  textSidebar: 'Retrouvez les contacts de vos collègues et plus encore !',
  // Menu crm
  planning: 'Planning',
  sav: 'SAV',
  typeProjet: 'Type de projet',
  servicesClients: 'Services clients',
  search: 'Rechercher',
  searchMsg: 'Rechercher {msg}',
  projet: 'Projets',
  aucun: 'Aucun',
  // Search
  aucunResultat: 'résultat pour',
  Ouvert: 'Ouvert',
  Perdu: 'Perdu',
  Conclu: 'Conclu',
  // settings entity
  gestionEntite: 'Gestion des entités',
  users: 'Gérer les utilisateurs',
  historyUsers: "Historique de l'utilisateur",
  equipe: 'équipes',
  group: 'Groupe',
  depot: 'dépôts',
  visibility: 'Visibilités',
  connexions: 'Connexions',
  gestionProjet: 'Gestion des projets',
  typesProjets: 'Types des projets',
  customFields: 'Champs personnalisés',
  categories: 'Catégories',
  pipelines: 'Pipelines',
  resources: 'Ressource',
  evenement: 'événements',
  sources: 'Sources',
  precarite: 'Précarités',
  visibilityProjet: 'Visibilités des projets',
  template: 'Templates',
  webhooks: 'Webhooks',
  apiToken: 'API token',
  history: 'Historique',
  exportExcel: 'export excel',
  // users
  utilisateurs: 'Utilisateurs',
  configUser: {
    name: 'utilisateur',
    active: 'Actifs',
    desactive: 'Désactivés',
    export: 'Export'
  },
  autorisations: 'autorisations',
  configPermission: {
    name: 'autorisation'
  },
  corbeille: 'corbeil',
  add: 'Ajouter {msg}',
  update: 'Modifier',
  deleted: 'Supprimer',
  active: 'Activer',
  diabled: 'Désactiver',
  renommer: 'Renommer',
  loading: 'Chargement en cours...',
  btnCancel: 'Annuler',
  btnValider: 'Valider',
  btnAdd: 'Ajouter',
  btnDetail: 'Détails',
  btnEnregistre: 'Enregistrer',
  btnRestore: 'Restaurer',
  btnDeleteDefinitive: 'Supprimer définitivement',
  msgdeleted: 'Êtes-vous certain de vouloir supprimer {msg}',
  msgDesactive: 'Êtes-vous certain de vouloir désactiver {msg}',
  ensemble: 'Ensembles',
  // Fields
  msgOblg: 'est obligatoire',
  msgObligMsg: '{msg} est obligatoire',
  noDataOption: 'Aucune option trouvée',
  noDataOptionMsg: 'Aucune {option} trouvée',
  msgSelectDate: 'Sélectionner la date',
  // visibility Projet
  name: 'Nom',
  object: 'Objet',
  champ: 'Champs',
  operator: 'Opérateurs',
  date: 'Date',
  value: 'Valeur',
  // Template
  title: 'Titre',
  message: 'Message',
  copy: 'Copier',
  view: 'Visualiser',
  // users
  first_name: 'Prénom',
  phone: 'Téléphone',
  zip_code: 'Code postal',
  password: 'Mot de passe',
  confirm_password: 'Confirmation de mot de passe',
  company: 'Société',
  Superior: 'Supérieur',
  team_manager: "chef d'équipe",
  department: 'Département',
  commercial_sedentaire: 'Commercial sédentaire',
  categoriesStage: 'Catégorie de stade',
  stade: 'Stade',
  adresse: 'Adresse',
  ville: 'Ville',
  sous_categories: 'Sous catégorie',
  category: 'Catégorie',
  sousType: 'Sous type',
  statut: 'Statut',
  selectionner: 'Sélectionnez',
  filtre: 'Filtre',
  telecharger: 'Télécharger',
  champObligatoire: 'Ce champ est obligatoire',
  suivant: 'Suivant',
  precedant: 'Précédent',
  choose: 'Choisir',
  etape: 'Etape',
  date_debut: 'Date de début',
  date_fin: 'Date de fin',
  designation: 'désignation',
  //projet immobilier
  total: 'Totale',
  all: 'Tout',
  note: 'Note',
  email: 'Email',
  call: 'Appel',
  task: 'Tâche',
  meetting: 'Réunion',
  assign: 'Attribuer',
  assign_owner: 'Attribuer',
  opportunity: 'opportunité',
  budget: 'Budget',
  owner: 'Propriétaire',
  progress: 'progression de projet',
  next_stage: 'Valider',
  one_step: 'Une seule etape',
  activities: 'Activités',
  information: 'Information',
  proposals: 'Propositions',
  documents: 'Documents',
  calendar_task: 'Calendrier',
  pipeline: 'Pipeline',
  contact: 'Contacts',
  catalog: 'Catalogue',
  loading_msg: 'Chargement... Veuillez patienter',
  no_data_table: "Il n'y a aucune donnée à afficher dans ce tableau.",
  modified_by: 'Modifié par',
  default_theme: 'Standard',
  immo_theme: 'Immobilier',
  header_field: 'Champ entête',
  theme: 'Thème',
  event_status: 'statut événement',
  active_since: 'Actif depuis',
  data_updated: 'Modification enregistrée avec succès',
  task_aded: 'Tâche ajoutée avec succès',
  add_new_task: 'Ajouter une tâche',
  new_task: 'Nouvelle tâche',
  event_type: 'type événement',
  description: 'Description',
  color: 'Couleur',
  overdue: 'En retard',
  task_edit: 'Modifier la tâche',
  one_category_projet: 'Utiliser uniquement la catégorie projet',
  dashboard: 'tableau de bord',
  assigned_to: 'Assigné à',
  action: 'Action',
  send: 'Envoyer',
  confirmActionMsg: 'Êtes-vous sûr de vouloir confirmer cette action',
  action_done: 'Action terminée avec succès',
  project_id: 'Projet',
  contact_id: 'Contact',
  proposal: 'Proposition',
  job: 'Titre du poste',
  nationality: 'nationalité',
  location: 'localisation',
  email_address: 'adresse électronique',
  phone_number: 'numéro de téléphone',
  lead_owner: 'Lead Owner',
  full_name: 'nom et prénom',
  edit_view: 'Modifier vue',
  write: 'écrire',
  to: 'à',
  add_another: 'Ajouter un autre',
  invalid: 'Invalide',
  subject: 'Objet',
  created_by: 'Crée par',
  send_by: 'envoyé par',
  more: '+{nb} autre(s)',
  send_succes: 'envoyé',
  link: 'Lien',
  noResult: 'aucun résultat',
  log_activity: 'activité journal',
  type_activity: 'activity type',
  cover: 'couverture',
  message_file: 'vous devez choisir une image de couverture',
  resource_disable: 'Ressources Désactivés',
  resource_enable: 'Ressources Actifs',
  resource: 'ressource',
  msgValidEmail: 'Veuillez saisir une adresse e-mail valide.',
  to_send: 'à envoyer par',
  update_succes: 'modifié avec succès',
  export_key: 'export',
  projet_disable: 'Projets Désactivés',
  projet_enable: 'Projets activé',
  proposal_disable: 'propositions désactivées',
  proposal_enable: 'propositions activées',
  deleted_projet: 'Supprimer Projet/Projets',
  create: 'Créer',
  voir: 'Voir',
  civility: 'civilité',
  contact_Id: 'Contact Id',
  others: 'autres',
  add_new_tag: 'Ajouter un nouveau tag',
  msgPlaceholderCombobox: 'Sélectionner une option ou en créer une',
  ownerId: 'Propriétaire',
  update_stade: 'Modifier  Stade',
  stage_status_list: 'Accéder au Statut de stade',
  add_tag: 'Ajouter une étiquette',
  update_key: 'Modifier',
  new_tag: 'Nouvelle étiquette',
  any: 'Tous',
  delete_filter: 'supprimer le filtre',
  view_tag: 'View',
  discard_update: 'Supprimer la mise à jour',
  discard: 'Abandonner',
  back: 'Retourner',
  discard_msg_tag: 'Êtes-vous sûr de vouloir ignorer la mise à jour des tags ?'

}
