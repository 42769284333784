import Entity from '../../Entity'
import EtapesSubStage from './EtapesSubStage'

export default class EtapesStage extends Entity {
  constructor(data) {
    super(data.id)
    this.name = data.name
    this.order = data.order
    this.has_email = data.has_email
    this.has_sms = data.has_sms
    this.projectsCount = null
    this.projets_count = data.projets_count
    this.flech = false
    this.subStages = []
    if (data.csubstages && data.csubstages.data) {
      this.subStages = data.csubstages.data.map(s => EtapesSubStage.create(s))
    }
  }

  /**
   * @param { id, name, order, status, projets_count, csubstages } data
   */
  static create(data) {
    if (!data) {
      throw new Error('EtapesStage::create: "data" is undefined')
    }
    return new EtapesStage(data)
  }

  updateStage(data) {
    this.name = data.name
    this.order = data.order
    this.has_email = data.has_email
    this.has_sms = data.has_sms
    this.projectsCount = data.projets_count
    this.projets_count = data.projets_count
    if (data.csubstages && data.csubstages.data) {
      this.subStages = data.csubstages.data.map(s => EtapesSubStage.create(s))
    }
  }
}
