import Axios from 'axios'
import Project from '../../models/crm/Project'
import Activity from '../../models/crm/Activity'
import History from '../../models/crm/History'
import Comment from '../../models/crm/Comment'
import TypesPixel from '../../models/crm/TypesPixel'
import File from '../../models/crm/File'
import domain from '@/environment'
import StageTH from '../../models/crm/StageTH'
let requestDataImmo = null

import moment from 'moment'

const requests = {
  projects: null,
  projectDependencies: {
    activities: null,
    history: null,
    journal: null,
    files: null,
    comments: null
  },
  exportExcel: null,
  exportExcelTh: null
}

const state = {
  countPaginationdFile: 0,
  contact: null,
  error: null,
  createError: null,
  processing: false,
  exportProcessing: false,
  uploadProcessing: false,
  projects: [],
  projectsContact: [],
  extraProject: null,
  lastCreatedProject: null,
  projectFields: [],
  contactFields: [],
  // NOVELLE TABLE DYNAMIC
  headerTable: [],
  ListeEditView: [],
  projectstotalRowsTableBoard: 1,

  // users of project
  usersCommercialSedentaire: [],
  usersCommercialSedentaireLoading: false,
  usersCommercialsIte: [],
  usersCommercialsIteLoading: false,
  usersRespPlan: [],
  usersRespPlanLoading: false,
  usersTechniciens: [],
  usersTechniciensLoading: false,

  suggestionsProcessing: false,
  comments: [],
  projectsCommentstotalRows: 1,
  projectsActivitytotalRows: 1,
  projectsJournaltotalRows: 1,
  projectsHistoriquetotalRows: 1,
  files: [],
  filesProcessing: false,
  processingHistory: false,
  sourcesProcessing: false,
  commentProcessing: false,
  source: [],
  campagneProcessing: false,
  campagne: [],
  campagneSource: [],
  categories: [],
  projectstotalRowsType: 1,
  projectstotalRowsStade: 1,
  projectstotalRows: 1,
  projectstotalRowsTH: 1,
  projectCountProcessing: true,
  depots: [],
  depotsCommercialTerrain: [],
  depotsTechnicien: [],
  depotsPrevisiteur: [],
  depotsProcessing: false,
  filters: [],
  routeDynamic: [],
  routeDynamicProcessing: false,
  routeDynamicProjectTH: [],
  routeDynamicProcessingProjectTH: false,
  commSedNotDeleted: [],
  commSedNotDeletedProcessing: false,
  previsiteurNotDeleted: [],
  previsiteurNotDeletedProcessing: false,
  poseurNotDeleted: [],
  poseurNotDeletedProcessing: false,
  commTerrainNotDeleted: [],
  commTerrainNotDeletedProcessing: false,
  sources: [],
  sourceProcessing: false,
  respPlanningNotDeleted: [],
  respPlanningNotDeletedProcessing: false,
  typePixel: [],
  typePixelLoading: false,
  historyInjectionPixel: [],
  historyInjectionPixelLoading: false,
  sousTypes: [],
  commentDynamicProjectTh: [],
  changePageValue: false,
  objectPrj: null,
  tableStadeGlobalTH: [],
  fieldsProjetMasse: [],
  fieldsAddProjetImmo: [],
  loaderExportExcelProjectsImmo: false
}
const getters = {
  // Count de pagination 
  fileCount(state) {
    return state.files.length;
  },
  //  COunt de pagination revien de l'api Get
  countPaginationdFile : state => state.countPaginationdFile,

  getProjectsContact: state => state.contact,
  getProjectsError: state => state.error,
  getProjectsCreateError: state => state.createError,
  getProjectsProcessing: state => state.processing,
  getProjectsExportProcessing: state => state.exportProcessing,
  getProjectsUploadProcessing: state => state.uploadProcessing,
  getProjectCountProcessing: state => state.projectCountProcessing,
  getProjectsProcessingHistory: state => state.processingHistory,
  getHeaderTable: state => state.headerTable,
  getListEditView: state => state.ListeEditView,
  getTableBoard: state => state.projects,
  getProjectsTotalRowsTableBoard: state => state.projectstotalRowsTableBoard,
  getProjectsExtraProject: state => state.extraProject,
  getProjectsLastCreated: state => state.lastCreatedProject,
  getProjectsProjectFields: state => state.projectFields,
  getProjectsContactFields: state => state.contactFields,
  getProjectsComments: state => state.comments,
  getProjectsCommentstotalRows: state => state.projectsCommentstotalRows,
  getProjectsActivitytotalRows: state => state.projectsActivitytotalRows,
  getProjectsJournaltotalRows: state => state.projectsJournaltotalRows,
  getProjectsHistoriquetotalRows: state => state.projectsHistoriquetotalRows,
  getProjectsFiles: state => state.files,
  getProjectsFilesProcessing: state => state.filesProcessing,
  getProjectsFieldsProjetMasse: state => state.fieldsProjetMasse,
  // Projects users getters
  getProjectsUsersCommercialSedentaireLoading: state =>
    state.usersCommercialSedentaireLoading,
  getProjectsUsersCommercialsIte: state => state.usersCommercialsIte,
  getProjectsUsersCommercialsIteLoading: state =>
    state.usersCommercialsIteLoading,
  getProjectsUsersRespPlan: state => state.usersRespPlan,
  getProjectsUsersRespPlanLoading: state => state.usersRespPlanLoading,
  getProjectsUsersTechniciens: state => state.usersTechniciens,
  getProjectsUsersTechniciensLoading: state => state.usersTechniciensLoading,

  // users types combined [userAdmin and userAgent and userAdministration]
  getProjectsUsersCommercialsSedentaires: state =>
    state.usersCommercialSedentaire,
  getProjectsUsers: state =>
    state.usersCommercialSedentaire.concat(
      state.usersCommercialsIte,
      state.usersRespPlan,
      state.usersTechniciens
    ),
  getProjectsUsersLoading: state =>
    state.usersCommercialSedentaireLoading ||
    state.usersCommercialsIteLoading ||
    state.usersRespPlanLoading ||
    state.usersTechniciensLoading,

  getProjectsSuggestionsProcessing: state => state.suggestionsProcessing,
  getProjectsSources: state => state.source,
  getProjectsSourcesProcessing: state => state.sourcesProcessing,
  getProjectsCampagnes: state => state.campagne,
  getProjectsCampagnesSources: state => state.campagneSource,
  getProjectsCampagnesProcessing: state => state.campagneProcessing,
  getProjectsCategories: state => state.categories,
  getProjectsDepots: state => state.depots,
  getProjectsDepotsCommercialTerrain: state => state.depotsCommercialTerrain,
  getProjectsDepotsTechnicien: state => state.depotsTechnicien,
  getProjectsDepotsPrevisiteur: state => state.depotsPrevisiteur,
  getProjectsDepotsProcessing: state => state.depotsProcessing,
  getProjectstotalRows: state => state.projectstotalRows,

  getProjectstotalRowsTH: state => state.projectstotalRowsTH,
  getProjectstotalRowsType: state => state.projectstotalRowsType,
  getProjectstotalRowsStade: state => state.projectstotalRowsStade,
  getSaveFilters: state => state.filters,
  getProjectRouteDynamic: state => state.routeDynamic,
  getRouteDynamicProcessing: state => state.routeDynamicProcessing,
  getProjectsUsersCommercialSedNotDeleted: state => state.commSedNotDeleted,
  getProjectsUsersCommercialSedNotDeletedProcessing: state =>
    state.commSedNotDeletedProcessing,
  getProjectsUsersPrevisiteurNotDeleted: state => state.previsiteurNotDeleted,
  getProjectsUsersPrevisiteurNotDeletedLoading: state =>
    state.previsiteurNotDeletedProcessing,
  getProjectsUsersPoseursNotDeleted: state => state.poseurNotDeleted,
  getProjectsUsersPoseursNotDeletedProcessing: state =>
    state.poseurNotDeletedProcessing,
  getProjectsUsersCommTerrainNotDeleted: state => state.commTerrainNotDeleted,
  getProjectsUsersCommTerrainNotDeletedProcessing: state =>
    state.commTerrainNotDeletedProcessing,
  getProjectsUsersNotDeleted: state =>
    state.commSedNotDeleted.concat(
      state.previsiteurNotDeleted,
      state.respPlanningNotDeleted,
      state.poseurNotDeleted
    ),
  getProjectsUsersNotDeletedLoading: state =>
    state.commSedNotDeletedProcessing ||
    state.previsiteurNotDeletedProcessing ||
    state.respPlanningNotDeletedProcessing ||
    state.poseurNotDeletedProcessing,
  getProjectsUsersSources: state => state.sources,
  getProjectsUsersSourcesProcessing: state => state.sourceProcessing,
  getProjectsUsersRespPlanningNotDeleted: state => state.respPlanningNotDeleted,
  getProjectsUsersRespPlanningNotDeletedLoading: state =>
    state.respPlanningNotDeletedProcessing,
  getProjectsTypesPixels: state => state.typePixel,
  getProjectsTypesPixelsLoading: state => state.typePixelLoading,
  getProjectshistoryInjectionPixel: state => state.historyInjectionPixel,
  getProjectshistoryInjectionPixelLoading: state =>
    state.historyInjectionPixelLoading,
  getProjectsSousTypes: state => state.sousTypes,
  //
  getProjectRouteDynamicProjectTH: state => state.routeDynamicProjectTH,
  getRouteDynamicProcessingProjectTH: state =>
    state.routeDynamicProcessingProjectTH,
  getRouteDynamicCommentProjectTH: state => state.commentDynamicProjectTh,
  getCommentProcessingProjectTH: state => state.commentProcessing,
  getChangePageValue: state => state.changePageValue,
  getObjectPrj: state => state.objectPrj,
  getTableStadeGlobalTH: state => state.tableStadeGlobalTH,
  getFieldsAddProjetImmo: state => state.fieldsAddProjetImmo,
  getLoaderExportExcelProjectsImmo: state => state.loaderExportExcelProjectsImmo
}

//**********************************************************************************Mutation *****************************************************************************/

const mutations = {

  SET_File_META(state, payload) {
    //  PAGINATION
    if (payload && payload.pagination) {
      state.countPaginationdFile = payload.pagination.total
    } else {
      state.countPaginationdFile = 0
    }
  },
  SET_PROJECTS_PROCESSING(state, payload = false) {
    state.processing = payload
  },
  SET_PROJECTS_UPLOAD_EXCEL_PROCESSING(state, payload = false) {
    state.uploadProcessing = payload
  },
  SET_PROJECTS_EXPORT_PROCESSING(state, payload = false) {
    state.exportProcessing = payload
  },
  SET_PROJECTS_COUNT_PROCESSING(state, payload = false) {
    state.projectCountProcessing = payload
  },
  SET_PROJECTS_HISTORY_PROCESSING(state, payload = false) {
    state.processingHistory = payload
  },
  SET_PROJECTS_ERROR(state, payload = null) {
    state.error = payload
  },
  SET_PROJECTS_CREATE_ERROR(state, payload = null) {
    state.createError = payload
  },
  FETCH_HEADER_COLUMN(state, payload) {
    state.headerTable = payload
  },
  // Fetsh List Edit View
  FETCH_LISTE_EDIT_VIEW(state, payload) {
    state.ListeEditView = payload
  },

  // Set Update View Tableau (Post)
  SET_UPDATE_VIEW(state, payload) {
    state.ListeEditView = state.ListeEditView.map(item => {
      if (item.id == payload.field_id) {
        item.visibility = payload.visibility
      }
      return item
    })
  },
  FETCH_TABLE_BOARD(state, payload) {
    state.projects = payload
  },
  SET_PROJECTS_TABLE_BOARD_META(state, payload) {
    if (payload && payload.total) {
      state.projectstotalRowsTableBoard = payload.total
    } else {
      state.projectstotalRowsTableBoard = 0
    }
  },
  ADD_PROJECTS_EXTRA(state, { data, checked, id }) {
    if (data.header) {
      data.header.checked = checked
    } else {
      data.checked = checked
    }
    data.id = id
    state.contact = data.body.projet.contact

    state.extraProject = Project.create(data)
  },
  REFRESH_PROJECTS_EXTRA(state) {
    state.extraProject = null
  },
  ADD_PROJECTS_PROJECT(state, payload) {
    const objet = new Project(payload.response)
    if (payload.currentType.id != 112) {
      objet.formatedObjet(payload.response)
    }
    if (payload.id_dtype === payload.currentType.id) {
      state.projects.unshift(objet)
    }
    state.lastCreatedProject = objet
  },
  UPDATE_PROJECTS_PROJECT(state, payload) {
    payload.entity.update(payload.response, {
      projectFields: state.projectFields,
      contactFields: state.contactFields
    })
    // for (let index = 0; index < this.state.projects.projects.length; index++) {
    //   const projet = this.state.projects.projects[index]
    //   if (projet.id === payload.entity.id) {
    //     this.state.projects.projects[index].update(payload.response)
    //   }
    // }
    if (payload.checked === false) {
      payload.entity.checked = false
    } else {
      payload.entity.checked = true
    }
  },
  UPDATE_PROJECTS_MULTIPLE(state, payload) {
    payload.projects.forEach(item => {
      const project = payload.response.find(el => {
        return el.static.id == item.id
      })
      if (project) {
        item.update(project, {
          projectFields: state.projectFields,
          contactFields: state.contactFields
        })
      }
    })
  },
  DELETE_PROJECTS_MULTIPLE(state, payload) {
    payload.projects.forEach(project => {
      state.projects = state.projects.filter(item => item.id != project.id)
    })
  },
  REFRECH_PROJECTS_PROJECT(state, payload) {
    payload.project.update(payload.response)
  },
  REMOVE_PROJECTS_PROJECT(state, payload) {
    payload.checked = false
    if (state.extraProject && state.extraProject.id == payload.id) {
      state.extraProject = null
    }
    state.projects = state.projects.filter(item => item.id != payload.id)
  },
  // PROJECT TH
  REMOVE_PROJECT_TH(state, payload) {
    state.projects = state.projects.filter(item => item.id != payload.id)
  },
  UPDATE_PROJECT_TH(state, payload) {
    payload.entity.update(payload.response)
  },
  SET_COMMENT_DYNAMIC_PROJECT_TH(state, payload) {
    state.commentDynamicProjectTh = payload.response
  },
  SET_ADD_COMMENT_PROJECT_TH(state, payload) {
    state.commentDynamicProjectTh.unshift(payload.response)
    payload.project.ecs_comments =
      payload.response.ecs_comments == 1 ? 1 : payload.project.ecs_comments
    payload.project.rr_comments =
      payload.response.rr_comments == 1 ? 1 : payload.project.rr_comments
    payload.project.ro_comments =
      payload.response.ro_comments == 1 ? 1 : payload.project.ro_comments
    payload.project.s_comments =
      payload.response.s_comments == 1 ? 1 : payload.project.s_comments
    payload.project.d_comments =
      payload.response.d_comments == 1 ? 1 : payload.project.d_comments
    payload.project.p_comments =
      payload.response.p_comments == 1 ? 1 : payload.project.p_comments
    payload.project.r_comments =
      payload.response.r_comments == 1 ? 1 : payload.project.r_comments
    payload.project.m_comments =
      payload.response.m_comments == 1 ? 1 : payload.project.m_comments
    payload.project.ite_comments =
      payload.response.ite_comments == 1 ? 1 : payload.project.ite_comments
    payload.project.vmc_comments =
      payload.response.vmc_comments == 1 ? 1 : payload.project.vmc_comments
    payload.project.cofrac_comments =
      payload.response.cofrac_comments == 1
        ? 1
        : payload.project.cofrac_comments
    payload.project.nom_client_comments =
      payload.response.nom_client_comments == 1
        ? 1
        : payload.project.nom_client_comments
  },
  REMOVE_COMMENT_PROJECT_TH(state, payload) {
    state.commentDynamicProjectTh = state.commentDynamicProjectTh.filter(
      item => item.id != payload.response.id
    )
    payload.project.ecs_comments =
      payload.response.ecs_comments == 0 ? 0 : payload.project.ecs_comments

    payload.project.rr_comments =
      payload.response.rr_comments == 0 ? 0 : payload.project.rr_comments

    payload.project.ro_comments =
      payload.response.ro_comments == 0 ? 0 : payload.project.ro_comments

    payload.project.s_comments =
      payload.response.s_comments == 0 ? 0 : payload.project.s_comments

    payload.project.d_comments =
      payload.response.d_comments == 0 ? 0 : payload.project.d_comments

    payload.project.p_comments =
      payload.response.p_comments == 0 ? 0 : payload.project.p_comments

    payload.project.r_comments =
      payload.response.r_comments == 0 ? 0 : payload.project.r_comments

    payload.project.m_comments =
      payload.response.m_comments == 0 ? 0 : payload.project.m_comments

    payload.project.ite_comments =
      payload.response.ite_comments == 0 ? 0 : payload.project.ite_comments

    payload.project.vmc_comments =
      payload.response.vmc_comments == 0 ? 0 : payload.project.vmc_comments

    payload.project.cofrac_comments =
      payload.response.cofrac_comments == 0
        ? 0
        : payload.project.cofrac_comments

    payload.project.nom_client_comments =
      payload.response.nom_client_comments == 0
        ? 0
        : payload.project.nom_client_comments
  },
  // Project users
  SET_PROJECTS_USERS_COMMERCIAL_SEDENTAIRE(state, payload) {
    state.usersCommercialSedentaire = payload
  },
  SET_PROJECTS_USERS_COMMERCIAL_SEDENTAIRE_LOADING(state, payload = false) {
    state.usersCommercialSedentaireLoading = payload
  },
  SET_PROJECTS_USERS_COMMERCIALS_ITE(state, payload) {
    state.usersCommercialsIte = payload
  },
  SET_PROJECTS_USERS_COMMERCIALS_ITE_LOADING(state, payload = false) {
    state.usersCommercialsIteLoading = payload
  },
  SET_PROJECTS_USERS_RESP_PLAN(state, payload) {
    state.usersRespPlan = payload
  },
  SET_PROJECTS_USERS_RESP_PLAN_LOADING(state, payload = false) {
    state.usersRespPlanLoading = payload
  },
  SET_PROJECTS_USERS_TECHNICIENS(state, payload) {
    state.usersTechniciens = payload
  },
  SET_PROJECTS_USERS_TECHNICIENS_LOADING(state, payload = false) {
    state.usersTechniciensLoading = payload
  },

  SET_PROJECTS_SOURCE_PROCESSING(state, payload = false) {
    state.sourcesProcessing = payload
  },
  SET_PROJECTS_CAMPAGNE_PROCESSING(state, payload = false) {
    state.campagneProcessing = payload
  },
  SET_PROJECTS_CONTACT_FIELDS(state, payload) {
    state.contactFields = payload
  },
  SET_PROJECTS_PROJECT_FIELDS(state, payload) {
    state.projectFields = payload
  },
  SET_PROJECTS_SUGGESTIONS_PROCESSING(state, payload = false) {
    state.suggestionsProcessing = payload
  },
  ADD_PROJECT_ACTIVITY(state, payload) {
    if (!payload.project.activities) {
      payload.project.activities = []
    }
    payload.project.activities.unshift(Activity.create(payload.payload))
    if (payload.payload.terminer) {
      payload.project.timeline.unshift(Activity.create(payload.payload))
    }
  },
  SET_PROJECT_ACTIVITY(state, payload) {
    if (payload.reset && payload.reset === 1) {
      payload.activity.activities = []
    }
    if (payload.activity.activities && payload.activity.activities.length) {
      payload.activity.activities = payload.activity.activities.filter(t => {
        return t.terminer == 1
      })
      payload.response.map(item =>
        payload.activity.activities.push(Activity.create(item))
      )
    } else {
      payload.activity.activities = payload.response.map(item =>
        Activity.create(item)
      )
    }
    if (payload.pagination && payload.pagination.total) {
      state.projectsActivitytotalRows = payload.pagination.total
    }
  },
  UPDATE_PROJECT_ACTIVITY(state, payload) {
    //activities
    const index = payload.project.activities.findIndex(e => {
      return e.id == payload.activity.id
    })
    if (index > -1) {
      payload.project.activities[index].updateActivity(payload.response)
    }
    //timeline
    if (payload.project) {
      const index = payload.project.timeline.findIndex(e => {
        return e.object == 'Activity' && e.id == payload.activity.id
      })
      if (index > -1) {
        payload.project.timeline[index].updateActivity(payload.response)
      }
      //
      if (payload.toggle) {
        if (payload.response.terminer) {
          payload.project.timeline.unshift(Activity.create(payload.response))
        } else {
          const index = payload.project.timeline.findIndex(e => {
            return e.object == 'Activity' && e.id == payload.activity.id
          })
          if (index > -1) {
            payload.project.timeline.splice(index, 1)
          }
        }
      }
    }
  },
  REMOVE_PROJECT_ACTIVITY(state, payload) {
    payload.project.activities = payload.project.activities.filter(activity => {
      return activity.id != payload.activity.id
    })
    if (payload.project && payload.project.timeline) {
      payload.project.timeline = payload.project.timeline.filter(item => {
        return item.object != 'Activity' || item.id != payload.activity.id
      })
    }
  },
  SET_PROJECT_JOURNAL(state, payload) {
    if (payload.reset && payload.reset === 1) {
      payload.project.journals = []
    }
    if (
      payload &&
      payload.response &&
      payload.response.length &&
      payload.project &&
      payload.project.journals
    ) {
      payload.response.map(item =>
        payload.project.journals.push(History.create(item))
      )
    }
    if (payload.pagination && payload.pagination.total) {
      state.projectsJournaltotalRows = payload.pagination.total
    }
  },
  SET_PROJECT_COMMENTS(state, payload) {
    if (payload.reset && payload.reset === 1) {
      payload.project.comments = []
    }
    if (
      payload &&
      payload.response &&
      payload.response.length &&
      payload.project &&
      payload.project.comments
    ) {
      payload.response.map(item =>
        payload.project.comments.push(Comment.create(item))
      )
    }
    if (payload.pagination && payload.pagination.total) {
      state.projectsCommentstotalRows = payload.pagination.total
    }
  },
  ADD_PROJECTS_COMMENTS(state, payload) {
    payload.project.comments.unshift(Comment.create(payload.response))
    payload.project.timeline.unshift(Comment.create(payload.response))
  },
  SET_PROJECT_ADD_FILES(state, payload) {
    state.files = state.files.concat(payload.response.map(f => File.create(f)))
    if (payload.response && Array.isArray(payload.response)) {
      payload.response.forEach(file => {
        payload.project.files.unshift(File.create(file))
        payload.project.timeline.unshift(File.create(file))
        
      })
    }
  },
  SET_PROJECT_HISTORIQUE(state, payload) {
    if (payload.reset && payload.reset === 1) {
      payload.project.timeline = []
    }
    if (
      payload &&
      payload.response &&
      payload.response.length &&
      payload.project.timeline &&
      payload.project
    ) {
      payload.response.map(item => {
        if (item.object == 'ChangeProjet') {
          payload.project.timeline.push(History.create(item))
        }
        if (item.object == 'Fichier') {
          payload.project.timeline.push(File.create(item))
        }
        if (item.object == 'Comment') {
          payload.project.timeline.push(Comment.create(item))
        }
        if (item.object == 'Activity') {
          payload.project.timeline.push(Activity.create(item))
        }
      })
    }
    if (payload.pagination && payload.pagination.total) {
      state.projectsHistoriquetotalRows = payload.pagination.total
    }
  },
  SET_PROJECT_FILES(state, payload) {
    payload.project.files = payload.response.map(item => File.create(item))
    state.files = payload.response.map(item => File.create(item))
  },
  SET_PROJECT_FILES_PROCESSING(state, payload = false) {
    state.filesProcessing = payload
  },
  UPDATE_PROJECT_FILE(state, payload) {
    // files
    const index = payload.project.files.findIndex(e => {
      return e.id == payload.file.id
    })
    if (index > -1) {
      payload.project.files[index].updateFile(payload.response)
    }

    // timeline
    const indexTimeline = payload.project.timeline.findIndex(e => {
      return e.object == 'Fichier' && e.id == payload.file.id
    })
    if (index > -1) {
      payload.project.timeline[indexTimeline].updateFile(payload.response)
    }
  },
  REMOVE_PROJECTS_FILE(state, payload) {
    state.files = state.files.filter(file => {
      return file.id != payload.file.id
    })

    payload.project.files = payload.project.files.filter(file => {
      return file.id != payload.file.id
    })
    if (payload.project.timeline) {
      payload.project.timeline = payload.project.timeline.filter(item => {
        return item.id != payload.file.id
      })
    }
  },
  SET_PROJECT_FILES_CATEGORIES(state, payload) {
    state.categories = payload
  },
  UPDATE_PROJECT_NOTE(state, payload) {
    // comments
    const index = payload.project.comments.findIndex(e => {
      return e.id == payload.comment.id
    })
    if (index > -1) {
      payload.project.comments[index].updateNote(payload.response)
    }

    // timeline
    const indexTimeline = payload.project.timeline.findIndex(e => {
      return e.object == 'Comment' && e.id == payload.comment.id
    })
    if (indexTimeline > -1 && index > -1) {
      payload.project.timeline[indexTimeline].updateNote(payload.response)
    }
  },
  REMOVE_PROJECT_NOTE(state, payload) {
    payload.project.comments = payload.project.comments.filter(comment => {
      return comment.id != payload.comment.id
    })

    if (payload.project.timeline) {
      payload.project.timeline = payload.project.timeline.filter(item => {
        return item.object != 'Comment' || item.id != payload.comment.id
      })
    }
  },
  SET_PROJECTS_SOURCE(state, payload) {
    state.source = payload
  },
  SET_PROJECTS_CAMPAGNE(state, payload) {
    state.campagne = payload
  },
  SET_PROJECTS_CAMPAGNE_SOURCES(state, payload) {
    state.campagneSource[payload.source.text] = payload.response.map(u => {
      return { text: u.full_name, value: u.id }
    })
  },
  SET_PROJECTS_DEPOTS(state, payload) {
    state.depots = payload
  },
  SET_PROJECTS_DEPOTS_COMMERCIAL_TERRAIN(state, payload) {
    state.depotsCommercialTerrain[payload.depot.text] = payload.response.map(
      u => {
        return { text: u.full_name, value: u.id }
      }
    )
  },
  SET_PROJECTS_DEPOTS_TECHNICIEN(state, payload) {
    state.depotsTechnicien[payload.depot.text] = payload.response.map(u => {
      return { text: u.full_name, value: u.id }
    })
  },
  SET_PROJECTS_DEPOTS_PREVISITEUR(state, payload) {
    state.depotsPrevisiteur[payload.depot.text] = payload.response.map(u => {
      return { text: u.full_name, value: u.id }
    })
  },

  SET_PROJECTS_DEPOTS_PROCESSING(state, payload = false) {
    state.depotsProcessing = payload
  },
  SET_PROJECTS_META(state, payload) {
    if (
      payload.response &&
      payload.response.pagination &&
      payload.response.pagination.total
    ) {
      if (!payload.conditions) {
        state.projectstotalRowsType = payload.response.pagination.total
        state.projectstotalRows = payload.response.pagination.total_pages
        state.projectstotalRowsStade = 0
      } else {
        state.projectstotalRowsStade = payload.response.pagination.total
        state.projectstotalRows = payload.response.pagination.total_pages
        state.projectstotalRowsType = 0
      }
      state.projectstotalRowsTH = payload.response.pagination.total
    } else {
      state.projectstotalRowsStade = 0
      state.projectstotalRows = 0
      state.projectstotalRowsTH = 0
      state.projectstotalRowsType = 0
    }
  },
  DO_NOTHING() {},
  SAVE_CUSTOM_FILTERS(state, payload) {
    state.filters.push(payload)
  },
  SET_CUSTOM_FILTERS(state, payload) {
    state.filters = payload
  },
  DELETE_CUSTOM_FILTERS(state, payload) {
    state.filters = state.filters.filter(filter => {
      return filter.id != payload.id
    })
  },
  SET_ETAT_SWITCH(state, payload) {
    if (payload.response.subtype) {
      for (
        let index = 0;
        index < state.extraProject.categoriesProjets.length;
        index++
      ) {
        if (
          state.extraProject.categoriesProjets[index].id ===
          payload.payload.categorie.id
        ) {
          for (
            let indx = 0;
            indx < state.extraProject.categoriesProjets[index].subtypes.length;
            indx++
          ) {
            if (
              state.extraProject.categoriesProjets[index].subtypes[indx].id ===
              payload.response.subtype.id
            ) {
              state.extraProject.categoriesProjets[index].subtypes[
                indx
              ].is_tagged = payload.response.subtype.is_tagged
            }
          }
        }
      }
    }
  },

  SET_ROUTE_DYNAMIC(state, payload) {
    state.routeDynamic = payload
  },
  SET_ROUTE_DYNAMIC_PROCESSING(state, payload = false) {
    state.routeDynamicProcessing = payload
  },
  SET_ROUTE_DYNAMIC_PROJECT_TH(state, payload) {
    let name = null
    if (payload.route && payload.route.name) {
      name = payload.route.name
    } else if (payload.route && payload.route.name_1) {
      name = payload.route.name_1
    } else if (payload.route && payload.route.name_2) {
      name = payload.route.name_2
    } else if (payload.route && payload.route.name_3) {
      name = payload.route.name_3
    }
    const value = 'table' + name
    if (name == 'stade_projet_global_id') {
      payload.project[value] = payload.response.map(item =>
        StageTH.create(item)
      )
    } else {
      payload.project[value] = payload.response
    }
  },
  SET_ADD_CHAMP_SELECT_PROJECT_TH(state, payload) {
    let name = null
    if (payload.route.name) {
      name = payload.route.name
    }
    if (payload.link_name) {
      name = payload.link_name
    }
    const value = 'table' + name
    payload.project[value].unshift(payload.response)
  },
  SET_ROUTE_DYNAMIC_PROCESSING_PROJECT_TH(state, payload = false) {
    state.routeDynamicProcessingProjectTH = payload
  },
  SET_COMM_SED_NOT_DELETED(state, payload) {
    state.commSedNotDeleted = payload
  },
  SET_COMM_SED_NOT_DELETED_PROCESSING(state, payload = false) {
    state.commSedNotDeletedProcessing = payload
  },
  SET_PREVISITEUR_NOT_DELETED(state, payload) {
    state.previsiteurNotDeleted = payload
  },
  SET_PREVISITEUR_NOT_DELETED_PROCESSING(state, payload = false) {
    state.previsiteurNotDeletedProcessing = payload
  },
  SET_POSEUR_NOT_DELETED(state, payload) {
    state.poseurNotDeleted = payload
  },
  SET_POSEUR_NOT_DELETED_PROCESSING(state, payload = false) {
    state.poseurNotDeletedProcessing = payload
  },
  SET_COMM_TERRAIN_NOT_DELETED(state, payload) {
    state.commTerrainNotDeleted = payload
  },
  SET_COMM_TERRAIN_NOT_DELETED_PROCESSING(state, payload = false) {
    state.commTerrainNotDeletedProcessing = payload
  },
  SET_SOURCES(state, payload) {
    state.sources = payload
  },
  SET_SOURCE_PROCESSING(state, payload = false) {
    state.sourceProcessing = payload
  },
  SET_RESP_PLANNNING_NOT_DELETED(state, payload) {
    state.respPlanningNotDeleted = payload
  },
  SET_RESP_PLANNNING_NOT_DELETED_PROCESSING(state, payload = false) {
    state.respPlanningNotDeletedProcessing = payload
  },
  SET_PROJECT_TYPES_PIXEL(state, payload) {
    state.typePixel = payload
  },
  SET_PROJECT_SOUS_TYPES(state, payload) {
    state.sousTypes = payload.map(item => TypesPixel.create(item))
  },
  SET_PROJECT_TYPES_PIXEL_PROCESSING(state, payload = false) {
    state.typePixelLoading = payload
  },
  SET_PROJECT_INJECTION_PIXEL(state, payload) {
    payload.pixel.update(payload.response)
    state.historyInjectionPixel.unshift({
      pixel_type: payload.response.pixel_type,
      time: payload.response.time,
      user: payload.response.user,
      name_subtype: payload.response.name_subtype
    })
  },
  SET_PROJECT_HISTORY_PIXEL_PROCESSING(state, payload = false) {
    state.historyInjectionPixelLoading = payload
  },
  SET_PROJECT_HISTORY_PIXEL(state, payload) {
    state.historyInjectionPixel = payload
  },
  RESET_PAGE_VALUE(state, payload) {
    state.objectPrj = payload
    state.changePageValue = !state.changePageValue
  },
  SET_COMMENTS_PROJECTS_TH_PROCESSING(state, payload = false) {
    state.commentProcessing = payload
  },
  SET_UPDATE_CHAMP_SELECT_PROJECT_TH(state, payload) {
    let name = null
    if (payload.route.name) {
      name = payload.route.name
    }
    if (payload.link_name) {
      name = payload.link_name
    }
    if (payload.checked) {
      payload.project[payload.field] = payload.newOption.id
    }
    const value = 'table' + name
    let old = null
    for (let index = 0; index < payload.project[value].length; index++) {
      if (
        payload.project[value][index].identifier == payload.newOption.identifier
      ) {
        old = payload.project[value][index].id
        payload.project[value][index] = {
          id: payload.newOption.id,
          name: payload.newOption.id,
          identifier: payload.newOption.identifier
        }
      }
    }
    state.projects.map(item => {
      if (item[name] == old) {
        item[name] = payload.newOption.id
      }
    })
  },
  SET_DELETE_CHAMP_SELECT_PROJECT_TH(state, payload) {
    let name = null
    if (payload.route.name) {
      name = payload.route.name
    }
    if (payload.link_name) {
      name = payload.link_name
    }
    const value = 'table' + name
    for (let index = 0; index < payload.project[value].length; index++) {
      if (
        payload.project[value][index].identifier ==
        payload.optinToDelete.option.identifier
      ) {
        payload.project[value].splice(index, 1)
      }
    }
  },
  UPDATE_PROJECT_TH_CHECK(state, payload) {
    payload.entity.updateCheck(payload.response)
  },
  UPDATE_STAGES_TH(state, { options, data, order }) {
    if (!order) {
      options.updateStageTH(data)
    }
  },
  ADD_STAGES_TH(state, { optionStade, stage }) {
    optionStade.push(StageTH.create(stage))
  },

  UPDATE_ORDER(state, payload) {
    for (let index = 0; index < state.projects.length; index++) {
      state.projects[index].tablestade_projet_global_id = payload.test
    }
  },
  REMOVE_STAGES_TH() {},
  SET_STADE_GLOBAL_PROJECT_TH(state, payload) {
    state.tableStadeGlobalTH = []
    state.tableStadeGlobalTH = payload.map(item => StageTH.create(item))
  },
  FIELDS_MASSE_PROJECTS_PROJECT(state, payload) {
    state.fieldsProjetMasse = payload
  },
  UPDATE_ORDER_FIELDS_CATEGORY(state, payload) {
    const categoryToUpdate = state.extraProject.categoriesProjets.find(
      category => category.id === payload.category.id
    )
    if (categoryToUpdate) {
      categoryToUpdate.fields = payload.fields
    }
  },
  UPDATE_CONTACT_PROJECTS_EXTRA(state, payload) {
    payload.data.updateFullNameContact(payload.response)
  },
  SET_FIELD_IMMO_SPECIFIC(state, payload) {
    state.fieldsAddProjetImmo = payload
  },
  SET_LOADER_EXPORT_PROJECTS_IMMO(state, payload) {
    state.loaderExportExcelProjectsImmo = payload
  }
}
//**********************************************************************************Action *****************************************************************************/
const actions = {
  //Action Update View de tabe IMMoTable
  async UpdateViewTable({ commit }, payload) {
    commit('SET_PROJECTS_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      const response = await Axios.post(
        domain + '/api/update-fields-in-type',
        payload.data,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('SET_UPDATE_VIEW', response.data)
      commit('SET_PROJECTS_PROCESSING')
    } catch (error) {
      commit('SET_PROJECTS_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  //Action Liste Edit View IMMoTable
  async fetchListEditView({ commit }, payload) {
    commit('SET_PROJECTS_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      const params = {
        type_id: payload
      }
      const response = await Axios.get(`${domain}/api/available-fields`, {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        },
        params: params
      })
      commit('FETCH_LISTE_EDIT_VIEW', response.data.data)
      commit('SET_PROJECTS_PROCESSING')
    } catch (error) {
      commit('SET_PROJECTS_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', error)
      }
      return false
    }
    return true
  },

  //Action Header Column IMMoTable
  async fetchHeaderColumnTableBoard({ commit }, payload) {
    // commit("FETCH_TABLE_BOARD", []);
    commit('SET_PROJECTS_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      const params = {
        type_id: payload
      }
      const response = await Axios.get(`${domain}/api/visible-type-fields`, {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        },
        params: params
      })
      commit('FETCH_HEADER_COLUMN', response.data.data)
      commit('SET_PROJECTS_PROCESSING')
    } catch (error) {
      commit('SET_PROJECTS_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', error)
      }
      return false
    }
    return true
  },
  updateOrderFields({ commit }, payload) {
    commit('FETCH_LISTE_EDIT_VIEW', payload)
  },
  async fetchTableBoard({ commit }, payload) {
    commit('SET_PROJECTS_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')
    commit('FETCH_TABLE_BOARD', [])
    try {
      const params = {
        type_id: payload.type_id,
        per_page: payload.per_page,
        page: payload.page,
        step_id: payload.step_id
      }
      if (payload.stage_id) {
        params.stage_id = payload.stage_id
      }
      if (payload.substage_id) {
        params.substage_id = payload.substage_id
      }
      if (payload.contact_id && payload.contact_id.length) {
        params.contact_id = payload.contact_id.map(i => i.id)
      }
      if (payload.owner_id && payload.owner_id.length) {
        params.owner_id = payload.owner_id.map(i => i.id)
      }
      if (payload.tag_id && payload.tag_id.length) {
        params.tag_id = payload.tag_id.map(i => i.id)
      }
      if (requestDataImmo) {
        requestDataImmo.cancel()
      }
      requestDataImmo = Axios.CancelToken.source()
      const response = await Axios.get(`${domain}/api/all-projects`, {
        cancelToken: requestDataImmo.token,
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        },
        params: params
      })
      if (
        response &&
        response.data &&
        response.data.data &&
        response.data.data.projets
      ) {
        commit('FETCH_TABLE_BOARD', response.data.data.projets)
        commit('SET_PROJECTS_TABLE_BOARD_META', response.data.data.pagination)
      } else {
        commit('FETCH_TABLE_BOARD', [])
      }
      commit('SET_PROJECTS_PROCESSING')
    } catch (error) {
      commit('SET_PROJECTS_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        if (!error.message == 'cancel-request') {
          commit('SET_PROJECTS_ERROR', error)
        }
      }
      return false
    }
    return true
  },
  intialiserTableProjet({ commit }) {
    commit('FETCH_TABLE_BOARD', [])
  },
  async fetchSingleProject({ commit }, { id, checked }) {
    commit('SET_PROJECTS_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      const response = await Axios.get(`${domain}/api/crm/projets/${id}`, {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        }
      })
      commit('ADD_PROJECTS_EXTRA', {
        data: response.data,
        checked: checked,
        id: id
      })
      commit('SET_PROJECTS_PROCESSING')
    } catch (error) {
      commit('SET_PROJECTS_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', error)
      }
      return false
    }
    return true
  },
  async reafreshContactData({ commit }, payload) {
    try {
      const response = await Axios.get(
        `${domain}/api/crm/projets/${payload.id}`,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('UPDATE_CONTACT_PROJECTS_EXTRA', {
        response: response.data,
        data: payload
      })
      commit('SET_PROJECTS_PROCESSING')
    } catch (error) {
      commit('SET_PROJECTS_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', error)
      }
      return false
    }
  },
  async createProject({ commit }, payload) {
    commit('SET_PROJECTS_PROCESSING', true)
    commit('SET_PROJECTS_CREATE_ERROR')
    try {
      const response = await Axios.post(
        domain + '/api/crm/projets',
        payload.data,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('ADD_PROJECTS_PROJECT', {
        id_dtype: payload.data.dtype_id,
        project_owner: payload.data.project_owner,
        currentType: payload.currentType,
        response: response.data
      })
      commit('SET_PROJECTS_PROCESSING')
    } catch (error) {
      commit('SET_PROJECTS_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_CREATE_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_CREATE_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  refreshProjectExtra({ commit }) {
    commit('REFRESH_PROJECTS_EXTRA')
  },
  async updateProject({ commit, dispatch }, payload) {
    commit('SET_PROJECTS_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      let data = { [payload.field]: payload.value }
      if (payload && payload.field && Array.isArray(payload.field)) {
        payload.field.forEach(objet => {
          for (const prop in objet) {
            data[prop] = objet[prop]
          }
        })
      }
      if (!payload.field) {
        data = payload.value
      }
      if (payload.substage) {
        data[payload.substage.field] = payload.substage.value
      }
      if (payload.step_update) {
        data[payload.step_update.field] = payload.step_update.value
      }
      const response = await Axios.put(
        domain + `/api/crm/projets/${payload.entity.id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      if (
        payload.field &&
        (payload.field == 'cstage_id' || payload.field == 'csubstage_id')
      ) {
        dispatch('fetchProjectHistoriques', {
          project: payload.entity,
          reset: 1,
          page: 1
        })
      }
      // dispatch('fetchJournalDesModifications', {
      //   project: payload.entity,
      //   reset: 1,
      //   page: 1
      // })
      commit('UPDATE_PROJECTS_PROJECT', {
        checked: payload.checked,
        entity: payload.entity,
        response: response.data
      })
      commit('SET_PROJECTS_PROCESSING')
    } catch (error) {
      commit('SET_PROJECTS_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async updateProjectBash({ commit, dispatch }, payload) {
    commit('SET_PROJECTS_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      const response = await Axios.put(
        domain + `/api/crm/projets/${payload.entity.id}`,
        payload.data,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      dispatch('fetchProjectHistoriques', { project: payload.entity, page: 1 })
      dispatch('fetchJournalDesModifications', {
        project: payload.entity,
        page: 1
      })
      commit('UPDATE_PROJECTS_PROJECT', {
        entity: payload.entity,
        response: response.data
      })
      commit('SET_PROJECTS_PROCESSING')
    } catch (error) {
      commit('SET_PROJECTS_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async updateProjectsMultiple({ commit }, payload) {
    commit('SET_PROJECTS_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      const response = await Axios.put(
        `${domain}/api/crm/mass-projets`,
        payload.data,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('UPDATE_PROJECTS_MULTIPLE', {
        response: response.data,
        projects: payload.projects
      })
      commit('SET_PROJECTS_PROCESSING')
    } catch (error) {
      commit('SET_PROJECTS_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  // async updateProjectsMultiple({ commit }, payload) {
  //   commit('SET_PROJECTS_PROCESSING', true)
  //   commit('SET_PROJECTS_ERROR')
  //   try {
  //     const response = await Axios.put(
  //       `${domain}/api/crm/dynamic-mass-projets`,
  //       payload.data,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${this.state.currentUser.token}`
  //         }
  //       }
  //     )
  //     commit('UPDATE_PROJECTS_MULTIPLE', {
  //       response: response.data,
  //       projects: payload.projects
  //     })
  //     commit('SET_PROJECTS_PROCESSING')
  //   } catch (error) {
  //     commit('SET_PROJECTS_PROCESSING')
  //     if (
  //       error.response &&
  //       error.response.data &&
  //       error.response.data.error &&
  //       error.response.data.error.messages
  //     ) {
  //       commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
  //     } else {
  //       commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
  //     }
  //     return false
  //   }
  //   return true
  // },
  async fetchListeFieldsMassProjet({ commit }, payload) {
    commit('SET_PROJECTS_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')
    const params = {
      dtype_id: payload.dtype_id
    }
    try {
      const response = await Axios.get(domain + '/api/type-project-fields', {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        },
        params
      })
      commit('FIELDS_MASSE_PROJECTS_PROJECT', response.data.data)
      commit('SET_PROJECTS_PROCESSING')
    } catch (error) {
      commit('SET_PROJECTS_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async deleteProjectsMultiple({ commit }, payload) {
    commit('SET_PROJECTS_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      const response = await Axios.delete(
        `${domain}/api/crm/delete-mass-projets`,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          },
          data: {
            projets: payload.projects.map(p => {
              return p.id
            }),
            is_deleted: 1
          }
        }
      )
      commit('DELETE_PROJECTS_MULTIPLE', {
        response: response.data.data,
        projects: payload.projects
      })
      commit('SET_PROJECTS_PROCESSING')
    } catch (error) {
      commit('SET_PROJECTS_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async refrechProject({ commit, dispatch }, payload) {
    commit('SET_PROJECTS_PROCESSING', true)
    commit('SET_PROJECTS_CREATE_ERROR')
    try {
      if (!payload.notRefreshHistory) {
        dispatch('fetchJournalDesModifications', {
          project: payload.project,
          reset: payload.reset,
          page: 1
        })
      }
      const response = await Axios.get(
        domain + '/api/crm/projets/' + payload.project.id,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('REFRECH_PROJECTS_PROJECT', {
        project: payload.project,
        response: response.data
      })
      commit('SET_PROJECTS_PROCESSING')
    } catch (error) {
      commit('SET_PROJECTS_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_CREATE_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_CREATE_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async deleteProject({ commit }, payload) {
    commit('SET_PROJECTS_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      await Axios.delete(domain + `/api/crm/projets/${payload.id}`, {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        }
      })
      commit('REMOVE_PROJECTS_PROJECT', payload)

      commit('SET_PROJECTS_PROCESSING')
    } catch (error) {
      commit('SET_PROJECTS_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async exportProjectFile(
    { commit },
    { conditions, type_id, step_id, filter_id, sort_by, sort_by_desc }
  ) {
    commit('SET_PROJECTS_EXPORT_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      const params = {
        sort_by: sort_by,
        type_id: type_id,
        step_id: step_id
      }
      if (sort_by_desc) {
        params.sort_by_desc = sort_by_desc
      }
      if (filter_id === 'null') {
        filter_id = null
      }
      if (filter_id) {
        params.filter_id = filter_id
      }
      if (requests.exportExcel) {
        requests.exportExcel.cancel('override-request')
      }
      requests.exportExcel = Axios.CancelToken.source()
      const response = await Axios.post(
        domain + '/api/export-projects',
        {
          conditions: conditions
        },
        {
          cancelToken: requests.exportExcel.token,
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          },
          params: params
        }
      )
      const a = document.createElement('a')
      a.href = response.data.data.url
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
      commit('SET_PROJECTS_EXPORT_PROCESSING')
    } catch (error) {
      if (error.message === 'override-request') {
        return true
      }
      commit('SET_PROJECTS_EXPORT_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', error)
      }
      return false
    }
    return true
  },
  // Custom fields
  async fetchContactFields({ commit }) {
    commit('SET_PROJECTS_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      const response = await Axios.get(
        domain + '/api/fields?table_id=3&default=0',
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('SET_PROJECTS_CONTACT_FIELDS', response.data.data)

      commit('SET_PROJECTS_PROCESSING')
    } catch (error) {
      commit('SET_PROJECTS_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async fetchProjectFields({ commit }, payload) {
    commit('SET_PROJECTS_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      const response = await Axios.get(
        domain +
          '/api/fields?dtype_id=' +
          payload.id_dtype +
          '&table_id=42&default=0 ',
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('SET_PROJECTS_PROJECT_FIELDS', response.data.data)

      commit('SET_PROJECTS_PROCESSING')
    } catch (error) {
      commit('SET_PROJECTS_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },

  // Users of projet
  async fetchUsersCommercialSedentaire({ commit }) {
    commit('SET_PROJECTS_USERS_COMMERCIAL_SEDENTAIRE_LOADING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      const response = await Axios.get(domain + '/api/coms-sed', {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        }
      })
      commit('SET_PROJECTS_USERS_COMMERCIAL_SEDENTAIRE', response.data.data)
      commit('SET_PROJECTS_USERS_COMMERCIAL_SEDENTAIRE_LOADING')
    } catch (error) {
      commit('SET_PROJECTS_USERS_COMMERCIAL_SEDENTAIRE_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async fetchUsersCommercialsIte({ commit }) {
    commit('SET_PROJECTS_USERS_COMMERCIALS_ITE_LOADING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      const response = await Axios.get(domain + '/api/coms-itenerant', {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        }
      })
      commit('SET_PROJECTS_USERS_COMMERCIALS_ITE', response.data.data)
      commit('SET_PROJECTS_USERS_COMMERCIALS_ITE_LOADING')
    } catch (error) {
      commit('SET_PROJECTS_USERS_COMMERCIALS_ITE_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async fetchProjectDepotPrevisiteur({ commit }, payload) {
    commit('SET_PROJECTS_USERS_COMMERCIALS_ITE_LOADING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      const response = await Axios.get(
        domain + '/api/depots/' + payload.value + '/coms-itenerant',
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('SET_PROJECTS_DEPOTS_PREVISITEUR', {
        response: response.data.data,
        depot: payload
      })
      commit('SET_PROJECTS_USERS_COMMERCIALS_ITE_LOADING')
    } catch (error) {
      commit('SET_PROJECTS_USERS_COMMERCIALS_ITE_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async fetchUsersRespPlan({ commit }) {
    commit('SET_PROJECTS_USERS_RESP_PLAN_LOADING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      const response = await Axios.get(domain + '/api/resp-plan', {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        }
      })
      commit('SET_PROJECTS_USERS_RESP_PLAN', response.data.data)
      commit('SET_PROJECTS_USERS_RESP_PLAN_LOADING')
    } catch (error) {
      commit('SET_PROJECTS_USERS_RESP_PLAN_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async fetchUsersTechniciens({ commit }) {
    commit('SET_PROJECTS_USERS_TECHNICIENS_LOADING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      const response = await Axios.get(domain + '/api/poseurs', {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        }
      })
      commit('SET_PROJECTS_USERS_TECHNICIENS', response.data.data)
      commit('SET_PROJECTS_USERS_TECHNICIENS_LOADING')
    } catch (error) {
      commit('SET_PROJECTS_USERS_TECHNICIENS_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async fetchProjectDepotTechnicien({ commit }, payload) {
    commit('SET_PROJECTS_USERS_TECHNICIENS_LOADING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      const response = await Axios.get(
        domain + '/api/depots/' + payload.value + '/poseurs',
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('SET_PROJECTS_DEPOTS_TECHNICIEN', {
        response: response.data.data,
        depot: payload
      })
      commit('SET_PROJECTS_USERS_TECHNICIENS_LOADING')
    } catch (error) {
      commit('SET_PROJECTS_USERS_TECHNICIENS_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  // Planning suggestion
  async fetchPlanningDatesSuggestions({ commit }, payload) {
    commit('SET_PROJECTS_SUGGESTIONS_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')
    let suggestions = []

    try {
      const response = await Axios.post(
        domain + '/api/dates-suggestions',
        payload,
        {}
      )
      suggestions = response.data
      commit('SET_PROJECTS_SUGGESTIONS_PROCESSING')
    } catch (error) {
      commit('SET_PROJECTS_SUGGESTIONS_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
    }
    return suggestions
  },
  async fetchPreVisitDatesSuggestions({ commit }) {
    commit('SET_PROJECTS_SUGGESTIONS_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')
    let suggestions = []

    try {
      // api call
      suggestions = []
      commit('SET_PROJECTS_SUGGESTIONS_PROCESSING')
    } catch (error) {
      commit('SET_PROJECTS_SUGGESTIONS_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
    }
    return suggestions
  },
  // Activities
  async createActivity({ commit }, { payload, project }) {
    commit('SET_PROJECTS_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')

    try {
      const response = await Axios.post(
        domain + '/api/crm/activities',
        payload,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('ADD_PROJECT_ACTIVITY', {
        payload: response.data.data,
        project: project
      })
      commit('SET_PROJECTS_PROCESSING')
    } catch (error) {
      commit('SET_PROJECTS_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async fetchAllActivities({ commit }, payload) {
    commit('SET_PROJECTS_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      if (
        requests.projectDependencies &&
        requests.projectDependencies.activities
      ) {
        requests.projectDependencies.activities.cancel('override-request')
      }
      requests.projectDependencies.activities = Axios.CancelToken.source()

      const responseNonTerminer = await Axios.get(
        domain + '/api/crm/activities?projet_id=' + payload.project.id,
        {
          cancelToken: requests.projectDependencies.activities.token,
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          },
          params: {
            terminer: 0
          }
        }
      )

      const responseTerminer = await Axios.get(
        domain + '/api/crm/activities?projet_id=' + payload.project.id,
        {
          cancelToken: requests.projectDependencies.activities.token,
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          },
          params: {
            per_page: 10,
            page: payload.page,
            terminer: 1
          }
        }
      )
      const response = responseNonTerminer.data.data.concat(
        responseTerminer.data.data
      )
      commit('SET_PROJECT_ACTIVITY', {
        activity: payload.project,
        response: response,
        pagination:
          responseTerminer.data.meta && responseTerminer.data.meta.pagination
            ? responseTerminer.data.meta.pagination
            : { total: 1 },
        reset: payload.reset
      })
      commit('SET_PROJECTS_PROCESSING')
    } catch (error) {
      if (error.message === 'override-request') {
        return true
      }
      commit('SET_PROJECTS_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async updateActivity({ commit }, payload) {
    commit('SET_PROJECTS_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')
    const body = {
      date_start: payload.activity.date_start,
      terminer: payload.activity.terminer ? 1 : 0,
      description: payload.activity.description,
      name: payload.activity.name,
      user_id: payload.activity.user.value
    }
    if (!payload.activity.terminer) {
      body.me_prevenir = payload.activity.me_prevenir
    }
    try {
      const response = await Axios.put(
        domain + '/api/crm/activities/' + payload.activity.id,
        body,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('UPDATE_PROJECT_ACTIVITY', {
        toggle: payload.toggle,
        activity: payload.entity,
        project: payload.project,
        response: response.data.data
      })
      commit('SET_PROJECTS_PROCESSING')
    } catch (error) {
      commit('SET_PROJECTS_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async toggleActivity({ commit }, payload) {
    commit('SET_PROJECTS_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')

    try {
      const response = await Axios.put(
        domain + '/api/crm/activities/' + payload.activity.id,
        { terminer: payload.value },
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('UPDATE_PROJECT_ACTIVITY', {
        toggle: true,
        project: payload.project,
        activity: payload.activity,
        response: response.data.data
      })
      commit('SET_PROJECTS_PROCESSING')
    } catch (error) {
      commit('SET_PROJECTS_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async deleteActivity({ commit }, payload) {
    commit('SET_PROJECTS_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      const response = await Axios.delete(
        domain + `/api/crm/activities/` + payload.activity.id,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('REMOVE_PROJECT_ACTIVITY', {
        activity: payload.activity,
        project: payload.project,
        response: response.data.data
      })

      commit('SET_PROJECTS_PROCESSING')
    } catch (error) {
      commit('SET_PROJECTS_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },

  // History
  async fetchProjectHistoriques({ commit }, payload) {
    commit('SET_PROJECTS_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      const params = {
        per_page: 10,
        page: payload.page
      }
      if (
        requests.projectDependencies &&
        requests.projectDependencies.history
      ) {
        requests.projectDependencies.history.cancel('override-request')
      }
      requests.projectDependencies.history = Axios.CancelToken.source()
      const response = await Axios.get(
        domain + '/api/crm/filter-tous?projet_id=' + payload.project.id,
        {
          cancelToken: requests.projectDependencies.history.token,
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          },
          params: params
        }
      )
      commit('SET_PROJECT_HISTORIQUE', {
        project: payload.project,
        response: response.data.data,
        pagination:
          response.data.meta && response.data.meta.pagination
            ? response.data.meta.pagination
            : { total: 1 },
        reset: payload.reset
      })
      commit('SET_PROJECTS_PROCESSING')
    } catch (error) {
      if (error.message === 'override-request') {
        return true
      }
      commit('SET_PROJECTS_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async fetchJournalDesModifications({ commit }, payload) {
    commit('SET_PROJECTS_HISTORY_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      const params = {
        per_page: 10,
        page: payload.page
      }
      if (
        requests.projectDependencies &&
        requests.projectDependencies.journal
      ) {
        requests.projectDependencies.journal.cancel('override-request')
      }
      requests.projectDependencies.journal = Axios.CancelToken.source()
      const response = await Axios.get(
        domain + '/api/crm/historiques?projet_id=' + payload.project.id,
        {
          cancelToken: requests.projectDependencies.journal.token,
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          },
          params: params
        }
      )
      commit('SET_PROJECT_JOURNAL', {
        project: payload.project,
        response: response.data.data,
        pagination:
          response.data.meta && response.data.meta.pagination
            ? response.data.meta.pagination
            : { total: 1 },
        reset: payload.reset
      })
      commit('SET_PROJECTS_HISTORY_PROCESSING')
    } catch (error) {
      if (error.message === 'override-request') {
        return true
      }
      commit('SET_PROJECTS_HISTORY_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  //----------------------------------------------- Files-----------------------------------------//
  async addFichier({ commit }, payload) {
    commit('SET_PROJECTS_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      const response = await Axios.post(
        domain + '/api/crm/files',
        payload.data,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('SET_PROJECT_ADD_FILES', {
        project: payload.project,
        response: response.data.data
      })
      commit('SET_PROJECTS_PROCESSING')
    } catch (error) {
      commit('SET_PROJECTS_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async fetchFiles({ commit }, payload) {
    commit('SET_PROJECT_FILES_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      if (requests.projectDependencies.files) {
        requests.projectDependencies.files.cancel('override-request')
      }
      requests.projectDependencies.files = Axios.CancelToken.source()
      const response = await Axios.get(
        domain + '/api/crm/files?projet_id=' + payload.id,
        {
          cancelToken: requests.projectDependencies.files.token,
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )

      commit('SET_PROJECT_FILES', {
        project: payload,
        response: response.data.data
      })
      commit('SET_File_META', response.data.meta)

      commit('SET_PROJECT_FILES_PROCESSING')
    } catch (error) {
      if (error.message === 'override-request') {
        return true
      }
      commit('SET_PROJECT_FILES_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async UpdateFile({ commit }, payload) {
    commit('SET_PROJECTS_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      const response = await Axios.put(
        domain + '/api/crm/files/' + payload.file.id,
        { description: payload.data },
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('UPDATE_PROJECT_FILE', {
        file: payload.file,
        project: payload.project,
        response: response.data.data
      })
      commit('SET_PROJECTS_PROCESSING')
    } catch (error) {
      commit('SET_PROJECTS_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async deleteFile({ commit }, payload) {
    commit('SET_PROJECTS_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      const response = await Axios.delete(
        domain + `/api/crm/files/` + payload.file.id,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      if (response) {
        commit('REMOVE_PROJECTS_FILE', {
          file: payload.file,
          project: payload.project
        })
      }
      commit('SET_PROJECTS_PROCESSING')
    } catch (error) {
      commit('SET_PROJECTS_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async downloadFile({ commit }, payload) {
    commit('SET_PROJECTS_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      const response = await Axios.get(
        domain + '/api/crm/files/' + payload.id,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          },
          responseType: 'blob'
        }
      )
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', payload.title) //or any other extension
      document.body.appendChild(link)
      link.click()
      commit('DO_NOTHING')
      commit('SET_PROJECTS_PROCESSING')
    } catch (error) {
      commit('SET_PROJECTS_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async fetchCategorieType({ commit }, payload) {
    commit('SET_PROJECTS_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      const response = await Axios.post(
        domain + '/api/crm/categories-files-type',
        payload,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('SET_PROJECT_FILES_CATEGORIES', response.data.data)
      commit('SET_PROJECTS_PROCESSING')
    } catch (error) {
      commit('SET_PROJECTS_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  // Comments
  async addComment({ commit }, payload) {
    commit('SET_PROJECTS_PROCESSING', true)
    commit('SET_PROJECTS_CREATE_ERROR')
    try {
      const response = await Axios.post(
        domain + '/api/crm/pcomments',
        {
          body: payload.comment,
          projet_id: payload.project.id
        },
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('ADD_PROJECTS_COMMENTS', {
        response: response.data.data,
        project: payload.project
      })
      commit('SET_PROJECTS_PROCESSING')
    } catch (error) {
      commit('SET_PROJECTS_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_CREATE_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_CREATE_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async fetchComments({ commit }, payload) {
    commit('SET_PROJECTS_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      const params = {
        per_page: 10,
        page: payload.page
      }
      if (
        requests.projectDependencies &&
        requests.projectDependencies.comments
      ) {
        requests.projectDependencies.comments.cancel('override-request')
      }
      requests.projectDependencies.comments = Axios.CancelToken.source()
      const response = await Axios.get(
        domain + '/api/crm/pcomments?projet_id=' + payload.project.id,
        {
          cancelToken: requests.projectDependencies.comments.token,
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          },
          params: params
        }
      )
      commit('SET_PROJECT_COMMENTS', {
        project: payload.project,
        response: response.data.data,
        pagination:
          response.data.meta && response.data.meta.pagination
            ? response.data.meta.pagination
            : { total: 1 },
        reset: payload.reset
      })
      commit('SET_PROJECTS_PROCESSING')
    } catch (error) {
      if (error.message === 'override-request') {
        return true
      }
      commit('SET_PROJECTS_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async updateNote({ commit }, payload) {
    commit('SET_PROJECTS_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')

    try {
      const response = await Axios.put(
        domain + '/api/crm/pcomments/' + payload.entity.id,
        payload.data,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('UPDATE_PROJECT_NOTE', {
        comment: payload.entity,
        project: payload.project,
        response: response.data.data
      })
      commit('SET_PROJECTS_PROCESSING')
    } catch (error) {
      commit('SET_PROJECTS_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async deleteNote({ commit }, payload) {
    commit('SET_PROJECTS_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      const response = await Axios.delete(
        domain + `/api/crm/pcomments/` + payload.comment.id,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('REMOVE_PROJECT_NOTE', {
        comment: payload.comment,
        project: payload.project,
        response: response.data.data
      })

      commit('SET_PROJECTS_PROCESSING')
    } catch (error) {
      commit('SET_PROJECTS_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  // information marketing - source
  async fetchProjectSource({ commit }) {
    commit('SET_PROJECTS_SOURCE_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      const response = await Axios.get(domain + '/api/crm/sources?active=1', {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        }
      })
      commit('SET_PROJECTS_SOURCE', response.data.data)

      commit('SET_PROJECTS_SOURCE_PROCESSING')
    } catch (error) {
      commit('SET_PROJECTS_SOURCE_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async fetchProjectCampagne({ commit }) {
    commit('SET_PROJECTS_CAMPAGNE_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      const response = await Axios.get(domain + '/api/coms-regie', {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        }
      })
      commit('SET_PROJECTS_CAMPAGNE', response.data.data)

      commit('SET_PROJECTS_CAMPAGNE_PROCESSING')
    } catch (error) {
      commit('SET_PROJECTS_CAMPAGNE_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async fetchProjectsCampagnesSources({ commit }, payload) {
    commit('SET_PROJECTS_CAMPAGNE_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      const response = await Axios.get(
        domain + '/api/team/' + payload.team_id + '/coms-regie',
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('SET_PROJECTS_CAMPAGNE_SOURCES', {
        response: response.data.data,
        source: payload
      })

      commit('SET_PROJECTS_CAMPAGNE_PROCESSING')
    } catch (error) {
      commit('SET_PROJECTS_CAMPAGNE_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async fetchProjectDepot({ commit }, payload) {
    commit('SET_PROJECTS_DEPOTS_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      const params = {}
      if (payload.exclude) {
        params.exclude = payload.exclude.join()
      }
      const response = await Axios.get(domain + '/api/all-depots', {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        },
        params: params
      })
      commit('SET_PROJECTS_DEPOTS', response.data.data)

      commit('SET_PROJECTS_DEPOTS_PROCESSING')
    } catch (error) {
      commit('SET_PROJECTS_DEPOTS_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async fetchProjectDepotCommercialTerrain({ commit }, payload) {
    commit('SET_PROJECTS_USERS_COMMERCIALS_ITE_LOADING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      const response = await Axios.get(
        domain + '/api/depots/' + payload.value + '/coms-terrain',
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('SET_PROJECTS_DEPOTS_COMMERCIAL_TERRAIN', {
        response: response.data.data,
        depot: payload
      })

      commit('SET_PROJECTS_USERS_COMMERCIALS_ITE_LOADING')
    } catch (error) {
      commit('SET_PROJECTS_USERS_COMMERCIALS_ITE_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  resetProjectError({ commit }) {
    commit('SET_PROJECTS_CREATE_ERROR')
  },
  // custom Filters
  async saveFilter({ commit }, payload) {
    commit('SET_PROJECTS_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      const response = await Axios.post(domain + '/api/pfilters', payload, {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        }
      })
      commit('SAVE_CUSTOM_FILTERS', response.data)
      commit('SET_PROJECTS_PROCESSING')
    } catch (error) {
      commit('SET_PROJECTS_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async fetchSaveFilters({ commit }) {
    commit('SET_PROJECTS_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')
    const params = { exclude: 'conditions', for_visibility: 0 }
    try {
      const response = await Axios.get(domain + '/api/pfilters', {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        },
        params: params
      })
      commit('SET_CUSTOM_FILTERS', response.data)

      commit('SET_PROJECTS_PROCESSING')
    } catch (error) {
      commit('SET_PROJECTS_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async deleteSaveFilters({ commit }, payload) {
    commit('SET_PROJECTS_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      await Axios.delete(domain + '/api/pfilters/' + payload.id, {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        }
      })
      commit('DELETE_CUSTOM_FILTERS', payload)
      commit('SET_PROJECTS_PROCESSING')
    } catch (error) {
      commit('SET_PROJECTS_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async activeDesactiveSwitchSousCategory({ commit }, payload) {
    commit('SET_PROJECTS_DEPOTS_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      const response = await Axios.get(
        domain +
          '/api/projets/' +
          payload.project_id +
          '/subcategories/' +
          payload.sousCategorie.id,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('SET_ETAT_SWITCH', { payload, response: response.data.data })

      commit('SET_PROJECTS_DEPOTS_PROCESSING')
    } catch (error) {
      commit('SET_PROJECTS_DEPOTS_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async fetchProjectRouteDynamic({ commit }, payload) {
    commit('SET_ROUTE_DYNAMIC_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      const params = {}
      if (payload.group_id != null) {
        params.group_id = payload.group_id
      }
      if (payload && payload.type_projet_id) {
        params.type_projet_id = payload.type_projet_id
      }
      const response = await Axios.get(domain + payload.route, {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        },
        params
      })
      commit('SET_ROUTE_DYNAMIC', response.data.data)

      commit('SET_ROUTE_DYNAMIC_PROCESSING')
    } catch (error) {
      commit('SET_ROUTE_DYNAMIC_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async fetchProjectCommSedNotDeleted({ commit }) {
    commit('SET_COMM_SED_NOT_DELETED_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      const response = await Axios.get(domain + '/api/coms-sed-not-deleted', {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        }
      })
      commit('SET_COMM_SED_NOT_DELETED', response.data.data)

      commit('SET_COMM_SED_NOT_DELETED_PROCESSING')
    } catch (error) {
      commit('SET_COMM_SED_NOT_DELETED_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async fetchProjectPrevisiteurNotDeleted({ commit }) {
    commit('SET_PREVISITEUR_NOT_DELETED_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      const response = await Axios.get(
        domain + '/api/coms-itenerant-not-deleted',
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('SET_PREVISITEUR_NOT_DELETED', response.data.data)

      commit('SET_PREVISITEUR_NOT_DELETED_PROCESSING')
    } catch (error) {
      commit('SET_PREVISITEUR_NOT_DELETED_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async fetchProjectPoseurNotDeleted({ commit }) {
    commit('SET_POSEUR_NOT_DELETED_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      const response = await Axios.get(domain + '/api/poseurs-not-deleted', {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        }
      })
      commit('SET_POSEUR_NOT_DELETED', response.data.data)

      commit('SET_POSEUR_NOT_DELETED_PROCESSING')
    } catch (error) {
      commit('SET_POSEUR_NOT_DELETED_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async fetchProjecCommTerrainNotDeleted({ commit }) {
    commit('SET_COMM_TERRAIN_NOT_DELETED_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      const response = await Axios.get(
        domain + '/api/coms-terrain-not-deleted',
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('SET_COMM_TERRAIN_NOT_DELETED', response.data.data)

      commit('SET_COMM_TERRAIN_NOT_DELETED_PROCESSING')
    } catch (error) {
      commit('SET_COMM_TERRAIN_NOT_DELETED_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async fetchProjectSources({ commit }) {
    commit('SET_SOURCE_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      const response = await Axios.get(domain + '/api/sources-ids-names', {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        }
      })
      commit('SET_SOURCES', response.data.data)

      commit('SET_SOURCE_PROCESSING')
    } catch (error) {
      commit('SET_SOURCE_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async fetchUsersRespPlanningNotDeleted({ commit }) {
    commit('SET_RESP_PLANNNING_NOT_DELETED_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      const response = await Axios.get(domain + '/api/resp-plan-not-deleted', {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        }
      })
      commit('SET_RESP_PLANNNING_NOT_DELETED', response.data.data)

      commit('SET_RESP_PLANNNING_NOT_DELETED_PROCESSING')
    } catch (error) {
      commit('SET_RESP_PLANNNING_NOT_DELETED_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async fetchProjectTypesPixel({ commit }) {
    commit('SET_PROJECT_TYPES_PIXEL_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      const response = await Axios.get(domain + '/api/pixel-types', {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        }
      })
      commit('SET_PROJECT_TYPES_PIXEL', response.data.data)

      commit('SET_PROJECT_TYPES_PIXEL_PROCESSING')
    } catch (error) {
      commit('SET_PROJECT_TYPES_PIXEL_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async fetchProjectSousType({ commit }, payload) {
    commit('SET_PROJECTS_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      const response = await Axios.get(
        domain + '/api/projets/' + payload + '/active-subtypes-px',
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('SET_PROJECT_SOUS_TYPES', response.data)

      commit('SET_PROJECTS_PROCESSING')
    } catch (error) {
      commit('SET_PROJECTS_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async injectionPixel({ commit }, payload) {
    commit('SET_PROJECTS_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      const response = await Axios.get(
        domain +
          '/api/projets/' +
          payload.project.id +
          '/subtypes/' +
          payload.subtypes +
          '/pixel-types/' +
          payload.typePixel.id,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('SET_PROJECT_INJECTION_PIXEL', {
        pixel: payload.pixel,
        response: response.data
      })
      commit('SET_PROJECTS_PROCESSING')
    } catch (error) {
      commit('SET_PROJECTS_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async fetchProjectsHistoryPixel({ commit }, payload) {
    commit('SET_PROJECT_HISTORY_PIXEL_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      const response = await Axios.get(
        domain + '/api/projets/' + payload + '/pixel-history',
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('SET_PROJECT_HISTORY_PIXEL', response.data.data)

      commit('SET_PROJECT_HISTORY_PIXEL_PROCESSING')
    } catch (error) {
      commit('SET_PROJECT_HISTORY_PIXEL_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  resetErrorProject({ commit }) {
    commit('SET_PROJECTS_ERROR')
  },

  async fetchRouteDynamicProjectTH({ commit }, payload) {
    commit('SET_ROUTE_DYNAMIC_PROCESSING_PROJECT_TH', true)
    commit('SET_PROJECTS_ERROR')
    try {
      let link = null
      if (payload.route && payload.route.link) {
        link = payload.route.link
      } else if (payload.route && payload.route.link_1) {
        link = payload.route.link_1
      } else if (payload.route && payload.route.link_2) {
        link = payload.route.link_2
      } else if (payload.route && payload.route.link_3) {
        link = payload.route.link_3
      }
      const response = await Axios.get(domain + link, {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        }
      })
      commit('SET_ROUTE_DYNAMIC_PROJECT_TH', {
        response: response.data.data,
        project: payload.project,
        route: payload.route
      })

      commit('SET_ROUTE_DYNAMIC_PROCESSING_PROJECT_TH')
    } catch (error) {
      commit('SET_ROUTE_DYNAMIC_PROCESSING_PROJECT_TH')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async ajouterChampsSelectDynamic({ commit }, payload) {
    commit('SET_ROUTE_DYNAMIC_PROCESSING_PROJECT_TH', true)
    commit('SET_PROJECTS_ERROR')
    try {
      let link = null
      if (payload.link && payload.link.link) {
        link = payload.link.link
      }
      if (payload.link && payload.link_name) {
        link = payload.link
      }
      const response = await Axios.post(
        domain + link,
        { name: payload.name },
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('SET_ADD_CHAMP_SELECT_PROJECT_TH', {
        response: response.data.data,
        project: payload.project,
        route: payload.link,
        link: payload.link,
        link_name: payload.link_name
      })
      commit('SET_ROUTE_DYNAMIC_PROCESSING_PROJECT_TH')
    } catch (error) {
      commit('SET_ROUTE_DYNAMIC_PROCESSING_PROJECT_TH')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async updateProjectTH({ commit }, payload) {
    commit('SET_PROJECTS_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')

    try {
      let data = { [payload.field]: payload.value }
      const response = await Axios.put(
        domain + '/api/projets-th/' + payload.entity.id,
        data,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('UPDATE_PROJECT_TH', {
        entity: payload.entity,
        response: response.data.data
      })
      commit('SET_PROJECTS_PROCESSING')
    } catch (error) {
      commit('SET_PROJECTS_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async createProjectTh({ commit }, payload) {
    commit('SET_PROJECTS_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      const response = await Axios.post(
        domain + '/api/projets-th',
        {},
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('ADD_PROJECTS_PROJECT', {
        id_dtype: 112,
        currentType: payload.currentType,
        response: response.data.data
      })
      commit('SET_PROJECTS_PROCESSING')
    } catch (error) {
      commit('SET_PROJECTS_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async uploadFileExcel({ commit }, payload) {
    commit('SET_PROJECTS_UPLOAD_EXCEL_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')
    var bodyFormData = new FormData()
    bodyFormData.append('type_id', payload.type_id)
    bodyFormData.append('file', payload.file)
    try {
      await Axios.post(
        domain + '/api/import-reno-glob-projects',
        bodyFormData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('SET_PROJECTS_UPLOAD_EXCEL_PROCESSING')
    } catch (error) {
      commit('SET_PROJECTS_UPLOAD_EXCEL_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async deleteProjectTh({ commit }, payload) {
    commit('SET_PROJECTS_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      await Axios.delete(domain + `/api/projets-th/` + payload.id, {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        }
      })
      commit('REMOVE_PROJECT_TH', payload)

      commit('SET_PROJECTS_PROCESSING')
    } catch (error) {
      commit('SET_PROJECTS_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async listCommentColumnDynamique({ commit }, payload) {
    commit('SET_COMMENTS_PROJECTS_TH_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      const response = await Axios.get(
        `${domain}/api/thprojets/${payload.project.id}/comments?column=${payload.column}`,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('SET_COMMENT_DYNAMIC_PROJECT_TH', {
        response: response.data.data,
        project: payload.project
      })

      commit('SET_COMMENTS_PROJECTS_TH_PROCESSING')
    } catch (error) {
      commit('SET_COMMENTS_PROJECTS_TH_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async ajouterCommentDynamique({ commit }, payload) {
    commit('SET_PROJECTS_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      const response = await Axios.post(
        domain + '/api/thprojets/' + payload.project.id + '/comments',
        { body: payload.body, column: payload.column },
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('SET_ADD_COMMENT_PROJECT_TH', {
        response: response.data.data,
        project: payload.project
      })
      commit('SET_PROJECTS_PROCESSING')
    } catch (error) {
      commit('SET_PROJECTS_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async deleteCommentDynamique({ commit }, payload) {
    commit('SET_PROJECTS_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      const response = await Axios.delete(
        domain +
          '/api/thprojets/' +
          payload.comment.thprojet_id +
          '/comments/' +
          payload.comment.id,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('REMOVE_COMMENT_PROJECT_TH', {
        response: response.data.data,
        project: payload.project
      })

      commit('SET_PROJECTS_PROCESSING')
    } catch (error) {
      commit('SET_PROJECTS_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async updateOptionSelectDynamic({ commit }, payload) {
    commit('SET_ROUTE_DYNAMIC_PROCESSING_PROJECT_TH', true)
    commit('SET_PROJECTS_ERROR')
    try {
      let link = null
      if (payload.link && payload.link.link) {
        link = payload.link.link
      }
      if (payload.link && payload.link_name) {
        link = payload.link
      }
      await Axios.put(
        domain + link + '/' + payload.newValue.identifier,
        { name: payload.newValue.id },
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('SET_UPDATE_CHAMP_SELECT_PROJECT_TH', {
        project: payload.project,
        route: payload.link,
        link: payload.link,
        link_name: payload.link_name,
        newOption: payload.newValue,
        field: payload.field,
        checked: payload.checked
      })
      commit('SET_ROUTE_DYNAMIC_PROCESSING_PROJECT_TH')
    } catch (error) {
      commit('SET_ROUTE_DYNAMIC_PROCESSING_PROJECT_TH')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async deleteOptionSelectDynamic({ commit }, payload) {
    commit('SET_ROUTE_DYNAMIC_PROCESSING_PROJECT_TH', true)
    commit('SET_PROJECTS_ERROR')
    try {
      let link = null
      if (payload.link && payload.link.link) {
        link = payload.link.link
      }
      if (payload.link && payload.link_name) {
        link = payload.link
      }
      await Axios.delete(
        domain + link + '/' + payload.optinToDelete.option.identifier,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('SET_DELETE_CHAMP_SELECT_PROJECT_TH', {
        project: payload.project,
        route: payload.link,
        link: payload.link,
        link_name: payload.link_name,
        optinToDelete: payload.optinToDelete
      })
      commit('SET_ROUTE_DYNAMIC_PROCESSING_PROJECT_TH')
    } catch (error) {
      commit('SET_ROUTE_DYNAMIC_PROCESSING_PROJECT_TH')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async updatecheckListFieldDossierCee({ commit }, payload) {
    commit('SET_PROJECTS_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')

    try {
      var formBody = new FormData()
      formBody.set('field', payload.field)
      const response = await Axios.post(
        domain +
          `/api/projets-th/${payload.entity.id}/update-check-list-field-dossier-cee`,
        formBody,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`,
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }
      )
      commit('UPDATE_PROJECT_TH_CHECK', {
        entity: payload.entity,
        response: response.data.data
      })
      commit('SET_PROJECTS_PROCESSING')
    } catch (error) {
      commit('SET_PROJECTS_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  resetPageValue({ commit }, payload) {
    commit('RESET_PAGE_VALUE', { object: payload.object })
  },
  async addStagesTH({ commit }, { optionStade, stage }) {
    commit('SET_PROJECTS_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')
    const body = {
      name: stage.name,
      order: stage.order
    }
    try {
      const response = await Axios.post(
        domain + '/api/stade-projet-global-th',
        body,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('ADD_STAGES_TH', {
        optionStade: optionStade,
        stage: response.data.data
      })
      commit('SET_PROJECTS_PROCESSING')
    } catch (error) {
      commit('SET_PROJECTS_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async updateNameStagesTH({ commit }, { options, data, order }) {
    commit('SET_PROJECTS_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      const response = await Axios.put(
        domain + '/api/stade-projet-global-th/' + options.identifier,
        data,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('UPDATE_STAGES_TH', { options, data: response.data.data, order })
      commit('SET_PROJECTS_PROCESSING')
    } catch (error) {
      commit('SET_PROJECTS_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async deleteStagesTH({ commit }, { options, stage }) {
    commit('SET_PROJECTS_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      await Axios.delete(
        domain + '/api/stade-projet-global-th/' + stage.identifier,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('REMOVE_STAGES_TH', { options, stage })
      commit('SET_PROJECTS_PROCESSING')
    } catch (error) {
      commit('SET_PROJECTS_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  updateOrder({ commit }, payload) {
    commit('UPDATE_ORDER', payload)
  },
  async fetchListStadeGlobalTH({ commit }) {
    commit('SET_PROJECTS_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      const response = await Axios.get(`${domain}/api/stade-projet-global-th`, {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        }
      })
      commit('SET_STADE_GLOBAL_PROJECT_TH', response.data.data)

      commit('SET_PROJECTS_PROCESSING')
    } catch (error) {
      commit('SET_PROJECTS_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  // UPDATE VISIBILITY FIELDS POUR TYPE DE PROJET ONE CATEGORY
  async visibilityAndUpdateOrderFieldsToCategory({ commit }, payload) {
    commit('SET_PROJECTS_PROCESSING', false)
    commit('SET_PROJECTS_ERROR')
    try {
      await Axios.post(
        domain + '/api/crm/projets/affectFieldCategory',
        payload,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('DO_NOTHING')
      commit('SET_PROJECTS_PROCESSING')
    } catch (error) {
      commit('SET_PROJECTS_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  updateOrderFieldsCategory({ commit }, payload) {
    commit('UPDATE_ORDER_FIELDS_CATEGORY', payload)
  },

  async fetchFieldImmoSpecific({ commit }) {
    commit('SET_PROJECTS_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      const response = await Axios.get(`${domain}/api/immo-specific-fields`, {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        }
      })
      commit('SET_FIELD_IMMO_SPECIFIC', response.data.data)

      commit('SET_PROJECTS_PROCESSING')
    } catch (error) {
      commit('SET_PROJECTS_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },

  // export excel immo projects
  async exportProjectsImmo({ commit }, payload) {
    commit('SET_LOADER_EXPORT_PROJECTS_IMMO', true)
    try {
      const params = {
        type_id: payload.type_id,
        step_id: payload.step_id
      }
      if (payload.stage_id) {
        params.stage_id = payload.stage_id
      }
      if (payload.substage_id) {
        params.substage_id = payload.substage_id
      }
      if (payload.contact_id) {
        params.contact_id = payload.contact_id
      }
      if (payload.projects) {
        params.projects = payload.projects
      }
      if (payload.owner_id) {
        params.owner_id = payload.owner_id.map(i => i.id)
      }
      const response = await Axios.get(domain + `/api/export-immo`, {
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        },
        params: params
      })

      // Handle successful response
      // Create a blob object from the response data
      const blob = new Blob([response.data], {
        type: 'application/vnd.ms-excel'
      })
      // Create a URL object from the blob
      const url = window.URL.createObjectURL(blob)
      // Create a link element
      const link = document.createElement('a')
      // Set the link's href attribute to the URL object
      link.href = url
      // Set the link's download attribute to specify the file name
      const filename = 'wa-export-' + moment().format('DDMMYYYY') + '.xlsx'
      link.setAttribute('download', filename)
      // Append the link to the document body
      document.body.appendChild(link)
      // Click the link to trigger the download
      link.click()
      // Remove the link from the document body
      document.body.removeChild(link)

      commit('SET_LOADER_EXPORT_PROJECTS_IMMO', false)
      return { succes: true }
    } catch (error) {
      commit('SET_LOADER_EXPORT_PROJECTS_IMMO', false)
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        return { succes: false, error: error.response.data.error.messages }
      } else {
        return { succes: false, error: 'Une erreur est survenue' }
      }
    }
  },
  //update Project Owner
  async updateProjectOwner({ commit }, payload) {
    try {
      let data = { [payload.field]: payload.value }

      const response = await Axios.put(
        domain + `/api/crm/projets/${payload.entity.id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('UPDATE_PROJECTS_PROJECT', {
        checked: payload.checked,
        entity: payload.entity,
        response: response.data
      })
      return { succes: true, data: 'data_updated' }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        return { succes: false, data: error.response.data.error.messages }
      } else {
        return { succes: false, data: ['Une erreur est survenue'] }
      }
    }
  },
  //update Projects Owner // CsubStage // Tag // ..
  async updateProjectsPropertys({ commit }, payload) {
    try {
      await Axios.put(domain + `/api/update-mass-projet`, payload, {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        }
      })
      commit('NOTHINK')
      return { succes: true, data: 'data_updated' }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        return { succes: false, data: error.response.data.error.messages }
      } else {
        return { succes: false, data: ['Une erreur est survenue'] }
      }
    }
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
