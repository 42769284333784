import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

//--------------ClickOutside------------------//
// import vClickOutside from 'v-click-outside';
// Vue.use(vClickOutside);
// Vue.directive('click-outside', {
//   bind(el, binding, vnode) {
//     el.clickOutsideEvent = function (event) {
//       if (!(el == event.target || el.contains(event.target))) {
//         vnode.context[binding.expression](event);
//       }
//     };

//     document.body.addEventListener('click', el.clickOutsideEvent);
//   },
//   unbind(el) {
//     document.body.removeEventListener('click', el.clickOutsideEvent);
//   },
// });



// Libraries components
// import './imports/bootstrap'
import './imports/font-awesome'
import './imports/font-awesome-pro'
//International Telephone
import VueTelInput from 'vue-tel-input'
Vue.use(VueTelInput)
// Application global style
import '@/assets/sass/custom.scss'
import '@/assets/themify-icons/themify-icons.css'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import VTooltip from 'v-tooltip'
import vueDebounce from 'vue-debounce'
//date  && time picker
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.min.css';

Vue.component('vue-ctk-date-time-picker', VueCtkDateTimePicker);
// vue-multiselect
Vue.component('multiselect', () =>
  import(/* webpackChunkName: "vue-multiselect" */ 'vue-multiselect')
)
Vue.use(VTooltip)
Vue.use(vueDebounce)
Vue.config.productionTip = false

import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)

import Lightbox from 'vue-easy-lightbox'
Vue.use(Lightbox)

import PortalVue from 'portal-vue'
Vue.use(PortalVue)

import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/fr'

import vuetify from './plugins/vuetify'
Vue.use(DatePicker)

//alert
import VueSimpleAlert from 'vue-simple-alert'
Vue.use(VueSimpleAlert)

// Formatage langue fr - en
import VueI18n from 'vue-i18n'
import formatEn from './i18n/en.js'
import formatFr from './i18n/fr.js'
Vue.use(VueI18n)
const messages = {
  en: formatEn,
  fr: formatFr
  // Ajoutez d'autres langues ici si nécessaire
}
const i18n = new VueI18n({
  locale: 'fr', // Langue par défaut
  fallbackLocale: 'en', // Langue de secours
  messages,
  missing: (locale, key, vm) => {
    // Si la traduction est manquante pour la langue en cours (ici anglais),
    // retourne la traduction en français par défaut
    if (locale === 'en') {
      return vm.$t(key, 'fr')
    }
  }
})

import DatetimePicker from 'vuetify-datetime-picker'
Vue.use(DatetimePicker)

//Vue Tel Input Vuetify
import VueTelInputVuetify from 'vue-tel-input-vuetify/lib';

Vue.use(VueTelInputVuetify, {
  vuetify,
});

// Material ui
import VueMaterial from 'vue-material'
import 'vue-material/dist/vue-material.min.css'
import 'vue-material/dist/theme/default.css'

Vue.use(VueMaterial)

// import BootstrapVue from 'bootstrap-vue'
// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'
// Vue.use(BootstrapVue)
// Vue.directive('click-outside', {
//   bind(el, binding, vnode) {
//     var vm = vnode.context
//     var callback = binding.value

//     el.clickOutsideEvent = function(event) {
//       if (!(el == event.target || el.contains(event.target))) {
//         return callback.call(vm, event)
//       }
//     }
//     document.body.addEventListener('click', el.clickOutsideEvent)
//   },
//   unbind(el) {
//     document.body.removeEventListener('click', el.clickOutsideEvent)
//   }
// })

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
