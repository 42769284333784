import Axios from '@/axios'
import domain from '@/environment'
import HistoryUsers from '../../models/crm/HistoryUsers'
const state = {
  historyUsersError: null,
  historyUsersLoading: false,
  historyUsers: [],
  countPaginationHistoriqueUser: 1
}
const getters = {
  getHistoryUsersError: state => state.historyUsersError,
  getHistoryUsersLoading: state => state.historyUsersLoading,
  getHistoryUsers: state => state.historyUsers,
  countPaginationHistoriqueUser: state => state.countPaginationHistoriqueUser
}
const mutations = {
  SET_HISTORY_USERS_LOADING(state, payload = false) {
    state.historyUsersLoading = payload
  },
  SET_HISTORY_USERS_ERROR(state, payload = null) {
    state.historyUsersError = payload
  },
  SET_HISTORY_USERS(state, payload) {
    state.historyUsers = payload.data.map(item => HistoryUsers.create(item))
    if (payload.meta) {
      state.countPaginationHistoriqueUser = payload.meta.pagination.total
    } else {
      state.countPaginationHistoriqueUser = 0
    }
  }
}
const actions = {
  async fetchHistoryUsers({ commit }, payload) {
    commit('SET_HISTORY_USERS_LOADING', true)
    commit('SET_HISTORY_USERS_ERROR')

    try {
      const params = {
        page: payload.page,
        per_page: payload.per_page,
        user_id: payload.user_id
      }
      if (payload.entity) {
        params.entity = payload.entity
      }
      const response = await Axios.get(domain + '/api/crm/historiques', {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        },
        params: params
      })
      commit('SET_HISTORY_USERS', response.data)
      commit('SET_HISTORY_USERS_LOADING')
    } catch (error) {
      commit('SET_HISTORY_USERS_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_HISTORY_USERS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_HISTORY_USERS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  }
}
export default {
  state,
  getters,
  mutations,
  actions
}
