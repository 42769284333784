import Vue from 'vue'

import { library, config } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/pro-solid-svg-icons'
import { far } from '@fortawesome/pro-regular-svg-icons'
import { fal } from '@fortawesome/pro-light-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { dom } from '@fortawesome/fontawesome-svg-core'

// Configure Font Awesome with your private token
config.autoAddCss = false
config.api = {
  apiKey: '93090F35-DFCF-47B1-8F44-D3D714F8FB9F'
}

// Add the icon sets to the library
library.add(fas, far, fal, fab)
library.add(faMegaphone)
library.add(faBullhorn)
library.add(faMapLocationDot)
library.add(faBullhorn)
library.add(faMessageDots)
library.add(faCalendarCirclePlus)
library.add(faAddressBook)
library.add(faCodeBranch)
library.add(faFolderOpen)
library.add(faStore)
library.add(faTableColumns)
library.add(faCrosshairsSimple)
library.add(faUserHeadset)
library.add(faImageUser)
library.add(faComputerMouseScrollwheel)
library.add(faHeadset)
library.add(faGrid2)
// Kicks off the process of finding <i> tags and replacing with <svg>
dom.watch()
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faGrid2 } from '@fortawesome/pro-solid-svg-icons'
import {
  faFolderOpen,
  faCalendarCirclePlus,
  faMegaphone,
  faBullhorn,
  faMapLocationDot,
  faMessageDots,
  faTableColumns,
  faAddressBook,
  faCodeBranch,
  faStore,
  faCrosshairsSimple,
  faUserHeadset,
  faImageUser
} from '@fortawesome/pro-light-svg-icons'
import {
  faComputerMouseScrollwheel,
  faHeadset
} from '@fortawesome/pro-regular-svg-icons'
Vue.component('font-awesome-icon', FontAwesomeIcon)
