import Entity from '../Entity'
import User from './User'

export default class File extends Entity {
  constructor(data) {
    super(data.id)
    this.categorie = data.categorie
    this.created_at = data.created_at
    if (data.created_by) {
      if (data.created_by.data) {
        this.created_by = User.create(data.created_by.data)
      } else {
        this.created_by = User.create(data.created_by)
      }
    }
    this.description = data.description
    this.extension = data.extension
    this.path = data.path
    this.size = data.size
    this.title = data.title
    this.url = data.url
    this.object = 'Fichier'
  }

  /**
   * @param {*} data
   */
  static create(data) {
    if (!data) {
      throw new Error('File::create: "data" is undefined')
    }
    return new File(data)
  }
  updateFile(data) {
    this.categorie = data.categorie
    this.created_at = data.created_at
    this.created_by = User.create(data.created_by.data)
    this.description = data.description
    this.extension = data.extension
    this.path = data.path
    this.size = data.size
    this.title = data.title
    this.url = data.url
  }
}
