import Axios from 'axios'
import TypeEvenements from '../../../models/crm/evenements/TypeEvenements'
import domain from '@/environment'

const state = {
  //   TYPE EVENEMENTS
  typeEvent: [],
  oneTypeEvent: null,
  typeEventLoading: false,
  typeEventError: false,
  countPaginationTypeEvent: 1,
  actionForTypeEventLoading: false,
  actionForTypeEventError: null,
  actionForTypeEvent: [],
}
const getters = {
  //   TYPE EVENEMENTS
  getTypeEvent: state => state.typeEvent,
  getOneTypeEvent: state => state.oneTypeEvent,
  getTypeEventLoading: state => state.typeEventLoading,
  getTypeEventError: state => state.typeEventError,
  countPaginationTypeEvent: state => state.countPaginationTypeEvent,
  getActionForTypeEvent: state => state.actionForTypeEvent,
  getActionForTypeEventError: state => state.actionForTypeEventError,
  getActionForTypeEventLoading: state => state.actionForTypeEventLoading,
}
const mutations = {
  //   TYPE EVENT
  SET_TYPE_EVENT_LOADING(state, payload = false) {
    state.typeEventLoading = payload
  },
  SET_TYPE_EVENT_ERROR(state, payload = null) {
    state.typeEventError = payload
  },
  SET_ALL_TYPE_EVENT(state, payload) {
    state.typeEvent = payload.data.map(g => TypeEvenements.create(g))
    //  PAGINATION
    if (payload.pagination) {
      state.countPaginationTypeEvent = payload.pagination.total
    } else {
      state.countPaginationTypeEvent = 1
    }
  },
  SET_ONE_TYPE_EVENT(state, type) {
    state.oneTypeEvent = TypeEvenements.create(type)
  },
  ADD_TYPE_EVENT(state, payload) {
    state.typeEvent.push(TypeEvenements.create(payload))
  },
  UPDATE_TYPE_EVENT(state, { typeEvent, data }) {
    typeEvent.updateTypeEvent(data)
  },
  REMOVE_TYPE_EVENT(state, id) {
    state.typeEvent = state.typeEvent.filter(type => type.id != id)
  },
  ADD_CUSTEM_FIELDS_TYPE_EVENT(state, response) {
    state.oneTypeEvent = TypeEvenements.create(response)
  },
  UPDATE_TYPE_EVENT_CUSTEM_FIELD(state, response) {
    state.oneTypeEvent = response
  },
  REFRESH_ORDER_CUSTEM_FIELDS_TYPE_EVENT(state, payload) {
    state.oneTypeEvent.fields = payload
  },
  UPDATE_ORDER_CUSTEM_FIELDS_TYPE_EVENT(state, payload) {
    state.oneTypeEvent = payload
  },
  SET_ACTIONS_FOR_TYPE_EVENT_LOADING(state, payload) {
    state.actionForTypeEventLoading = payload
  },
  SET_ACTIONS_FOR_TYPE_EVENT_ERROR(state, payload) {
    state.actionForTypeEventError = payload
  },
  ADD_ACTIONS_FOR_TYPE_EVENT(state, payload) {
    state.actionForTypeEvent = [{ id: null, name: '' }, ...payload]
  },
}
const actions = {
  //   TYPE EVENT
  async fetchAllTypeEvent({ commit }, payload) {
    commit('SET_TYPE_EVENT_LOADING', true)
    commit('SET_TYPE_EVENT_ERROR')
    try {
      const params = {}
      if (payload && payload.page) {
        params.page = payload.page
      }
      if (payload && payload.per_page) {
        params.per_page = payload.per_page
      }
      if (payload && payload.type_projet_id) {
        params.type_projet_id = payload.type_projet_id
      }
      const response = await Axios.get(domain + '/api/types_events', {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        },
        params
      })
      commit('SET_ALL_TYPE_EVENT', response.data)
      commit('SET_TYPE_EVENT_LOADING')
    } catch (error) {
      commit('SET_TYPE_EVENT_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_TYPE_EVENT_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_TYPE_EVENT_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async fetchOneTypeEvent({ commit }, type_event_id) {
    commit('SET_TYPE_EVENT_LOADING', true)
    commit('SET_TYPE_EVENT_ERROR')
    try {
      const response = await Axios.get(
        domain + '/api/types_events/' + type_event_id,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('SET_ONE_TYPE_EVENT', response.data.data)
      commit('SET_TYPE_EVENT_LOADING')
    } catch (error) {
      commit('SET_TYPE_EVENT_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_TYPE_EVENT_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_TYPE_EVENT_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async addTypeEvent({ commit }, payload) {
    commit('SET_TYPE_EVENT_LOADING', true)
    commit('SET_TYPE_EVENT_ERROR')
    const body = {
      designation: payload.designation,
      detail: payload.detail,
      color: payload.color,
      icon: payload.icon,
      action_id: payload.action_id,
      type_projet_id:
        payload && payload.type_projet && payload.type_projet.id
          ? payload.type_projet.id
          : payload.type_projet
    }
    try {
      const response = await Axios.post(domain + '/api/types_events', body, {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        }
      })
      commit('ADD_TYPE_EVENT', response.data.data)
      commit('SET_TYPE_EVENT_LOADING')
    } catch (error) {
      commit('SET_TYPE_EVENT_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_TYPE_EVENT_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_TYPE_EVENT_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async updateTypeEvent({ commit }, { body, typeEvent }) {
    commit('SET_TYPE_EVENT_LOADING', true)
    commit('SET_TYPE_EVENT_ERROR')
    const payload = {
      detail: body.detail,
      color: body.color,
      icon: body.icon,
      type_projet_id: body.type_projet_id,
      action_id: body.action_id,
    }
    // Supprimer les espaces au début et à la fin des chaînes
    let chaine1Trimmed = body.designation.trim()
    let chaine2Trimmed = typeEvent.designation.trim()
    if (
      body.designation &&
      typeEvent.designation &&
      chaine1Trimmed !== chaine2Trimmed
    ) {
      payload.designation = body.designation
    }
    try {
      const response = await Axios.put(
        domain + '/api/types_events/' + typeEvent.id,
        payload,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('UPDATE_TYPE_EVENT', {
        typeEvent,
        data: response.data.data
      })
      commit('SET_TYPE_EVENT_LOADING')
    } catch (error) {
      commit('SET_TYPE_EVENT_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_TYPE_EVENT_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_TYPE_EVENT_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async deletedTypeEvent({ commit }, id) {
    commit('SET_TYPE_EVENT_LOADING', true)
    commit('SET_TYPE_EVENT_ERROR')
    try {
      await Axios.delete(domain + '/api/types_events/' + id, {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        }
      })
      commit('REMOVE_TYPE_EVENT', id)
      commit('SET_TYPE_EVENT_LOADING')
    } catch (error) {
      commit('SET_TYPE_EVENT_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_TYPE_EVENT_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_TYPE_EVENT_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async fetchActionsForTypeEvent({ commit }) {
    commit('SET_ACTIONS_FOR_TYPE_EVENT_LOADING', true)
    commit('SET_ACTIONS_FOR_TYPE_EVENT_ERROR')
    try {
      const response = await Axios.get(domain + '/api/actions', {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        }
      })
      commit('ADD_ACTIONS_FOR_TYPE_EVENT', response.data)
      commit('SET_ACTIONS_FOR_TYPE_EVENT_LOADING')
    } catch (error) {
      commit('SET_ACTIONS_FOR_TYPE_EVENT_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_ACTIONS_FOR_TYPE_EVENT_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_ACTIONS_FOR_TYPE_EVENT_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async runActionEvent({ commit }, payload) {

    try {
      await Axios.post(domain + '/api/events/' + payload.id + '/run-action', {}, {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        }
      })
      commit('')
      return { succes: true, data: 'action_done' }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        return { succes: false, error: error.response.data.error.messages }

      } else {
        return { succes: false, error: ['Une erreur est survenue'] }
      }
    }
  },
  resetErrorTypeEvent({ commit }) {
    commit('SET_TYPE_EVENT_ERROR')
  },

  // CUUSTOM FIELDS
  async addCutemFieldsToTypeEvents({ commit }, { idEvent, data }) {
    commit('SET_TYPE_EVENT_LOADING', true)
    commit('SET_TYPE_EVENT_ERROR')
    try {
      const response = await Axios.put(
        domain + '/api/types_events/' + idEvent,
        data,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('ADD_CUSTEM_FIELDS_TYPE_EVENT', response.data.data)
      commit('SET_TYPE_EVENT_LOADING')
    } catch (error) {
      commit('SET_TYPE_EVENT_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_TYPE_EVENT_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_TYPE_EVENT_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async updateCustemFieldTypeEvents({ commit }, { idEvent, data }) {
    commit('SET_TYPE_EVENT_LOADING', true)
    commit('SET_TYPE_EVENT_ERROR')
    try {
      const response = await Axios.put(
        domain + '/api/update-type-field-event/' + idEvent,
        data,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )

      commit('UPDATE_TYPE_EVENT_CUSTEM_FIELD', response.data.data)
      commit('SET_TYPE_EVENT_LOADING')
    } catch (error) {
      commit('SET_TYPE_EVENT_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_TYPE_EVENT_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_TYPE_EVENT_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  refreshOrderCustemFieldsTypeEvents({ commit }, payload) {
    commit('REFRESH_ORDER_CUSTEM_FIELDS_TYPE_EVENT', payload)
  },
  async updateOrderCustemFieldsTypeEvents({ commit }, payload) {
    commit('SET_TYPE_EVENT_LOADING', true)
    commit('SET_TYPE_EVENT_ERROR')
    try {
      const response = await Axios.put(
        domain +
        '/api/update-ordre-event/' +
        payload.idEvent +
        '/' +
        payload.field_id +
        '/' +
        payload.ordre,
        {},
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )

      commit('UPDATE_ORDER_CUSTEM_FIELDS_TYPE_EVENT', response.data.data)
      commit('SET_TYPE_EVENT_LOADING')
    } catch (error) {
      commit('SET_TYPE_EVENT_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_TYPE_EVENT_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_TYPE_EVENT_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
