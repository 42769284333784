import Entity from '../../Entity'

export default class SubStage extends Entity {
  constructor(data) {
    super(data.id)
    this.check = false
    this.collapse = false
    this.name = data.name
    this.order = data.order
    this.status = data.status
    this.color = data.color
    this.has_email = data.has_email
    this.has_sms = data.has_sms
    this.projet_count = data.projet_count
    this.id_pixel_vertuoz = data.id_pixel_vertuoz
    this.id_pixel_fiber = data.id_pixel_fiber
    this.id_pixel_acf = data.id_pixel_acf
    this.id_pixel_h2o = data.id_pixel_h2o
    this.id_pixel_rse = data.id_pixel_rse
    this.id_pixel_gse = data.id_pixel_gse
    this.sms_template_id = null
    this.email_template_id = null

    this.user = data.user
    this.date = data.date

    if (data.sms_template && data.sms_template.id) {
      this.sms_template_id = data.sms_template.id
    }
    if (data.email_template && data.email_template.id) {
      this.email_template_id = data.email_template.id
    }
  }

  /**
   * @param { id, name, order } data
   */
  static create(data) {
    if (!data) {
      throw new Error('SubStage::create: "data" is undefined')
    }
    return new SubStage(data)
  }

  update(data) {
    this.name = data.name
    this.order = data.order
    this.status = data.status
    this.color = data.color
    this.has_email = data.has_email
    this.has_sms = data.has_sms
    this.id_pixel_vertuoz = data.id_pixel_vertuoz
    this.id_pixel_fiber = data.id_pixel_fiber
    this.id_pixel_acf = data.id_pixel_acf
    this.id_pixel_h2o = data.id_pixel_h2o
    this.id_pixel_rse = data.id_pixel_rse
    this.id_pixel_gse = data.id_pixel_gse
    this.projectsCount = data.projet_count
    this.projet_count = data.projet_count
    this.user = data.user
    this.date = data.date
    if (data.sms_template && data.sms_template.id) {
      this.sms_template_id = data.sms_template.id
    }
    if (data.email_template && data.email_template.id) {
      this.email_template_id = data.email_template.id
    }
  }
}
