import Entity from '../../Entity'
import User from '../User'
export default class HistoryEventOfProject extends Entity {
  constructor(data) {
    super(data.id)
    if (data.created_by) {
      if (data.created_by.data) {
        this.created_by = User.create(data.created_by.data)
      } else {
        this.created_by = User.create(data.created_by)
      }
    }
    this.date = data.date
    this.description = data.description
    this.titre = data.titre
    this.type_event = data.type_event
    this.assigned_to= data.assigned_to
    this.event_status= data.event_status
    this.project_id = data.project_id
    this.to = data?.to?.length
      ? data.to.split(';').map(i => {
          return { value: i }
        })
      : []
    this.type = data.type
    this.showText = false
  }

  /**
   * @param {*} data
   */
  static create(data) {
    if (!data) {
      throw new Error('HistoryEventOfProject::create: "data" is undefined')
    }
    return new HistoryEventOfProject(data)
  }
}
