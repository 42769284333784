import Entity from '../../Entity'
export default class Event extends Entity {
  constructor(data) {
    super(data.id)
    this.name = data.name
    this.detail = data.detail
    this.end = data.end
    this.fields = data.fields
    this.start = data.start
    this.type = data.type
    this.color = data.color
    this.code = data.code
    this.timed = data.timed
    this.status = data.status
    this.assigned_user_id = data.assigned_user_id
    this.assigned_user = data.assigned_user
  }

  /**
   * @param {*} data
   */
  static create(data) {
    if (!data) {
      throw new Error('Event::create: "data" is undefined')
    }
    return new Event(data)
  }
  updateEvent(data) {
    this.name = data.name
    this.detail = data.detail
    this.end = data.end
    this.fields = data.fields
    this.start = data.start
    this.type = data.type
    this.color = data.color
    this.code = data.code
    this.timed = data.timed
    this.status = data.status
    this.assigned_user_id = data.assigned_user_id
    this.assigned_user = data.assigned_user
  }
}
