import Axios from 'axios'
import Group from '../../models/crm/Group'
import domain from '@/environment'

const state = {
  groups: [],
  oneGroup: null,
  groupsLoading: false,
  groupsError: false,
  countPaginationGroupe: 1
}
const getters = {
  getGroups: state => state.groups,
  getOneGroup: state => state.oneGroup,
  getGroupsLoading: state => state.groupsLoading,
  getGroupsError: state => state.groupsError,
  countPaginationGroupe: state => state.countPaginationGroupe
}
const mutations = {
  SET_GROUP_LOADING(state, payload = false) {
    state.groupsLoading = payload
  },
  SET_GROUP_ERROR(state, payload = null) {
    state.groupsError = payload
  },
  SET_ALL_GROUP(state, payload) {
    state.groups = payload.data.map(g => Group.create(g))
    //  PAGINATION
    if (payload.pagination) {
      state.countPaginationGroupe = payload.pagination.total
    } else {
      state.countPaginationGroupe = 1
    }
  },
  SET_ONE_GROUP(state, group) {
    state.oneGroup = Group.create(group)
  },
  ADD_GROUP(state, payload) {
    state.groups.push(Group.create(payload))
  },
  UPDATE_GROUP(state, { group, data }) {
    group.updateGroupObjet(data)
  },
  REMOVE_GROUP(state, id) {
    state.groups = state.groups.filter(group => group.id != id)
  },
  ADD_GROUP_USERS(state, payload) {
    state.groups = state.groups.map(item =>
      item.id == payload.id ? Group.create(payload) : item
    )
  },
  ADD_GROUP_ADMINS(state, payload) {
    state.groups = state.groups.map(item =>
      item.id == payload.id ? Group.create(payload) : item
    )
  }
}
const actions = {
  async fetchAllGroups({ commit }, payload) {
    commit('SET_GROUP_LOADING', true)
    commit('SET_GROUP_ERROR')
    const params = {}
    if (payload && payload.page) {
      params.page = payload.page
    }
    if (payload && payload.per_page) {
      params.per_page = payload.per_page
    }
    try {
      const response = await Axios.get(domain + '/api/groups', {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        },
        params: params
      })
      commit('SET_ALL_GROUP', response.data)
      commit('SET_GROUP_LOADING')
    } catch (error) {
      commit('SET_GROUP_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_GROUP_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_GROUP_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async fetchOneGroups({ commit }, group_id) {
    commit('SET_GROUP_LOADING', true)
    commit('SET_GROUP_ERROR')
    try {
      const response = await Axios.get(domain + '/api/groups/' + group_id, {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        }
      })
      commit('SET_ONE_GROUP', response.data.data)
      commit('SET_GROUP_LOADING')
    } catch (error) {
      commit('SET_GROUP_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_GROUP_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_GROUP_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async addGroup({ commit, dispatch }, payload) {
    commit('SET_GROUP_LOADING', true)
    commit('SET_GROUP_ERROR')
    try {
      const response = await Axios.post(domain + '/api/groups', payload, {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        }
      })
      commit('ADD_GROUP', response.data.data)
      if (
        payload.users &&
        response &&
        response.data &&
        response.data.data &&
        response.data.data.id
      ) {
        dispatch('addUsersGroupSync', {
          group_id: response.data.data.id,
          users_id: payload.users,
          group: response.data.data
        })
      }
      if (
        payload.admins &&
        response &&
        response.data &&
        response.data.data &&
        response.data.data.id
      ) {
        dispatch('addAdminsGroupSync', {
          group_id: response.data.data.id,
          admins_id: payload.admins,
          group: response.data.data
        })
      }
      commit('SET_GROUP_LOADING')
    } catch (error) {
      commit('SET_GROUP_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_GROUP_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_GROUP_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async updateGroup({ commit, dispatch }, { body, group }) {
    commit('SET_GROUP_LOADING', true)
    commit('SET_GROUP_ERROR')
    try {
      const response = await Axios.put(
        domain + '/api/groups/' + group.id,
        body,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('UPDATE_GROUP', {
        group: group,
        data: response.data.data
      })
      if (
        body.users &&
        response &&
        response.data &&
        response.data.data &&
        response.data.data.id
      ) {
        dispatch('addUsersGroupSync', {
          group_id: response.data.data.id,
          users_id: body.users,
          group: response.data.data
        })
      }
      if (
        body.admins &&
        response &&
        response.data &&
        response.data.data &&
        response.data.data.id
      ) {
        dispatch('addAdminsGroupSync', {
          group_id: response.data.data.id,
          admins_id: body.admins,
          group: response.data.data
        })
      }
      commit('SET_GROUP_LOADING')
    } catch (error) {
      commit('SET_GROUP_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_GROUP_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_GROUP_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async deletedGroup({ commit }, id) {
    commit('SET_GROUP_LOADING', true)
    commit('SET_GROUP_ERROR')
    try {
      await Axios.delete(domain + '/api/groups/' + id, {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        }
      })
      commit('REMOVE_GROUP', id)
      commit('SET_GROUP_LOADING')
    } catch (error) {
      commit('SET_GROUP_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_GROUP_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_GROUP_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },

  async addUsersGroupSync({ commit }, payload) {
    commit('SET_GROUP_LOADING', true)
    commit('SET_GROUP_ERROR')
    try {
      const body = {
        users: payload.users_id
      }
      const response = await Axios.post(
        domain + '/api/groups/' + payload.group_id + '/sync-users',
        body,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('ADD_GROUP_USERS', response.data.data)
      commit('SET_GROUP_LOADING')
    } catch (error) {
      commit('SET_GROUP_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_GROUP_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_GROUP_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async addAdminsGroupSync({ commit }, payload) {
    commit('SET_GROUP_LOADING', true)
    commit('SET_GROUP_ERROR')
    try {
      const body = {
        admins: payload.admins_id
      }
      const response = await Axios.post(
        domain + '/api/groups/' + payload.group_id + '/sync-admins',
        body,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('ADD_GROUP_ADMINS', response.data.data)
      commit('SET_GROUP_LOADING')
    } catch (error) {
      commit('SET_GROUP_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_GROUP_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_GROUP_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  resetErrorGroup({ commit }) {
    commit('SET_GROUP_ERROR')
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
