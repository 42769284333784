import Entity from '../../Entity'
// import ProjectType from '../ProjectType'
import EtapesStage from './EtapesStage'

export default class Etape extends Entity {
  constructor(data) {
    super(data.id)
    this.name = data.name
    this.type = data.types
    this.index = data.index
    this.projets_count = data.projets_count
    this.flech = false
    this.stages = []
    if (data.stages && data.stages.length) {
      this.stages = data.stages.map(stage => EtapesStage.create(stage))
    }
    this.flech = false
    //dont delete this
    this.hasContent = true
  }

  /**
   * @param { id, name, type_id, cstages } data
   */
  static create(data) {
    if (!data) {
      throw new Error('Etape::create: "data" is undefined')
    }
    return new Etape(data)
  }

  update(data) {
    this.name = data.name
    this.type = data.types
    this.projets_count = data.projets_count
    if (data.stages && data.stages.length) {
      this.stages = data.stages.map(stage => EtapesStage.create(stage))
    }
    this.index = data.index
  }
}
